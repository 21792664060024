.markets-slider {
  .icon-warning {
    width: 102px;
    height: 92px;
    background-image: url(../images/account/war.svg);
  }
  &-item {
    height: 158px;
    background-color: var(--bg_clr_30);
    border-radius: 4px;
    padding: 30px 25px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 7px;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-image: url('../images/account/bg-graph.svg');
      background-size: 100%;
      background-position: 0 100%;
      background-repeat: no-repeat;
      left: 0;
      bottom: 0;
      right: 0;
    }
    &-icon {
      width: 110px;
      text-align: center;
      margin-right: 20px;
    }
    .title {
      letter-spacing: 0.25px;
      line-height: 20px;
      padding-bottom: 8px;
      span {
        font-weight: 300;
        padding-left: 4px;
      }
    }
    .value {
      color: rgba(255, 255, 255, 0.87);
      font-size: 20px;
      letter-spacing: 0.25px;
      line-height: 26px;
      padding-bottom: 4px;
    }
    .course {
      letter-spacing: 0.44px;
      line-height: 24px;
    }
  }
  .slick-slide {
    opacity: 0.3;
    &.slick-active {
      opacity: 1;
    }
  }
}
