.react-datepicker {
  left: 250px;
  position: relative;
  z-index: 99;
  background-color: var(--bg_clr_11);
  color: var(--color_text_02);
  margin-top: -55px;
  margin-bottom: -55px;
}

.react-datepicker__header {
  background-color: var(--bg_clr_11);
  color: var(--color_text_02);
}

.react-datepicker__day-name {
  color: var(--color_text_02);
}

.react-datepicker__day {
  color: var(--color_text_02);

  &:hover {
    background-color: var(--bg_clr_00);
  }
}

.react-datepicker__current-month {
  color: var(--color_text_02);
}

.react-datepicker__year-option {
  background-color: var(--bg_clr_11);
  color: var(--color_text_02);

  &:hover {
    background-color: var(--bg_clr_00);
  }
}

.react-datepicker__month-option {
  background-color: var(--bg_clr_11);
  color: var(--color_text_02);

  &:hover {
    background-color: var(--bg_clr_00);
  }
}

.react-datepicker__triangle {
  display: none;
}

.calendar-input-new {
  width: 100%;
}
