.leftbar + .page-legacy {
  padding-left: $left_bar_full_width;
}

.page-legacy {
  min-height: 100%;
  padding-top: 38px;
  margin-top: -38px;

  .mt-30 {
    margin-top: 30px;
  }
  .container {
    max-width: 1210px;
    margin: 0 auto;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }

  &_tabs {
    margin-bottom: 35px;

    .nav-tabs {
      @include block_flex;
      width: 100%;

      li {
        width: 100%;

        a {
          display: block;
          border-bottom: 2px solid #ebedf8;
          color: var(--color_text_02);
          opacity: 0.75;
          padding: 17px 15px 15px;
          text-align: center;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.25px;
          white-space: nowrap;

          &.active {
            opacity: initial;
            color: var(--color_text_07);
            border-color: var(--brdr_clr_10);
          }
        }
      }
    }
  }

  &_content {
    @include block_flex;
    justify-content: flex-start;
    .btn-cancel {
      background: var(--bg_clr_04);
      color: white;
    }
    .btn-white {
      background: var(--bg_clr_11);
      color: var(--color_text_01);
      opacity: 0.9;
    }
    .help__form {
      .form__control {
        background: var(--bg_clr_11);
        color: var(--color_text_00);
        border: none;
        padding: 17px;
        min-height: 55px;
        width: 100%;

        border-radius: 5px;
        &:focus {
          outline: none;
        }
      }
      .warning {
        color: var(--color_text_12);
      }
      .response__message {
        margin-top: 20px;
        .success span {
          color: var(--color_text_07);
        }
      }
      .form__block {
        max-width: 460px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .dropzone {
          width: 100% !important;
          height: 151px;
          flex-direction: column;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px dashed var(--brdr_clr_04) !important;
          color: var(--color_text_02);
          box-sizing: border-box;
          border-radius: 5px;
          button {
            margin-top: 30px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        &:nth-child(1) {
          // margin-top: 10px;
        }
        &.button__wrapper {
          flex-direction: row;
        }
        .label__wrapper {
          padding-top: 15px;
          padding-bottom: 15px;
          color: var(--color_text_02);
          svg {
            margin-right: 15px;
            margin-left: 10px;
            width: 26px;
            height: 26px;
          }
        }
        button {
          padding: 11px 30px 11px 30px;
          border-radius: 5px;
          font-size: 14px;
        }
        button:nth-child(1) {
          margin-right: 20px;
        }
        label {
          margin-top: 10px;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: var(--color_text_02);
        }
        label.header__label {
          font-size: 16px;
          line-height: 21px;
          color: var(--color_text_02);
          margin-top: 0px;
          margin-bottom: 10px;
        }
      }
      textarea.form__control {
        min-height: 174px;
      }
    }

    &-nav {
      width: 26%;
      min-width: 306px;

      ul {
        border-left: 2px solid #ebedf8;
        width: 100%;

        li {
          margin: 10px 0;

          a {
            color: var(--color_text_33);
            font-size: 13px;
            line-height: 20px;
            opacity: 0.75;
            border-left: 3px solid transparent;
            display: block;
            padding: 5px 0;
            padding-left: 12px;
            margin-left: -2px;

            &:hover {
              color: var(--color_text_07);
              opacity: initial;
            }

            &.active {
              border-color: var(--brdr_clr_10);
              color: var(--color_text_07);
              font-weight: bold;
              opacity: initial;
            }
          }
        }
      }

      + div {
        display: flex;
        width: 67%;
        padding-left: 20px;
      }
    }

    &-block {
      width: 100%;
      padding-bottom: 40px;

      h1,
      h2,
      h3,
      h4,
      h5 {
        color: var(--color_text_34);
        padding-bottom: 15px;
      }

      .title {
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        margin-bottom: 40px;

        h2 {
          font-size: 34px;
          padding-bottom: 0;
        }

        .date {
          opacity: 0.5;
          color: #626467;
          font-size: 12px;
          letter-spacing: 0.11px;
        }
      }

      .text {
        font-size: 15px;
        line-height: 22px;
        color: var(--color_text_02);
        margin-bottom: 35px;

        p {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-top: 20px;
        }
      }
    }
  }
}
