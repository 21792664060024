$font_family: 'IBM Plex Sans', sans-serif;

/*Colors*/

$text_hight: 000; // Hight contrast text & titles

:root {
  --black0: #000000;
  --black1: #333333;
  --black2: #212226;
  --black3: #17171a;
  --black4: #050505;
  --black5: #222327;

  --dark0: #263858;
  --dark1: rgba(47, 51, 59, 0.8);
  --dark2: rgba(38, 56, 88, 0.5);
  --dark3: rgba(99, 112, 137, 0.6);
  --dark4: rgba(99, 112, 137, 0.75);
  --dark5: rgba(99, 112, 137, 0.3);
  --dark6: rgba(99, 112, 137, 0.5);
  --dark7: rgba(112, 182, 139, 0.15);
  --dark8: rgba(68, 123, 255, 0.1);
  --dark9: rgba(126, 137, 158, 0.2);
  --dark10: #363840;
  --dark11: #2c2d33;
  --dark12: rgba(47, 51, 59, 0.2);

  --gray0: #637089;
  --gray1: #7e899e;
  --gray2: #aeb4c0;
  --gray3: #8b959e;
  --gray4: #bbc1cb;
  --gray5: rgba(255, 255, 255, 0.3);
  --gray6: rgba(139, 149, 158, 0.5);
  --gray7: rgba(241, 243, 246, 0.5);
  --gray8: #848588;
  --gray9: #eff0f8;
  --gray10: #cdd2d6;

  --white0: #ffffff;
  --white1: #dde2e5;
  --white2: #dee4e9;
  --white3: #f1f3f6;
  --white4: #dadde0;
  --white5: #f4f8f9;
  --white6: #edeff1;
  --white7: #f2f5f7;
  --white8: #ededed;
  --white9: #e5e9ec;
  --white10: #e8ecea;
  --white11: #f0f1f6;

  --violet0: #7f63ff;
  --violet1: #794aff;
  --violet3: rgba(127, 99, 255, 0.75);

  --green0: #70b68b;
  --green1: #00b276;
  --green2: #049966;
  --green3: rgba(0, 178, 188, 0.2);
  --green4: rgba(4, 153, 102, 0.8);
  --green5: rgba(4, 153, 102, 0.2);
  --green6: #41bd54;

  --red0: #d85960;
  --red1: #c14a50;
  --red2: rgba(216, 89, 96, 0.15);
  --red3: rgba(216, 89, 96, 0.05);
  --red4: #f54865;

  --yellow0: #f5a623;
  --yellow1: #ff8836;
  --yellow2: #fca467;
  --yellow4: #f2c94c;

  --blue0: #447bff;
  --blue1: #064cbe;
  --blue2: #4a90e2;

  --send0: #e4bc74;
  --send1: #f3dfa3;
}

.light-theme {
  --loader-bg: rgba(255, 255, 255, 0.8);
  --pass-hint-shadow: 0 4px 20px 0 rgba(34, 36, 37, 0.15);
  //txt colors
  --color_text_00: var(--black0); // Black text
  --color_text_01: var(--dark0); // Bright base texts & Titles
  --color_text_02: var(--gray0); // Base text
  --color_text_03: var(--gray1); // Light Base text
  --color_text_04: var(--gray2); // Light text,tips etc
  --color_text_05: var(--white0);
  --color_text_06: var(--black1);
  --color_text_07: var(--violet0);
  --color_text_08: var(--violet1);
  --color_text_09: var(--green0);
  --color_text_10: var(--green1);
  --color_text_11: var(--green2);
  --color_text_12: var(--red0);
  --color_text_14: var(--yellow0);
  --color_text_15: var(--yellow1);
  --color_text_16: var(--yellow2);
  --color_text_17: var(--blue0);
  --color_text_18: var(--blue1);
  --color_text_19: var(--white1);
  --color_text_21: var(--gray3);
  --color_text_22: var(--blue2);
  --color_text_23: var(--white2);
  --color_text_24: var(--dark2);
  --color_text_25: var(--dark3);
  --color_text_26: var(--dark4);
  --color_text_27: var(--gray5);
  --color_text_28: var(--violet3);
  --color_text_29: var(--gray6);
  --color_text_30: var(--black2);
  --color_text_31: var(--gray8);
  --color_text_32: var(--gray4);
  --color_text_33: var(--dark10);
  --color_text_34: var(--black3);

  //background colors
  --bg_clr_00: var(--white3);
  --bg_clr_01: var(--white4);
  --bg_clr_02: var(--white1); //secondary bg
  --bg_clr_03: var(--gray4);
  --bg_clr_04: var(--gray1);
  --bg_clr_05: var(--gray2);
  --bg_clr_06: var(--white5);
  --bg_clr_07: var(--dark5);
  --bg_clr_08: var(--dark7);
  --bg_clr_09: var(--white7); //third bg
  --bg_clr_10: var(--white8);
  --bg_clr_11: var(--white0); //base bg color
  --bg_clr_12: var(--send0);
  --bg_clr_13: var(--send1);
  --bg_clr_14: var(--dark0);
  --bg_clr_15: var(--black1);
  --bg_clr_16: var(--violet0);
  --bg_clr_17: var(--violet1);
  --bg_clr_18: var(--green0);
  --bg_clr_19: var(--green1);
  --bg_clr_20: var(--green2);
  --bg_clr_21: var(--red0);
  --bg_clr_22: var(--red1);
  --bg_clr_23: var(--yellow1);
  --bg_clr_24: var(--blue0);
  --bg_clr_25: var(--gray7);
  --bg_clr_26: var(--dark6);
  --bg_clr_27: var(--red2);
  --bg_clr_28: var(--red3);
  --bg_clr_29: var(--dark8);
  --bg_clr_30: var(--black2);
  --bg_clr_31: var(--dark10);
  --bg_clr_32: var(--dark11);
  --bg_clr_33: var(--black3);
  --bg_clr_34: var(--green3);
  --bg_clr_35: var(--white11);
  --bg_clr_36: var(--violet3);
  --bg_clr_37: var(--yellow4);

  //border and outline colors
  --brdr_clr_00: var(--white4);
  --brdr_clr_01: var(--gray4);
  --brdr_clr_02: var(--gray1);
  --brdr_clr_03: var(--white5);
  --brdr_clr_04: var(--gray0);
  --brdr_clr_05: var(--white6);
  --brdr_clr_06: var(--white0);
  --brdr_clr_07: var(--white9);
  --brdr_clr_08: var(--white10);
  --brdr_clr_09: var(--dark0);
  --brdr_clr_10: var(--violet0);
  --brdr_clr_11: var(--violet1);
  --brdr_clr_12: var(--green0);
  --brdr_clr_13: var(--green1);
  --brdr_clr_14: var(--green4);
  --brdr_clr_15: var(--red0);
  --brdr_clr_16: var(--yellow0);
  --brdr_clr_17: var(--blue0);
  --brdr_clr_18: var(--dark9);
  --brdr_clr_19: var(--dark6);
  --brdr_clr_20: var(--gray7);
  --brdr_clr_21: var(--gray5);
  --brdr_clr_22: var(--black2);
  --brdr_clr_23: var(--gray8);
  --brdr_clr_24: var(--dark10);
  --brdr_clr_25: var(--dark11);
  --brdr_clr_26: var(--gray9);
  --brdr_clr_27: var(--violet3);
  --brdr_clr_28: var(--dark4);

  //shadow colors
  --clr_shdw_00: var(--dark12); //Base shadow color
  --clr_shdw_01: var(--gray7);
}

.dark-theme {
  --loader-bg: rgba(52, 52, 52, 0.8);
  --pass-hint-shadow: 0 2px 10px 0 rgba(166, 166, 166, 0.15);

  --color_text_02: var(--gray10);
  --color_text_07: var(--violet0);
  --color_text_08: var(--violet1);
  --color_text_09: var(--green0);
  --color_text_10: var(--green1);
  --color_text_11: var(--green2);
  --color_text_12: var(--red0);
  --color_text_14: var(--yellow0);
  --color_text_15: var(--yellow1);
  --color_text_16: var(--yellow2);
  --color_text_17: var(--blue0);
  --color_text_18: var(--blue1);
  --color_text_22: var(--blue2);
  --color_text_28: var(--violet3);
  --color_text_31: var(--gray8);

  --bg_clr_00: var(--black2);
  --bg_clr_01: var(--dark10);
  --bg_clr_02: var(--black5);
  --bg_clr_06: var(--black5);
  --bg_clr_07: var(--black5);
  --bg_clr_08: var(--dark7);
  --bg_clr_09: var(--black4);
  --bg_clr_11: var(--black3);
  --bg_clr_12: var(--send0);
  --bg_clr_13: var(--send1);
  --bg_clr_16: var(--violet0);
  --bg_clr_17: var(--violet1);
  --bg_clr_18: var(--green0);
  --bg_clr_19: var(--green1);
  --bg_clr_20: var(--green2);
  --bg_clr_21: var(--red0);
  --bg_clr_22: var(--red1);
  --bg_clr_23: var(--yellow1);
  --bg_clr_24: var(--violet0);
  --bg_clr_25: var(--black4);
  --bg_clr_27: var(--red2);
  --bg_clr_30: var(--black2);
  --bg_clr_34: var(--green3);
  --bg_clr_36: var(--violet3);
  --bg_clr_37: var(--yellow4);

  --brdr_clr_00: var(--dark10);
  --brdr_clr_02: var(--white0);
  --brdr_clr_01: var(--gray4);
  --brdr_clr_04: var(--gray5);
  --brdr_clr_05: var(--gray5);
  --brdr_clr_06: var(--gray5);
  --brdr_clr_08: var(--gray5);
  --brdr_clr_09: var(--white0);
  --brdr_clr_10: var(--violet0);
  --brdr_clr_11: var(--violet1);
  --brdr_clr_23: var(--gray5);
  --brdr_clr_28: var(--gray5);

  --brdr_clr_12: var(--green0);
  --brdr_clr_13: var(--green1);
  --brdr_clr_14: var(--green4);

  --brdr_clr_15: var(--red0);
  --brdr_clr_16: var(--yellow0);
  --brdr_clr_17: var(--blue0);
  --brdr_clr_27: var(--violet3);

  .table-dropdown-item-body-link {
  }

  .table-dropdown-item-body {
  }

  .teaser-banner {
    --color_text_00: var(--white0);
  }
}

$shdw_type_00: 0 4px 4px 0;
$left_bar_width: 58px;
$left_bar_full_width: 200px;

$brdr_rad: 4px;

@mixin bg_gradient() {
  background: var(--bg_clr_12);
  background: -moz-linear-gradient(left, var(--bg_clr_12) 0%, var(--bg_clr_13) 100%);
  background: -webkit-linear-gradient(left, var(--bg_clr_12) 0%, var(--bg_clr_13) 100%);
  background: linear-gradient(to right, var(--bg_clr_12) 0%, var(--bg_clr_13) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=var(--bg_clr_12), endColorstr=var(--bg_clr_13), GradientType=1);
}

body {
  font-family: $font_family;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: normal;
  background-color: var(--bg_clr_09);
  min-height: 700px;
  min-width: 1280px;
  color: var(--color_text_02);
}

html,
body {
  width: 100%;
  height: 100%;
}

input,
button,
textarea,
select {
  font-family: $font_family;
  font-size: 14px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}

img {
  vertical-align: top;
  max-width: 100%;
}

a {
  cursor: pointer;
  color: var(--color_text_22);
  text-decoration: none;
  @include web_transition(all 0.2s ease-in-out);

  &:hover {
    //color: $color_gray_1;
  }
}

.teaser-banner {
  position: absolute;
  top: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 24px;
  background-color: var(--bg_clr_37);
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: var(--color_text_00);
  max-height: 50px;
  min-width: 1280px;

  .banner-content {
    margin-left: 34px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0 16px;
    }
  }

  .box-control {
    .form-checkbox {
      color: var(--color_text_00);
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;

      .checkmark {
        background-color: white;
        top: 6px;
        left: 7px;
        height: 16px;
        width: 16px;
      }

      &:hover {
        background: initial;
      }
    }
  }

  .profile-link {
    text-decoration: underline;
    margin-left: 5px;
  }

  .control-block {
    margin-left: auto;
    display: flex;

    .hide-btn {
      padding: 6px 24px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      margin-left: 13px;
      background: rgba(255, 255, 255, 0.75);
      border-radius: 25px;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 1);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.under a,
a.under {
  text-decoration: underline;
}

.under a:hover,
a.under:hover {
  text-decoration: none;
}

b,
.bold {
  font-weight: bold;
}

i,
.italic {
  font-style: italic;
}

.error-msg-block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color_text_12);

  &.size-00 {
    height: 32px;
    max-height: 32px;
    min-height: 32px;
  }
}

.upper {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.svg-icon-balances {
  height: auto;
  width: auto;
  margin-top: -3px;
  margin-right: 12px;
}

.svg-without-text-icon-balances {
  margin-right: 0;
}

.text-left {
  text-align: left;
}

.styles-currency-icons {
  height: 50%;
  width: 50%;
}

h1,
.h1 {
  font-size: 35px;
}

h2,
.h2 {
  font-size: 28px;
}

h3,
.h3 {
  font-size: 22px;
}

h4,
.h4 {
  font-size: 18px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  line-height: 1.2;
}

.dotted {
  border-bottom: 1px dotted;
}

.dashed {
  border-bottom: 1px dashed;
}

.super {
  vertical-align: super;
  font-size: 11px;
}

.caret {
  color: inherit;
  border-top: 4px solid;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 6px 0;
  width: 0;
  height: 0;

  &-md {
    color: inherit;
    border-top: 5px solid;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    display: inline-block;
    vertical-align: middle;
    margin: -2px 6px 0;
    width: 0;
    height: 0;
  }
}

.box-inline {
  display: inline-block;
  vertical-align: top;
}

.btn {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  background: none;
  cursor: pointer;
  @include web_transition(all 0.2s ease-in-out);
}

.container {
  max-width: 1820px;
  margin: 0 auto;
  position: relative;
  padding-left: 6px;
  padding-right: 6px;
  display: flex;
  @include block_flex;
  @include flex_justify_between;
  width: 100%;

  &.full {
    max-width: 100%;
  }
}

.container-small {
  max-width: 785px;
  width: 100%;
}

.container-center {
  max-width: 1210px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.flexible {
  @include block_flex;
  @include flex_justify_between;
}

/*colors*/
.grey {
  color: var(--color_text_04);
}

.black {
  color: var(--color_text_05);
}

.white {
  color: var(--color_text_05);
}

.blue {
  color: var(--color_text_18);
}

.red {
  color: var(--color_text_12) !important;
}

.yellow {
  color: var(--color_text_14);
}

.green {
  color: var(--color_text_09) !important;
}

.wait {
  color: var(--color_text_16) !important;
}

/*CONTENT*/
#root {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 840px;
  padding-top: 38px;

  &.with-reminder {
    padding-top: 86px;
  }
}

.main {
  overflow-y: visible;
  overflow-x: hidden;
  padding: 6px;
  padding-left: $left_bar_width + 6px;
  position: relative;
  height: 100%;

  .grid-layout {
    display: grid;
    grid-template-rows: 1fr repeat(2, minmax(200px, 15%));
    grid-template-columns: 320px 1fr 300px 300px;
    grid-gap: 8px;
    height: 100%;
    max-height: 100%;

    &.narrow {
      grid-template-columns: 320px 1fr 300px;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;

      &.overflow-h {
        overflow: hidden;
      }
    }

    .markets-block {
      grid-row-start: 1;
      grid-row-end: 4;
    }

    .ordering-trading {
      grid-row-start: 2;
      grid-row-end: 4;
      grid-column-start: 2;
      grid-column-end: 3;
      overflow-y: auto;
      @include block_custom_scroll();
    }

    .order-book {
      grid-row-start: 1;
      grid-row-end: 4;

      .tabs-header {
        display: flex;

        &.with-bg {
          background: var(--bg_clr_00);

          .active {
            background-color: var(--bg_clr_11);
          }

          > div {
            cursor: pointer;
          }
        }
      }
    }

    .resent-trades {
      grid-row-start: 1;
      grid-row-end: 4;
      overflow: hidden;
    }

    > div,
    > section {
      position: relative;
      border-radius: 0 0 3px 3px;
      border-top: 2px solid var(--brdr_clr_10);
      background-color: var(--bg_clr_11);
    }

    .buy-sell-container {
      .lm_content {
        height: 100% !important;
      }
    }

    .chart-cell-header {
      display: flex;
      align-items: center;

      .market-volume-stats {
        font-size: 12px;
      }
    }

    .cell-header {
      padding: 7px 12px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      color: var(--color_text_01);
      font-family: 'IBM Plex Sans', sans-serif;
    }

    .terminal-history {
      min-height: 101%;
      max-height: 101%;

      .count-wrapper {
        width: 20px;
        position: relative;

        .count {
          position: absolute;
          top: -10px;
          left: 0;

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 10px;
          line-height: 12px;
          height: 20px;
          width: 20px;
          margin-left: 6px;
          background: red;
        }
      }
    }

    .balance_copy {
      text-align: start;
      font-size: 12px;
      padding: 12px 12px 0 12px;
      min-height: 27px;
    }

    .terminal-history,
    .terminal-trading {
      &__header {
        display: flex;
        background: var(--bg_clr_00);

        &-item {
          @extend .cell-header;
          display: flex;
          align-items: center;
          color: var(--color_text_02);
          cursor: pointer;
          border-top: 2px solid transparent;

          &.active {
            background: var(--bg_clr_11);
            color: var(--color_text_01);

            &:not(.no-border) {
              border-color: var(--brdr_clr_10);
            }
          }
        }
      }
    }

    .terminal-trading {
      display: flex;
      flex-direction: column;
      width: 100%;

      %trading-btn {
        color: var(--color_text_05);
        padding: 12px;
        width: 70%;
        text-transform: uppercase;

        &:hover {
          opacity: 0.85;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }

      .btn-buy {
        @extend %trading-btn;
        background-color: var(--green4);
      }

      .btn-sell {
        @extend %trading-btn;
        background-color: var(--red0);
      }

      .btn-buy,
      .btn-sell {
        padding: 7px 12px;
      }

      &__content {
        display: flex;
        width: 100%;
      }

      &__hint {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--red0);
        padding-bottom: 12px;
      }
    }
  }

  + div {
    padding-left: 64px;
  }
}

.tab-pane-name {
  color: var(--color_text_01) !important;
}

.tab-pane-subtext {
  font-size: 14px;
  color: var(--color_text_02);
}

.tab-pane-msg {
  font-size: 14px;
  line-height: 17px;
  margin-top: 12px;
}

.block-account-name {
  color: var(--color_text_01) !important;
}

.trigger-btn-body {
  $bodyWidth: 40px;
  $bodyHeight: 20px;
  $btnSize: 22px;
  $btnColor: var(--white0);
  $offColor: var(--green2);
  $onColor: var(--violet0);

  .svg-icon {
    position: absolute;
    z-index: 3;
    top: -2px;

    &.day {
      right: -2px;
    }

    &.night {
      left: -2px;
    }
  }

  position: relative;
  width: $bodyWidth;
  height: $bodyHeight;

  &.active {
    .trigger-circle {
      transform: translateX(100%);
    }

    .on-state {
      transform: translateX(0);
    }

    .off-state {
      transform: translateX(100%);
    }

    .lines-wrapper {
      box-shadow: none;
    }
  }

  .trigger-circle {
    width: $btnSize;
    height: $btnSize;
    background-color: $btnColor;
    border-radius: 50%;
    position: absolute;
    z-index: 4;
    top: -1px;
    left: -1px;
    transition: transform 0.5s;
    cursor: pointer;
  }

  .lines-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 var(--dark2);
  }

  .color-line {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .color-line {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
  }

  .on-state {
    background-color: $offColor;
  }

  .off-state {
    background-color: $onColor;
    transform: translateX(0);
    z-index: 2;
  }
}

.advcash-from {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  .adv-button {
    height: 56px;
  }

  .gti-input-section {
    width: 200px;
  }

  input {
    font-size: 1rem;
    height: 51px;
    text-align: center;
    width: 200px;
  }

  button {
    margin-left: 25px;
  }

  .preloader-container {
    min-width: 195px;
    // display: flex;
    // justify-content: center;
  }
}

#jsd-widget {
  opacity: 0.2;
  right: 10px !important;
  transition: opacity 0.3s;
}

#jsd-widget:hover {
  opacity: 1;
}

.lng-list {
  .lng-option-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .lng-option-wrapper span {
    margin: 0 5px;
  }
}

.d-none {
  display: none;
}

.bg_red {
  background: var(--red1);
}

.bg_green {
  background: var(--green3);
}

.chip {
  padding: 2px 4px;
  border-radius: 2px;
}

.full-height {
  height: 100%;
}

.content-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.block-account-control.high-lighted {
  color: var(--color_text_07);
}

.relative {
  position: relative;
}

.loading-block {
  position: absolute;
  width: 100%;
  height: calc(100% - 27px);
  margin-top: 27px;
  background: var(--bg_clr_11);
  z-index: 3;
}

.date-picker-custom-dark .DatePicker__calendarArrow {
  border-color: transparent transparent var(--black2) transparent;
}

.date-picker-custom-dark .Calendar {
  background: var(--black2);
  color: #fff;

  > :not(.Calendar__footer) button {
    color: #fff;
  }

  .Calendar__monthArrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  }

  .Calendar__monthYear.-shown > *:hover,
  .Calendar__monthSelector,
  .Calendar__yearSelector {
    background: var(--black2) !important;
    color: var(--violet0);
  }

  .Calendar__yearSelectorWrapper:before {
    background-image: linear-gradient(to top, var(--black2), var(--black2) 10%, rgba(47, 51, 59, 0));
  }

  .Calendar__yearSelectorWrapper:after {
    background-image: linear-gradient(to bottom, var(--black2), var(--black2) 10%, rgba(47, 51, 59, 0));
  }

  .Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover,
  .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover {
    background: var(--black1);
  }

  .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: var(--violet0);
    color: #fff;
  }

  .Calendar__section {
    .Calendar__day,
    .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
      color: #fff;
    }

    .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
      background: #fff;
    }
  }
}

.theme-switcher {
  .toggleWrapper {
    display: flex;
    align-items: center;

    input {
      display: none;
      z-index: 99;
    }
  }

  .toggle {
    cursor: pointer;
    position: relative;
    width: 32px;
    height: 16px;
    background: var(--bg_clr_02);
    border-radius: 32px - 6;
    transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    display: flex;
    align-items: center;
    padding: 0 2px;
  }

  .toggle__handler {
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 11px;
    height: 11px;
    background-color: var(--bg_clr_01);
    border-radius: 50%;
    box-shadow: 0 2px 6px var(--bg_clr_08);
    transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: translate3d(16px, 0, 0) rotate(0);

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      top: 0;
      box-shadow: 2px 2px 0 0 white;
      margin-top: -2px;
      margin-right: 2px;
    }
  }

  input:checked {
    + .toggle {
      background-color: white;

      &:after {
        color: white;
      }

      .toggle__handler {
        background-color: var(--bg_clr_37);
        transform: rotate(-45deg);

        .crater {
          opacity: 1;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

.not-found-page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .not-found-img {
      width: 240px;
      height: 240px;

      + p {
        font-family: Roboto, sans-serif;
        font-size: 20px;
        line-height: 24px;
        padding-top: 67px;
        padding-bottom: 42px;
      }
    }

    .btn {
      min-width: 304px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.faq-page {
  .table-balance-header .tr .td,
  .table-balance-header .tr th,
  .table-balance-header tr .td,
  .table-balance-header tr th {
    line-height: normal;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
    border-right: 1px solid var(--brdr_clr_08);
  }

  td {
    border-right: 1px solid var(--brdr_clr_08);
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 25px !important;
    line-height: 18px !important;
  }

  width: auto;
  height: 100%;
  padding-bottom: 100px;

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 50%;
  }

  .footer {
    margin-top: 100px;
  }

  .wrapper-line {
    width: 1106px;
    height: 2px;
    /* violet */
    margin-top: 15px;
    background: var(--color_text_07);
    margin-bottom: 60px;
  }

  .faq-header {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.2em;
    /* violet */

    color: var(--color_text_07);
  }

  .faq-left {
    min-width: 293px;
  }

  .faq-right {
    max-width: 746px;
  }

  .faq-left li {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */
    margin-bottom: 24px;
    color: #626467;
    margin-right: 30px;
  }

  .faq-left li:hover {
    cursor: pointer;
    color: var(--color_text_07);
    transition: 0.6s color;
  }

  .faq-left li.active {
    margin-right: -2px;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: var(--color_text_07);
    border-right: 3px solid transparent;
    border-color: var(--brdr_clr_10);
  }

  .faq-right li.active {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */

    text-align: left;

    /* violet */

    svg:hover {
      cursor: pointer;
    }

    svg {
    }
  }

  .faq-right li {
    max-width: 700px;
    // height: 26px;
    // display: flex;
    // align-items: flex-start;
    margin-top: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: left;

    p {
      margin-left: 20px;
    }

    .faq-text {
      margin-top: 3px;
      margin-bottom: 23px;
      color: var(--color_text_07);
    }
  }

  .faq-title {
    text-transform: uppercase;
    min-width: 370px;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 44px;
    text-align: left;
    color: var(--color_text_34);
  }

  .question-box:hover > p {
    transition: all 0.5s ease-in;
    color: var(--color_text_07);
  }

  .question-box:hover {
    cursor: pointer;
  }

  .btn-wrapper {
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    max-width: 314px;
    height: 40px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;

      /* black color */
    }
  }

  .btn-purple {
    max-width: 135px;
  }

  .collapsed {
    display: none;
    margin-left: 20px;
    opacity: 0;
    transition: all 0.6s;
  }

  .expanded {
    // color: var( --dark0);
    margin-left: 40px !important;
    opacity: 1;
    transition: all 0.6s;
  }
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.text-dark {
  color: var(--color_text_01) !important;
}

.faq-complex-answer {
  strong {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5em;
  }

  em {
    font-weight: bold;
    text-decoration: none;
  }

  div {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 1em;
  }

  img + img {
    margin-top: 1em;
  }

  ol {
    list-style: decimal;

    li {
      margin: 0 !important;
      padding: 0 !important;
      margin-left: 2em !important;
      max-width: none !important;
      list-style: decimal;
    }
  }
}

.portfolio-chart {
  text-align: center;
  margin-bottom: 40px;
}

.table-portfolio {
  tbody {
    tr:hover {
      td {
        transition: color 0.2s ease-out;
        color: var(--color_text_00);
      }
    }
  }

  text {
    color: red !important;
  }
}

.i18n-preLine {
  white-space: pre-line;
}

.custom-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 41;

  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.5);

  .modal-content {
    padding: 40px 60px;
    background-clip: padding-box;
    background-color: var(--bg_clr_11);
    color: var(--color_text_01);
    border-radius: 5px;
  }

  .ticket-created-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 485px;
    word-break: break-word;
    text-align: center;

    h2 {
      margin-bottom: 20px;
    }

    .text-description {
      margin-bottom: 45px;
    }
  }

  .order-create-error-modal {
    max-width: 485px;
    min-width: 485px;
    word-break: break-word;
    text-align: center;

    > svg {
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 20px;
    }

    .text-description {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 45px;

      .order-error-code {
        font-weight: bold;
      }

      .order-error-description {
        margin-top: 8px;
      }
    }
  }
}

.tab-pane {
  .deposit-chain-btns {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 60px;
    gap: 10px;

    button,
    a {
      width: initial;
    }

    a {
      color: var(--color_text_05);

      &:hover,
      &:focus {
        color: var(--color_text_05);
      }
    }
  }
}

.withdraw-network-select {
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.6);
}

.adaptive-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon-wrapper {
    margin-top: 5px;
    background-color: var(--violet1);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: var(--white0);
    }
  }

  .menu-text {
    line-height: normal !important;
  }
}

.leftbar.open {
  .adaptive-btn {
    .menu-text {
      display: flex;
      align-items: center;
    }
  }
}

.portfolio-chart-tooltip {
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 5px;
}

.rc-pagination {
  .rc-pagination-next,
  .rc-pagination-prev {
    .rc-pagination-item-link {
      font-size: 20px;
      line-height: 20px;
      background-color: transparent;
      color: var(--color_text_02);
    }
  }

  .rc-pagination-simple-pager {
    color: var(--color_text_02);

    input {
      background-color: transparent;
      color: var(--color_text_07);

      &:hover,
      &:focus {
        border-color: var(--brdr_clr_10);
      }
    }
  }
}

.add-info .deposit-chain-btns .icon-blockchain,
.withdraw-network-select .deposit-chain-btns .icon-blockchain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  > svg {
    margin-right: 6px;
    max-width: 26px;
    max-height: 26px;
  }
  > svg,
  > span {
    pointer-events: none;
  }
}
