.leftbar {
  width: $left_bar_width;
  background-color: var(--bg_clr_11);
  box-shadow: 0 0 0px 0 rgba(38, 56, 88, 0.01);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 40;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include web_transition(all 0.1s ease-in-out);

  &-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 2;
    background: inherit;
    padding-top: 15px;
    padding-bottom: 15px;

    &_documents-icon {
      transform: translate(2px, 0) scale(0.8);

      & path {
        fill: #1d1d1b;
      }
    }

    &_faq {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &_icon {
        opacity: 0.5;
      }
    }
  }

  .logo {
    overflow: hidden;
    margin-bottom: 20px;
    padding-left: 12px;
    //border-right: 12px solid var(--brdr_clr_06);
    cursor: pointer;
    text-align: left;

    img {
      max-width: none;
      height: 32px;
    }
  }

  &.open .logo {
    width: 176px;
  }

  ul {
    width: 58px;

    li {
      position: relative;
      color: var(--color_text_24);
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }

      .border-user {
        margin-top: 5px;
        margin-bottom: 15px;
      }

      .menu-text {
        position: absolute;
        left: 100%;
        top: 0;
        height: 40px;
        line-height: 40px;
        width: 142px;
        text-align: left;
        font-size: 13px;
        @include web_transition(all 0.2s ease-in-out);
        display: none;
        cursor: pointer;
      }

      a {
        height: 40px;
        line-height: 40px;
        display: block;
        color: var(--color_text_24);

        i {
          position: relative;
        }

        .icon {
          opacity: 0.5;
        }

        .new-message {
          display: block;
          position: absolute;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: var(--bg_clr_21);
          color: #ffffff;
          top: 5px;
          right: 10px;
          text-align: center;
          line-height: 14px;
          font-size: 8px;
          font-weight: bold;
          z-index: 1;
        }

        &:hover {
          // background-color: var(--bg_clr_29);

          .menu-text {
            background-color: var(--bg_clr_29);
          }
        }
      }

      &.active {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          background-color: var(--bg_clr_24);
        }

        a {
          color: var(--color_text_01);
          background: none;

          .menu-text {
            background: none;
          }

          .icon {
            opacity: 1;
          }
        }
      }
    }
  }

  .button {
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 26px;
    line-height: normal;
    border-radius: 3px;
    position: relative;
    border: none;
    color: var(--color_text_24);

    &-menu {
      border: 0;
      padding: 10px 18px;
      line-height: 2px;
      text-align: right;
      cursor: pointer;

      .icon-bar {
        display: inline-block;
        vertical-align: top;
        background: rgba(var(--bg_clr_14), 0.5);
        width: 20px;
        height: 2px;
        @include web_transition(all 0.2s ease-in-out);

        & + .icon-bar {
          margin-top: 5px;
        }

        &:nth-child(2) {
          width: 20px;
        }

        &:nth-child(3) {
          width: 20px;
        }
      }

      &.open {
        .icon-bar {
          &:first-child {
            @include web_transform(translateY(10px) rotate(45deg));
            width: 45%;
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            @include web_transform(translateY(-10px) rotate(-45deg));
            width: 45%;
          }
        }
      }
    }
  }

  &.open {
    width: $left_bar_full_width;
    box-shadow: 0 0 6px 0 rgba(38, 56, 88, 0.23);

    .menu-text {
      display: block;
    }
  }
}

.hover-left-menu-wrapper {
  position: absolute;
  z-index: -20;
  left: -2px;
  bottom: 0;
  transform: translateX(-100%);
  transition: transform 0.3s;
  height: 100%;
  width: 150px;
  background-color: var(--bg_clr_11);

  & span {
    z-index: -5;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
    opacity: 0;
    -webkit-transition: 0.3s ease;
  }
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: space-between;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.align-items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.tooltip-heading {
  margin-left: 30px;
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  max-width: 80%;
  height: 26px;
  color: var(--color_text_01);
}
.tooltip-subtext {
  margin-top: 20px;
  margin-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  max-width: 340px;
  height: 54px;
}
.close-icon {
  position: absolute;
  top: 30px;
  right: 30px;
}
.btn-full.small {
  width: auto;
  height: auto;
  max-width: 200px;
  margin-top: 30px;
  margin-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.hover-left-menu-wrapper.big {
  text-align: left;
  width: 400px;
  height: 230px;
  display: flex;
  .disabled {
    display: none;
  }
}
.hover-left-menu-wrapper.big.disabled {
  display: none;
}
.link-container {
  position: relative;

  &:hover {
    .hover-left-menu-wrapper {
      transform: translateX(60px);
    }
    .hover-left-menu-wrapper span {
      opacity: 1;
      transition: 0.3s;
    }
  }
}

.link-container {
  cursor: pointer;
}
