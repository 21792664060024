.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
