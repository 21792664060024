@font-face {
  font-family: 'updown';
  src: url('../fonts/updown.eot');
  src: url('../fonts/updown.woff') format('woff'), url('../fonts/updown.ttf') format('truetype'),
    url('../fonts/updown.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin cubeSize($size) {
  min-width: $size;
  min-height: $size;
  max-height: $size;
  max-width: $size;
}

@mixin iconSize($width, $height) {
  min-width: $width;
  min-height: $height;
  max-height: $height;
  max-width: $width;
}

.dark-theme {
  .svg-icon {
    &.depends-on-theme {
      path {
        fill: var(--white0);
      }
    }
  }
}

.svg-icon {
  display: inline-block;
  vertical-align: middle;

  &.light-content {
    path {
      fill: var(--white0);
    }
  }

  &.light-fill {
    path {
      fill: var(--black2);
    }
  }

  &.icon-next,
  &.icon-next-max {
    margin-bottom: 2px;
  }

  &.ic-size-00 {
    @include cubeSize(32px);
  }

  &.ic-size-01 {
    @include cubeSize(130px);
  }

  &.ic-size-02 {
    @include cubeSize(18px);
  }

  &.ic-size-03 {
    @include iconSize(7px, 18px);
  }

  &.ic-size-04 {
    @include cubeSize(20px);
  }

  &.ic-size-05 {
    @include cubeSize(28px);
  }

  &.ic-size-06 {
    @include cubeSize(24px);
  }

  &.ic-size-07 {
    @include cubeSize(14px);
  }

  &.ic-size-08 {
    @include iconSize(18px, 20px);
  }

  &.ic-size-09 {
    @include cubeSize(22px);
  }

  &.ic-size-10 {
    @include cubeSize(38px);
  }

  &.ic-size-11 {
    @include iconSize(19px, 22px);
  }

  &.ic-size-12 {
    @include iconSize(9px, 14px);
  }

  &.ic-size-13 {
    @include iconSize(16px, 14px);
  }

  &.ic-size-14 {
    @include cubeSize(16px);
  }

  &.ic-size-15 {
    @include iconSize(84px, 88px);
  }

  &.ic-size-16 {
    @include cubeSize(46px);
  }

  &.ic-size-17 {
    @include iconSize(18px, 24px);
  }

  &.ic-size-18 {
    @include cubeSize(25px);
  }

  &.ic-size-19 {
    @include cubeSize(100px);
  }

  &.ic-rotate-00 {
    transform: rotate(90deg);
  }

  &.ic-rotate-01 {
    transform: rotate(-90deg);
  }

  &.ic-rotate-02 {
    transform: rotate(180deg);
  }

  &.icon-dropdown {
    //@include web_transition(margin .2s linear);
    &.ic-color-00 {
      path {
        stroke: var(--red4);
      }
    }
  }

  &.icon-close {
    &.ic-color-00 {
      stroke: var(--red4);
    }
  }

  &.icon-star {
    &.active {
      path {
        fill: #447bff;
      }
    }

    &:hover {
      fill: none;
    }
  }

  &.icon-plus {
    &.ic-color-00 {
      path {
        fill: var(--white0);
      }
    }
  }

  &.icon-lock {
    &.ic-color-00 {
      g {
        fill: var(--red4) !important;
      }
    }
  }

  &.icon-social {
    path {
      fill: var(--gray0);
    }
  }

  &.icon-key-print {
    margin-right: 10px;
  }
}

i.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-top: -2px;
  background-repeat: no-repeat;
  background-size: 100% auto;

  &-app {
    width: 32px;
    height: 32px;
    background-image: url(../images/2fa/2FA-app.png);
    border-radius: 50%;
  }

  &-arrow-top {
    width: 16px;
    height: 14px;
    text-align: left;
    line-height: normal;

    &:after {
      font-style: normal; // FUUUUUUUU
      font-family: 'updown', sans-serif;
      content: 'u';
    }
  }

  &-arrow-bottom {
    width: 16px;
    height: 14px;
    text-align: left;
    line-height: normal;

    &:after {
      font-style: normal; // FUUUUUUUU
      font-family: 'updown', sans-serif;
      content: 'd';
    }
  }

  //exist in comments

  &-key-qr {
    width: 24px;
    height: 24px;
    background-image: url(../images/icon/qr.svg);
  }

  &-soon {
    width: 34px;
    height: 50px;
    background-image: url(../images/icon/time.svg);
  }

  &-menu-deposit {
    width: 24px;
    height: 24px;
    background-image: url('../images/nav/deposit.svg');
  }

  &-menu-workplace {
    width: 24px;
    height: 24px;
    background-image: url('../images/nav/workplace.svg');
  }

  &-menu-simple {
    width: 24px;
    height: 24px;
    background-image: url('../images/nav/simple.svg');
  }

  &-menu-advanced {
    width: 24px;
    height: 24px;
    background-image: url('../images/nav/advanced.svg');
  }

  &-menu-connect {
    width: 18px;
    height: 18px;
    background-image: url('../images/nav/connection-good.svg');
  }

  &-menu-markets {
    width: 24px;
    height: 24px;
    background-image: url('../images/nav/markets.svg');
  }

  &-checked {
    width: 18px;
    height: 18px;
    background-image: url('../images/checkbox-mark.svg');
  }
}
