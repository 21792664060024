.footer {
  border-top: 1px solid var(--brdr_clr_06);
  &-nav {
    @include block_flex;
    @include flex_justify_between;
    padding: 40px 0;
    > div {
      width: 100%;
    }
    .title {
      color: var(--color_text_28);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 20px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }
    a {
      color: var(--color_text_02);
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 24px;
      &:hover {
        color: var(--color_text_07);
      }
    }
  }
  &-copy {
    border-top: 1px solid var(--brdr_clr_06);
    @include block_flex;
    @include flex_justify_between;
    font-size: 12px;
    padding: 20px 0;
    line-height: 25px;
    color: var(--color_text_03);
    > div {
      width: 100%;
    }
  }
  &-follow {
    display: flex;
    align-items: center;
    a {
      margin: 0 7px;
      opacity: 0.3;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  &-lang {
    text-align: right;
    .flag {
      margin-right: 5px;
    }
    .caret {
      border-width: 5px;
    }
    .dropdown {
      &_btn {
        color: var(--color_text_04);
        font-size: 14px;
        padding: 3px 5px;
        line-height: 18px;
      }
    }
  }
}
