.news {
  height: 100%;
  &_header {
    border-bottom: 1px solid var(--brdr_clr_25);
  }
  &_list {
    max-height: calc(100% - 40px);
    overflow-y: auto;
    &-item {
      color: var(--color_text_31);
      font-size: 12px;
      padding: 12px;
      line-height: 16px;
      border-bottom: 1px solid var(--brdr_clr_25);
    }
  }
}
