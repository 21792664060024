.block-permissions {
  &-item {
    border-bottom: 1px solid #38393d;
    @include block_flex;
    @include flex_align_item_center;
    height: 48px;
    > div {
      width: 50%;
    }
    &-name {
      color: #f9f9fb;
      padding-left: 30px;
    }
    &-checkbox {
      @include block_flex;
      @include flex_align_item_center;
      > div {
        width: 50%;
      }
    }
  }
}
.form-new-alert {
  .currency-dropdown {
    top: 100%;
    margin-top: -1px;
  }
  .box-control-button {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    padding: 0;
    .caret {
      border-top: 5px solid;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
  .form-input > .form-control {
    background: none;
    border: 0;
    font-size: 16px;
    border-bottom: 1px solid var(--brdr_clr_23);
    padding: 4px 0 12px;
    height: 36px;
  }
  .ui-form-control {
    color: var(--color_text_01);
  }
}
