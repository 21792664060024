.success-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  &_checkmark {
    display: block;
    margin-bottom: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    stroke-width: 2;
    stroke: #5fce87;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    -webkit-animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;

    &_circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #5fce87;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    &_check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: none;
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #ffffff;
  }
}

@keyframes fill-dark {
  100% {
    box-shadow: inset 0px 0px 0px 30px #17171a;
  }
}

.dialog-success__dark {
  background-color: #17171a;
  border-color: #5d5d5f;
}

.checkmark__dark {
  animation: fill-dark 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  -webkit-animation: fill-dark 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
