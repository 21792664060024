@mixin border_radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box_shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin web_transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

@mixin web_transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  transform: $transform;
}

@mixin after-element($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  content: '';
}

@mixin block_flex() {
  display: -ms-flexbox;
  display: flex;
}

@mixin flex_wrap() {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex_column() {
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex_row() {
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex_column_reverse() {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@mixin flex_justify_around() {
  justify-content: space-around;
  -webkit-justify-content: space-around;
}

@mixin flex_justify_between() {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

@mixin flex_justify_start() {
  justify-content: start;
  -webkit-justify-content: flex-start;
}

@mixin flex_justify_end() {
  justify-content: end;
  -webkit-justify-content: flex-end;
}

@mixin flex_justify_center() {
  justify-content: center;
  -webkit-justify-content: center;
}

@mixin flex_align_item_end() {
  -ms-flex-align: end !important;
  align-items: flex-end;
}

@mixin flex_align_item_center() {
  -ms-flex-align: center !important;
  align-items: center;
}

@mixin flex_align_item_start() {
  -ms-flex-align: start !important;
  align-items: flex-start;
}

@mixin flex_align_self_start() {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

@mixin flex_align_self_center() {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

@mixin flex_align_self_end() {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

@mixin flex_col_full() {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@mixin flex_col_width($width) {
  -ms-flex: 0 0 $width;
  flex: 0 0 $width;
  max-width: $width;
}

@mixin block_custom_scroll($hideTrackBg: false) {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    @if ($hideTrackBg == false) {
      background: rgba($color: #cccccc, $alpha: 0.1);
    }

    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: #999999, $alpha: 0.1);
    border-radius: 4px;

    &:hover {
      background: rgba($color: #999999, $alpha: 0.2);
    }
  }
}
