.fade {
  .modal-2FA {
    @include web_transform(translate(0, -25%));
    @include web_transition(transform 0.3s ease-out);
  }
}

.in {
  .modal-2FA {
    @include web_transform(translate(0, 0));
  }
}

.modal-2FA {
  max-width: 640px;
  margin: 5% auto 0;
  position: relative;
  width: auto;
  &-content {
    border-radius: 5px;
    position: relative;
    outline: 0;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: var(--bg_clr_11);
    color: var(--color_text_01);
  }
  .close {
    position: absolute;
    width: 32px;
    height: 32px;
    line-height: 32px;
    top: 20px;
    border: 0;
    padding: 0;
    font-size: 30px;
    color: #40b6f0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    right: 20px;
    //background: url(../images/icon/close.svg) no-repeat center;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
    &:hover {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  h2 {
    color: var(--color_text_01);
    font-size: 28px;
    font-weight: 400;
    padding-bottom: 20px;
  }
  &-form {
    .ui-form {
      max-width: 360px;
      margin: 0 auto;
      .modal-text-bottom {
        margin-top: 30px;
        a {
          font-size: 14px;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    padding: 40px 60px;
    .img-left {
      text-align: left;
      padding-bottom: 30px;
      padding-top: 10px;
    }
    .img-center {
      text-align: center;
      padding-bottom: 30px;
      padding-top: 10px;
    }
    &-text {
      color: var(--color_text_31);
      font-size: 14px;
      line-height: 19px;
      padding-bottom: 45px;
    }
    &-btn {
      display: flex;
      align-items: center;
      .btn {
        max-width: 200px;
        width: 100%;
      }
    }
    &-important {
      background-color: rgba(252, 164, 103, 0.2);
      color: #fca467;
      font-size: 18px;
      line-height: 50px;
      margin-bottom: 30px;
      margin-left: -60px;
      margin-right: -60px;
    }
  }

  .box-app {
    padding-top: 20px;

    &-text {
      color: var(--color_text_05);
      font-size: 18px;
      line-height: 16px;
      padding-bottom: 10px;
    }

    .box-app-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding: 30px 0;

      a {
        padding: 5px;
        //img {
        //	height: 40px;
        //}
      }
    }
  }

  .modal-text-bottom {
    margin-top: 40px;
    color: var(--color_text_31);
    text-align: center;
    font-size: 13px;
  }

  .block-key {
    &-text {
      color: var(--color_text_01);
      font-size: 16px;
      line-height: 48px;
      border-bottom: 1px dotted var(--brdr_clr_23);
      margin-bottom: 30px;
    }

    &-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 370px;
      margin: 0 auto;
      padding-bottom: 60px;

      a {
        color: #b2b7bd;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        min-width: 200px;

        &:hover {
          color: var(--color_text_01);
        }
      }
    }
  }

  .block-qr {
    background: white;
    padding: 8px;
    max-width: 220px;
    margin: 0 auto 55px;
  }

  .text-info {
    font-size: 18px;
    line-height: 24px;
    max-width: 370px;
    margin: 0 auto;
    padding-bottom: 30px;
  }
}

.error-2fa {
  margin-top: 12px;
}
