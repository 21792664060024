.progress-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  position: relative;

  .progress-bar-value {
    position: absolute;
    text-align: center;
    width: 40px;
    left: 50%;
    margin-left: -20px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  .progress-line {
    background-color: var(--brdr_clr_27);
    border-radius: 10px;
    height: 16px;
    transition: width 0.5s;
    -webkit-transition: width 0.5s;
  }

  .lines-counts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    //.count-line {
    //  @include setFont('IBM Plex Sans', 400, 0.9375rem, 1.375rem);
    //  margin-left: 12px;
    //}
    //
    //.amount-line {
    //  @include setFont('IBM Plex Sans', 400, 0.8125rem, 1.1875rem);
    //  color: $txt_clr3;
    //}
  }
}
