.table-limit {
  border-radius: 4px;
  white-space: nowrap;
  &-search {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
    .box-control {
      position: relative;
      margin: -8px 0;
    }
    .form-control {
      width: 177px;
      border-color: var(--bg_clr_06);
      background: var(--bg_clr_06);
      padding-left: 35px;
      border-radius: 4px;
    }
    .icon {
      margin: 6px 8px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  th {
    text-align: right;
    border: 0 !important;
    &:first-child {
      text-align: left;
    }
  }
  td {
    text-align: right;
    &.cell-cur {
      padding-left: 37px;
    }
  }
  td.text-center {
    text-align: center;
  }
  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  .cell-cur {
    white-space: nowrap;
    text-align: left;
    width: 100%;
    .hover-info {
      padding-left: 25px;
    }
    .icon-cons {
      margin-right: 7px;
    }
  }
  .open-next {
    background: var(--bg_clr_06);
    td {
      text-align: left;
      border: 0;
      padding-top: 13px;
      padding-bottom: 13px;
    }
    span {
      color: var(--color_text_23);
    }
    .icon-arrow-toggle {
      @include web_transform(rotate(-90deg));
      @include web_transition(all 0.2s ease-in-out);
    }
    &.open .icon-arrow-toggle {
      @include web_transform(rotate(0deg));
    }
  }
  .hover-info {
    font-size: 12px;
    color: var(--color_text_24);
    padding-top: 8px;
    display: none;
  }
  .next-info {
    &:hover {
      background: var(--bg_clr_29);
      .hover-info {
        display: block;
      }
    }
  }
  .icon-payment {
    margin-right: 7px;
  }
  .icon-crypto {
    margin-left: 7px;
  }
}
