.markets_body {
  .radio-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    height: 100%;
    padding: 4px 0;
  }

  &-value-item {
    > svg {
      height: 25px;
      width: 30px;
    }

    > div {
      width: 100%;
      display: flex;
      flex-grow: 1;
    }
  }

  .markets_body-current {
    position: relative;
    padding-bottom: 6px;
  }

  .more-filter {
    .filter-picker {
      position: absolute;
      z-index: 10;
      opacity: 0;
      display: none;
      background: inherit;
      background-color: var(--bg_clr_11);
      box-shadow: $shdw_type_00 var(--clr_shdw_00);
      right: 0;
      top: 0;
      max-height: 200px;
      @include block_custom_scroll();
      overflow-y: auto;

      .markets_body-current-item {
        margin: 0;
        text-align: center;
        width: auto;
        min-width: 40px;
        border-bottom: 1px solid var(--brdr_clr_03);
      }

      &.visible {
        opacity: 1;
        display: block;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.form-control.error {
  border-color: var(--brdr_clr_15) !important;
}

.label.error {
  color: var(--brdr_clr_15) !important;
}

.markets-scroll {
  @include block_custom_scroll();
}
