.notification {
  position: fixed;
  left: $left_bar_width + 17px;
  bottom: 32px;
  z-index: 50;

  &_content {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 16px 16px rgba(0, 0, 0, 0.16);
    width: 290px;
    overflow: hidden;
  }

  &-item {
    @include block_flex;
    @include flex_align_item_center;
    font-size: 12px;
    line-height: 16px;
    color: var(--color_text_02);
    padding: 12px;
    padding-right: 30px;
    margin-bottom: 5px;
    position: relative;
    background: var(--bg_clr_11);
    left: 1;
    opacity: 1;
    @include web_transition(all 0.5s ease-in-out);

    &.out {
      left: -100%;
      opacity: 0;
    }

    & + & {
      margin-top: -1px;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      z-index: 1;

      &:hover {
        opacity: 0.5;
      }
    }

    .value {
      color: var(--color_text_02);
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    .image {
      height: 18px;
      width: 18px;
      border: 1px solid var(--brdr_clr_12);
      color: var(--color_text_09);
      border-radius: 50%;
      line-height: 16px;
      text-align: center;
      margin-right: 12px;
      font-size: 9px;
      @include flex_col_width(18px);
    }

    &.error {
      .image {
        color: var(--color_text_12);
        border-color: var(--brdr_clr_15);
      }
    }
  }

  &-center {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 410px;
    height: 100%;
    background: var(--bg_clr_11);
    border-left: 1px solid var(--brdr_clr_05);
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.5);
    text-align: left;
    @include web_transition(all 0.4s ease-in-out);
    opacity: 0;
    left: -410px;

    &.open {
      opacity: 1;
      left: 100%;
    }

    &_header {
      @include block_flex;
      @include flex_justify_between;
      @include flex_align_item_center;
      padding: 14px 20px 15px;

      .back {
        color: var(--color_text_00);
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;

        .icon {
          margin-right: 7px;
        }
      }

      .link-set {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }

    &_tabs {
      height: calc(100% - 55px);

      .tab-content {
        height: calc(100% - 40px);
      }

      .tab-pane {
        padding: 0;
        height: 100%;
      }

      .nav-tabs {
        @include block_flex;
        padding: 0 20px;
        width: 100%;
        border-bottom: 1px solid var(--brdr_clr_05);

        .title-hover {
          background: var(--bg_clr_11);
          border: 1px solid var(--brdr_clr_05);
          color: var(--color_text_04);
          font-size: 12px;
          line-height: 28px;
          position: absolute;
          left: 70%;
          top: 50%;
          border-radius: 4px;
          padding: 0 10px;
          white-space: nowrap;
          display: none;
          z-index: 1;
        }

        li {
          width: 50px;
          margin: 0;
          margin-bottom: -1px;

          &:after {
            display: none;
          }

          a {
            display: block;
            border-bottom: 1px solid transparent;
            text-align: center;
            position: relative;
            line-height: 30px;
            padding-bottom: 10px;

            &:hover {
              background: none;

              .icon {
                opacity: 0.7;
              }

              .title-hover {
                display: block;
              }
            }
          }

          &.active {
            a {
              border-color: var(--brdr_clr_09);
            }
          }
        }

        &-item {
          cursor: pointer;
          width: 50px;
          margin: 0;
          margin-bottom: -1px;
          display: block;
          border-bottom: 1px solid var(--brdr_clr_05);
          text-align: center;
          position: relative;
          line-height: 30px;
          padding-bottom: 10px;
          outline: none;

          .icon {
            opacity: 0.5;
            @include web_transition(opacity 0.2s ease-in-out);
          }

          &:hover {
            background: none;

            .icon {
              opacity: 0.7;
            }

            .title-hover {
              display: block;
            }
          }

          &.active {
            border-color: var(--brdr_clr_09);

            .icon {
              opacity: 1;
            }
          }
        }
      }
    }

    &_title {
      color: var(--color_text_02);
      font-size: 16px;
      padding: 20px 20px 10px;
      font-weight: 500;
      @include block_flex;
      @include flex_justify_between;
      line-height: 32px;

      .btn-add {
        font-size: 12px;
        color: var(--color_text_01);
        background: var(--bg_clr_11);
        padding: 0 15px;
        line-height: inherit;
        border-radius: 4px;

        .icon {
          margin-left: 5px;
          margin-right: -5px;
        }

        &:hover {
          background: var(--bg_clr_11);
        }
      }
    }

    &_list {
      // padding: 0 20px;
      height: calc(100% - 60px);
      overflow-y: auto;

      .date {
        color: var(--color_text_23);
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 10px;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .cat {
        margin: 10px 20px;
        position: relative;
        background: var(--bg_clr_06);
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        color: var(--color_text_23);

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 50%;
          background-color: var(--bg_clr_31);
          z-index: 1;
        }

        & span {
          color: #00d280;
        }

        span {
          background: inherit;
          padding: 0 8px;
          position: relative;
          z-index: 2;
        }
      }
    }

    .notification-item {
      border: 0;
      background: none;
      font-size: 14px;
      color: var(--color_text_02);
      display: flex;
      align-items: flex-start;
      position: relative;
      padding: 15px 20px;
      line-height: 21px;

      .description {
        padding-left: 15px;
        width: 100%;
      }

      .value {
        text-transform: uppercase;
        color: var(--color_text_01);
      }

      .time {
        font-size: 12px;
        color: var(--color_text_04);
        line-height: 16px;
      }

      &.able-to-read {
        background-color: rgba($color: #7f63ff, $alpha: 0.09);
        border-right: 3px solid #7f63ff;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .list-price {
      padding-bottom: 15px;
      border-bottom: 1px solid #4b4c51;
      margin: 0 -20px 10px;

      &_item {
        padding: 8px 13px;
        @include block_flex;
        @include flex_justify_between;

        &:hover {
          background: var(--bg_clr_11);
          cursor: pointer;

          .action {
            display: block;
          }
        }

        .form-checkbox {
          font-size: 14px;
          line-height: 14px;

          &:hover {
            background: none;
          }
        }

        .value {
          color: var(--color_text_01);
          margin: 0 5px;

          &:first-child {
            margin-left: 0;
          }
        }

        .action {
          display: none;

          a {
            opacity: 0.5;
            width: 24px;
            text-align: center;
            line-height: 28px;
            display: inline-block;
            vertical-align: top;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.icon-alerts {
  &-error {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/red.svg);
  }

  &-check {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/check.svg);
  }

  &-top {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/top.svg);
  }

  &-down {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/down.svg);
  }

  &-var {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/var.svg);
  }
}

.icon-nav {
  &-finance {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/nav/finance.svg);
  }

  &-news {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/nav/info-news.svg);
  }

  &-price {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/nav/price.svg);
  }

  &-system {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/nav/system.svg);
  }

  &-trading {
    width: 24px;
    height: 24px;
    background-image: url(../images/notification/nav/trading.svg);
  }
}
