.fast-exchange-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-image: url('../images/exchange_bg.png');
  background-position: center;
}

.fast-exchange-block__tab {
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.fast-exchange-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 24px;
  max-width: 24px;
  max-height: 24px;
  min-height: 24px;
  background-color: #00b276;
  color: #fff;
  font-weight: bold;
  margin-right: 8px;
  font-size: 16px;

  &.small {
    font-size: 10px;
  }

  &.medium {
    font-size: 12px;
  }
}
// #70b68b;
// #00b276;
.light-theme {
  .fast-exchange-page {
    --gray: #f5f6f8;
    --gray_1: #bbc1c9;
    --gray_2: rgba(0, 13, 29, 0.4);
    --dark-gray: #667280;
    --dark-gray-1: #eaecef;
    --light-gray: #9ba2ac;
    --black: #1d1d1d;
    --select-menu-bg: #ffffff;
    --active-period-selector: #1d1d1d;
  }
}

.dark-theme {
  .fast-exchange-page {
    --gray: #363840;
    --gray_1: #f5f6f8;
    --gray_2: #cdd2d6;
    --dark-gray: #667280;
    --dark-gray-1: #222327;
    --light-gray: #9ba2ac;
    --black: #ffffff;
    --select-menu-bg: #212226;
    --active-period-selector: #ffffff;
  }
}

.fast-exchange-block {
  $blue: #4200ff;
  $red: #d85960;
  $green: #41bd54;

  background-color: var(--bg_clr_11);
  max-width: 1140px;
  width: 100%;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.2);
  border-radius: 17px;
  position: relative;

  .exchange-error {
    display: flex;
    width: fit-content;
    margin: 1em auto;
    border-radius: 0.3em;
    padding: 0.5em;
    color: #fff;
    font-weight: 600;
    background-color: var(--red0);
    text-align: center;
  }

  .widget-container {
    width: 100%;
    min-height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 14px;

    .widget-content {
      width: 100%;
      min-height: 640px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & iframe {
        max-width: 500px;
        margin-bottom: 6px;
      }
    }

    .close {
      position: absolute;
      width: 40px;
      height: 40px;
      line-height: 40px;
      right: 5px;
      top: 5px;
      border: 0;
      padding: 0;
      font-size: 40px;
      background: url('../images/icon/close.svg') no-repeat center;
      background-size: 100% auto;
      -webnkit-background-size: 100% auto;
      color: var(--color_text_01);
      z-index: 1;
      @include web_transition(all 0.2s ease-in-out);
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

    & > .btn-primary {
      width: 220px;
      height: 46px;
      font-size: 20px;
    }
  }

  &__tab {
    outline: none;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 11px 4px;
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;

    &:not(.active) {
      background-color: var(--gray);
      color: var(--dark-gray);
    }

    &.active {
      background-color: transparent;
      color: $blue;
      border-color: $blue;
    }
  }

  .fast-exchange-buy-sell {
    display: flex;
    padding: 50px;

    &__left,
    &__right {
      width: 50%;
      max-width: 50%;
    }

    &__left {
      padding-right: 20px;
    }

    &__right {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
    }

    &__tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 42px;

      > button {
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }

    .buy-sell-tabs-inputs {
      > div {
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }

    &__reference-price {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      color: var(--black);
      margin-top: 10px;
      word-break: break-word;
    }

    &__action-btn-wrapper {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      margin-top: 40px;

      p {
        color: var(--black);
      }
    }
  }

  .exchange-input-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--light-gray);
      margin-left: 6px;
      margin-bottom: 6px;
    }

    &__wrapper {
      background-color: var(--gray);
      display: flex;
      align-items: center;
      width: 100%;
      padding: 9px 16px;
      border-radius: 8px;
      border: 2px transparent solid;

      &.input-error {
        border: 2px tomato solid;
      }

      > label {
        flex-grow: 1;

        input {
          width: 100%;
          border: 0;
          outline: none;
          background-color: transparent;
          font-family: IBM Plex Sans, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: var(--black);
          border-right: 1px solid var(--light-gray);

          &::placeholder {
            font-family: IBM Plex Sans, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: var(--light-gray);
          }
        }
      }
    }

    &__select {
      width: 135px;
      max-width: 135px;
    }

    > p {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: var(--light-gray);
      margin-left: 6px;
      font-weight: 500;
    }
  }

  &__currency-select-option {
    display: flex;
    align-items: center;
    font-family: IBM Plex Sans, sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    color: var(--black);
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    > svg,
    > img {
      margin-right: 8px;
    }
    > img {
      max-width: 24px;
      min-width: 24px;
      max-height: 24px;
      min-height: 24px;
    }

    &--in-list {
      padding: 10px;

      &:first-of-type {
        border-radius: 4px 4px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 4px 4px;
      }

      &:hover {
        background-color: var(--gray);
      }
    }
  }

  &__select-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__currency-icon {
    min-width: 28px;
    max-width: 28px;
    max-height: 26px;
    min-height: 26px;
  }

  .single-select {
    &__title {
      font-family: IBM Plex Sans, sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--light-gray);
      margin-left: 6px;
      margin-bottom: 6px;
    }

    &__wrapper {
      background-color: var(--gray);
      border-radius: 8px;
    }

    &__option {
      font-family: IBM Plex Sans, sans-serif;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0;
      color: var(--black);
      padding: 10px;
      cursor: pointer;

      &:first-of-type {
        border-radius: 4px 4px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 4px 4px;
      }

      &:hover {
        background-color: var(--gray);
      }
    }
  }

  .fast-exchange-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 226px;
    padding: 12px 8px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    border: 0;
    outline: none;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.3s;

    > svg {
      margin-left: 13px;
    }

    &:disabled {
      background-color: var(--dark-gray-1);
      color: var(--gray_1);
      pointer-events: none;

      > svg {
        path {
          fill: var(--gray_1);
        }
      }
    }

    &:not(:disabled) {
      background-color: $blue;
      color: #fff;

      > svg {
        path {
          fill: #fff;
        }
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .third-party {
    padding: 35px 40px 40px 40px;

    &__header {
      font-family: IBM Plex Sans, sans-serif;
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0;
      color: $blue;
    }

    &__back_btn {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      color: #7f63ff;
      cursor: pointer;

      > svg {
        path {
          fill: $blue;
        }
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 24px;
    }

    &__exchange-details {
      margin-top: 38px;
    }

    &__exchange-disclaimer {
      margin-top: 13px;
    }

    &__checkbox {
      margin-bottom: 30px;
      display: flex;

      .form-checkbox {
        font-family: IBM Plex Sans, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--black);

        .checkmark {
          top: 50%;
          margin-top: -10px;
          left: 0;
          height: 20px;
          width: 20px;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 13px;
    }

    .deposit-warning {
      display: flex;
      width: fit-content;
      margin: 1em auto;
      border-radius: 0.3em;
      padding: 0.5em;
      color: #fff;
      font-weight: 600;
      background-color: var(--red0);
      text-align: center;
    }
  }

  .exchange-details {
    padding: 16px 20px;
    background-color: var(--gray);
    border-radius: 8px;
    color: var(--black);
    display: grid;
    grid-template-columns: 120px 1fr;

    font-family: IBM Plex Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;

    &__bold {
      font-weight: bold;
    }
  }

  .exchange-disclaimer {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--black);

    &__header {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 6px;
    }
  }

  .price-stats {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &__price {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: var(--dark-gray);

      > span {
        color: var(--black);
      }
    }

    .price-slider-wrapper {
      width: 250px;
      margin: 0 16px;
    }

    .price-slider {
      position: relative;
      background-color: var(--gray);
      height: 4px;
      max-height: 6px;
      border-radius: 6px;
      width: 100%;

      &__thumb {
        position: absolute;
        top: 50%;
        margin-top: -4px;
        min-height: 8px;
        min-width: 4px;
        background-color: $green;
      }
    }
  }

  .price-stats-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__label {
      padding-bottom: 6px;
      border-bottom: 1px dashed;
      margin-bottom: 4px;
      color: var(--gray_2);
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
    }
  }

  .coin-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;

    &__icon {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;
      color: var(--black);

      > img {
        min-height: 28px;
        max-height: 28px;
        min-width: 28px;
        max-width: 28px;
        margin-right: 10px;
      }
    }

    &__last-price {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 36px;
      line-height: 44px;
      color: var(--black);
      margin: 8px 0 12px 0;
      display: flex;
      align-items: center;

      .price-change {
        font-size: 24px;
        line-height: 38px;
        margin-left: 8px;
      }
    }

    .coin-stat {
      font-family: IBM Plex Sans, sans-serif;
      word-break: break-word;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
      line-height: 26px;

      &__label {
        padding-bottom: 4px;
        border-bottom: 1px dashed var(--light-gray);
        margin-bottom: 4px;
        color: var(--gray_2);
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
      }

      &__value {
        color: var(--black);
      }
    }
  }

  .price-change {
    &.red {
      color: $red;
    }

    &.green {
      color: $green;
    }
  }

  .coin-graph {
    flex-grow: 1;
  }

  .exchange-chart-settings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 16px;

    &__periods {
      margin-right: 12px;
    }

    .chart-settings-periods {
      display: flex;
      align-items: center;
      gap: 12px;

      &__item {
        font-family: IBM Plex Sans, sans-serif;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        opacity: 0.5;

        &.active {
          color: var(--active-period-selector);
          opacity: 1;
        }
      }
    }

    .chart-settings-types {
      display: flex;
      align-items: center;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 3px 8px;
        border: 1px solid var(--dark-gray-1);

        &:first-of-type {
          border-right: 0;
        }

        &:not(.active) {
          svg {
            opacity: 0.3;
          }
        }

        &.active {
          background-color: var(--gray);

          svg {
            fill: var(--black);
          }
        }
      }
    }
  }
}
