.block-balance {
  background-color: var(--bg_clr_11);
  border-radius: 4px;
  margin-top: 22px;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    line-height: 35px;
    border-bottom: 1px solid var(--brdr_clr_26);
    & .hide-zero-balance {
      /*color: red;*/
      color: var(--color_text_02);
    }

    &-search {
      width: 50%;
      .box-control {
        position: relative;
        .icon {
          position: absolute;
          left: 0;
          top: 50%;
          width: 24px;
          height: 24px;
          margin-top: -12px;
        }
        .form-control {
          background-color: transparent;
          padding: 8px 13px 8px 30px;
          border: 0;
        }
      }
    }
    &-filter {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      .ui-form-checkbox {
        position: relative;
        width: inherit;
        height: inherit;
        padding-left: 26px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        color: rgba(255, 255, 255, 0.87);
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    &-item {
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 36px;
    }
  }

  .deposit-warning {
    margin-top: 1em;
    border-radius: 0.3em;
    padding: 0.5em;
    color: #fff;
    background-color: var(--red0);
    text-align: center;
    svg {
      margin-right: 0.5em;
      path {
        #warning-ic_svg__Triangle {
          fill: #fff !important;
        }
        stroke: #fff !important;
      }
    }
  }
}
