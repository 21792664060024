.lm {
  &_dropTargetIndicator {
    outline-color: var(--brdr_clr_17);
  }
}

.grid-layout .lm {
  &_header {
    height: 40px;
    background: var(--bg_clr_02);
    .lm_tabs {
      width: 100%;
      overflow: hidden;
    }
    .lm_tab {
      background: var(--bg_clr_03);
      padding: 7px 12px;
      padding-right: 30px;
      margin: 0;
      color: var(--color_text_02);
      border-left: 1px solid var(--brdr_clr_18);
      box-shadow: none;
      font-family: $font_family;
      @include web_transition(background 0.2s ease-in-out);
      &:first-child {
        border-left: 0;
      }
      &:hover {
        background: var(--bg_clr_02);
        box-shadow: none;
      }
      &.lm_active {
        width: 100%;
        border-top: 2px solid var(--brdr_clr_10);
        background: var(--bg_clr_11);
        border-left-color: var(--brdr_clr_06);
        color: var(--color_text_01);
        box-shadow: none;
        & + .lm_tab {
          border-left-color: var(--brdr_clr_03);
        }
        .title {
          .top {
            color: var(--color_text_09);
          }
          .down {
            color: var(--color_text_12);
          }
        }
      }
      .lm_title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        .normal {
          text-transform: none;
        }
        .title {
          span {
            font-weight: normal;
          }
          .top,
          .down {
            margin-left: 4px;
          }
          &-plus {
            margin: 0 -4px;
          }
          .count {
            color: var(--color_text_31);
            margin-left: 5px;
          }
          .icon-form {
            margin-left: -8px;
            margin-right: 4px;
          }
        }
        .text {
          text-transform: none;
          color: var(--color_text_04);
          margin-left: 4px;
        }
        .value {
          color: var(--color_text_02);
          margin-left: 4px;
        }
        .info {
          text-transform: none;
          color: var(--color_text_04);
          margin-left: 30px;
        }
        .caret {
          opacity: 0.5;
          & + .info {
            margin-left: 12px;
          }
        }
      }
      .lm_close_tab {
        top: 6px;
        right: 6px;
        background: url('../images/theme/gl-close.svg') center;
        opacity: 0.4;
        width: 18px;
        height: 18px;
        @include web_transition(opacity 0.2s ease-in-out);
        &:hover {
          opacity: 1;
        }
      }
    }
    .lm_tabdropdown_list {
      .title {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }
    }
    .lm_controls {
      right: 10px;
      top: 4px;
    }
  }
  &_content {
    background: var(--bg_clr_11);
  }
}

.normal__tabs .lm_active {
  width: auto !important;
}
// ".lm_dragging" is applied to BODY tag during Drag and is also directly applied to the root of the object being dragged

// Entire GoldenLayout Container, if a background is set, it is visible as color of "pane header" and "splitters" (if these latest has opacity very low)
.lm_goldenlayout {
  background: var(--bg_clr_09);
}

// Single Pane content (area in which final dragged content is contained)
.lm_content {
  background: var(--bg_clr_11);
}

// Single Pane content during Drag (style of moving window following mouse)
.lm_dragProxy {
  .lm_content {
    box-shadow: $shdw_type_00 var(--clr_shdw_00);
  }
}

// Placeholder Container of target position
.lm_dropTargetIndicator {
  outline: 3px solid var(--brdr_clr_17);
  box-shadow: none;
  transition: all 200ms ease;

  // Inner Placeholder
  .lm_inner {
    background: var(--bg_clr_24);
    opacity: 0.1;
  }
}

.lm_splitter {
  background: var(--bg_clr_24);
  opacity: 0.001;
  transition: opacity 200ms ease;

  &:hover, // When hovered by mouse...
  &.lm_dragging {
    background: var(--bg_clr_24);
    opacity: 1;
  }
}

.lm_controls {
  .lm_popout {
    background: url('../images/theme/expand.svg') center; // !PopOut Pane, not used for now
  }
  .lm_maximise {
    background: url('../images/theme/expand.svg') center;
  }
  .lm_close {
    background: url('../images/theme/gl-close.svg') center;
  }
}
// If maximized
.lm_maximised {
  .lm_header {
    background-color: var(--bg_clr_02);
  }
  .lm_controls {
    .lm_maximise {
      background: url('../images/theme/collapse.svg') center;
    }
  }
}
.shimmer {
  width: 100%;
  height: 100%;
}
