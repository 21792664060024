.fade {
  .modal-document {
    @include web_transform(translate(0, -25%));
    @include web_transition(transform 0.3s ease-out);
  }
}

.in {
  .modal-document {
    @include web_transform(translate(0, 0));
  }
}

.modal-document {
  max-width: 930px;
  margin: 5% auto;
  position: relative;
  width: auto;
  &-content {
    border-radius: 5px;
    position: relative;
    outline: 0;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: var(--bg_clr_11);
    color: var(--color_text_01);
  }
  &_header {
    padding: 50px 40px 0;
    text-align: center;
    .title {
      font-size: 34px;
      line-height: 40px;
    }
  }
  &_place {
    margin: 0 40px 40px;
    padding: 40px;
    min-height: 190px;
    text-align: center;
    border: 2px dashed var(--brdr_clr_08);
    border-radius: 4px;
    .name {
      font-size: 15px;
    }
    .image {
      margin-bottom: 20px;
    }
    .info {
      margin-top: 12px;
      font-size: 12px;
      color: var(--color_text_02);
    }
  }
  &_item {
    @include block_flex;
    @include flex_align_item_center;
    padding: 40px 0;
    margin: 0 52px;
    & + & {
      border-top: 2px solid var(--brdr_clr_20);
    }
    .text {
      font-size: 14px;
      color: var(--color_text_02);
      line-height: 24px;
    }
    .title {
      color: var(--color_text_01);
      font-size: 24px;
      line-height: 28px;
      max-width: 300px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .image {
      margin: 0 40px;
    }
  }
  .close {
    margin-top: 20px;
    @include web_transform(rotate(0deg));
    @include web_transition(all 0.2s ease-in-out);
    margin-right: 10px;
    opacity: 1;
    &:hover {
      @include web_transform(rotate(180deg));
    }
  }
}
