//----UI form
.ui-form {
  position: relative;

  &-input--with-select {
    margin-top: 0 !important;
  }

  &-name {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid var(--brdr_clr_08);
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  &-group {
    margin-bottom: 40px;
    width: 100%;

    &-line {
      @include block_flex;
      @include flex_justify_between;
      width: 100%;

      > div {
        margin: 0 15px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .ui-form-group {
        width: 100%;
      }
    }

    &-btn {
      @include block_flex;
      @include flex_align_item_center;

      .ui-form-btn {
        margin-top: 10px;
      }
    }
  }

  &-link {
    margin: 10px 0;
    line-height: 20px;
    font-size: 13px;
  }

  &-btn {
    margin-top: 30px;

    .btn-full + .btn-full {
      margin-top: 10px;
    }
  }

  &-text {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &-сaptcha {
    height: 82px;
    margin: 20px 0 25px;
    text-align: center;
  }

  &-input {
    position: relative;
    margin-top: 15px;
    margin-bottom: 0;
    min-height: 70px;
    width: 100%;

    .su-select-label {
      font-size: 13px;
      color: var(--color_text_04);
      pointer-events: none;
    }

    .type-switcher {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      z-index: 3;
      right: 0;
      top: 0;
      height: 44px;
      width: 30px;

      svg {
        width: 22px;
        height: 50%;
        fill: var(--color_text_02);
      }
    }

    &.error {
      .type-switcher {
        right: 26px;
      }
    }

    .dropdown {
      display: block;

      &_btn {
        display: block;
        background: none !important;
        position: relative;

        &:after {
          width: 8px;
          height: 8px;
          border-top: 2px solid var(--brdr_clr_09);
          border-right: 2px solid var(--brdr_clr_09);
          @include web_transform(rotate(135deg));
          content: '';
          right: 0;
          top: 50%;
          position: absolute;
          margin-right: 10px;
          margin-top: -6px;
          pointer-events: none;
        }
      }

      .flag {
        margin-right: 5px;
      }

      .link {
        display: block;
        color: var(--color_text_01);
        font-size: 14px;
        padding: 8px;
        line-height: 18px;

        &:hover {
          background: var(--bg_clr_11);
        }
      }
    }
  }

  &-control {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: right center;
    margin: 0;
    position: relative;
    z-index: 2;
    font-size: 16px;
    color: var(--color_text_13);
    outline: none;
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--brdr_clr_01);
    vertical-align: top;
    line-height: normal;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-clip: padding-box;
    padding: 12px 0;
    height: 44px;
    border-radius: 0;

    &.with-eye {
      padding-right: 50px;
    }

    &-secure {
      //-webkit-text-security: square;
    }

    .error & {
      background-image: url('../images/ui/error.svg');
      border-color: var(--brdr_clr_15);
    }

    .success & {
      background-image: url('../images/ui/ok.svg');
    }

    .fail & {
      background-image: url('../images/ui/fail-ui.svg');
    }

    .caps & {
      background-image: url('../images/ui/caps.svg');
    }

    &-placeholder {
      position: absolute;
      left: 0;
      top: 12px;
      font-size: 16px;
      color: var(--color_text_04);
      @include web_transition(all 0.2s ease-in-out);
      pointer-events: none;

      .focus & {
        font-size: 13px;
        top: 0;
        margin-top: -15px;
      }
    }

    &-focus {
      position: absolute;
      top: 44px;
      left: 0;
      width: 0;
      height: 1px;
      margin-top: -1px;
      background: var(--bg_clr_16);
      @include web_transition(all 0.4s ease-in-out);

      .error & {
        display: none;
      }
    }

    &-help {
      font-size: 14px;
      color: var(--color_text_04);
      position: relative;
      line-height: 1.1;
      top: -2.5em;
      @include web_transition(all 0.2s ease-in-out 0.2s);
      width: 100%;
      margin-top: 5px;
      display: block;
      opacity: 0;

      .error & {
        color: var(--color_text_12);
        top: 0;
      }
    }

    &-btn {
      position: absolute;
      right: 0;
      top: 0;
      line-height: 38px;
      margin-top: 3px;
    }

    &-rules {
      width: 220px;
      padding: 18px;
      font-size: 13px;
      line-height: 20px;
      color: var(--color_text_21);
      border-radius: 8px;
      background: var(--bg_clr_11);
      margin: 0 28px;
      position: absolute;
      top: 50%;
      @include web_transform(translateY(-50%));
      @include box_shadow(var(--pass-hint-shadow));
      margin-top: -20px;
      display: none;
      z-index: 4;

      &.left {
        right: 100%;

        &:after {
          border-left: 8px solid var(--brdr_clr_09);
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          left: 100%;
          bottom: 20px;
        }
      }

      &:after {
        color: inherit;
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        content: '';
      }

      &_item {
        padding-left: 18px;
        position: relative;
        color: var(--color_text_21);
        @include web_transition(all 0.2s ease-in-out);

        &:after {
          height: 6px;
          width: 6px;
          border: 2px solid #dddfe0;
          border-radius: 50%;
          background: var(--bg_clr_14);
          @include after-element(50%, 0);
          margin-top: -5px;
          @include web_transition(all 0.2s ease-in-out);
        }

        &.active {
          color: var(--color_text_29);
          text-decoration: line-through;

          &:after {
            border-color: var(--brdr_clr_12);
            background: var(--bg_clr_08);
          }
        }
      }
    }

    &-status {
      font-size: 14px;
      padding-top: 12px;

      &_line {
        height: 4px;
        width: 100%;
        position: relative;
        background-color: var(--bg_clr_10);

        .progress {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          @include web_transition(all 0.2s ease-in-out);
        }

        &:before {
          @include after-element(0, 25%);
          width: 50%;
          height: 100%;
          border-left: 1px solid var(--brdr_clr_09);
          border-right: 1px solid var(--brdr_clr_09);
        }

        &:after {
          @include after-element(0, 50%);
          width: 1px;
          height: 100%;
          background: var(--bg_clr_14);
        }
      }

      &_label {
        margin-top: 4px;
      }

      &.week {
        color: var(--color_text_12);

        .progress {
          width: 25%;
          background: var(--bg_clr_21);
        }
      }

      &.average {
        color: var(--color_text_15);

        .progress {
          width: 50%;
          background: var(--bg_clr_23);
        }
      }

      &.secure {
        color: var(--color_text_09);

        .progress {
          width: 75%;
          background: var(--bg_clr_18);
        }
      }

      &.extremely {
        color: #41be53;

        .progress {
          width: 100%;
          background: #41be53;
        }
      }
    }

    &:disabled {
      background: var(--bg_clr_15);
      color: var(--color_text_01);
    }

    &:hover {
      ~ .ui-form-control-focus {
        width: 100%;
      }
    }

    ~ .ui-form-control-placeholder.top-fixed {
      font-size: 13px;
      top: 0;
      margin-top: -15px;
    }

    &:not([readonly]):focus {
      ~ .ui-form-control-focus {
        width: 100%;
      }

      ~ .ui-form-control-placeholder {
        font-size: 13px;
        top: 0;
        margin-top: -15px;
      }

      ~ .ui-form-control-help {
        top: 0;
        opacity: 1;
      }

      ~ .ui-form-control-rules {
        display: block;
      }
    }

    &::-webkit-input-placeholder {
      color: var(--color_text_04);
    }

    &:-moz-placeholder {
      color: var(--color_text_04);
    }

    &:-ms-input-placeholder {
      color: var(--color_text_04);
    }

    &.error {
      border-color: var(--brdr_clr_15);
    }

    &.valid {
      border-color: var(--brdr_clr_12);
    }

    &.warning {
      border-color: var(--brdr_clr_16);
    }
  }

  &-checkbox {
    &:not(&--without-offset) {
      margin: 10px 0;
    }

    label {
      display: inline-block;
      cursor: pointer;
      position: relative;
      margin: 0;
      text-align: left;
      line-height: 20px;
      padding: 0;
      padding-left: 27px;
      font-size: 13px;
      font-weight: normal;
      color: var(--color_text_04);
      min-height: 16px;
      min-width: 16px;
      vertical-align: top;

      &:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid var(--brdr_clr_27);
        margin-top: -9px;
        position: absolute;

        left: 0;
        top: 10px;
        //color: $color_text_01;
        //background: $bg_gray;
        border-radius: 2px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-position: center;
        @include web_transition(all 0.2s ease-in-out);
        text-align: center;
        line-height: 10px;
        font-size: 12px;
      }
    }

    &--error {
      label {
        &:before {
          border-color: var(--brdr_clr_15);
        }
      }
    }

    input[type='radio'],
    input[type='checkbox'] {
      display: none;
    }

    &:hover {
      label:before {
        border-color: var(--brdr_clr_10);
      }
    }

    input[type='radio']:checked + label:before {
      border-width: 5px;
    }

    input[type='radio']:checked + label:before,
    input[type='checkbox']:checked + label:before {
      border-color: var(--brdr_clr_10);
      background-color: var(--bg_clr_16);
    }

    input[type='radio']:checked + label,
    input[type='checkbox']:checked + label {
      color: var(--color_text_21);
    }

    input[type='checkbox']:checked + label:before {
      //background-image: url("../images/checkbox-mark.svg");
      background-image: url('../images/ui/ok.svg');
      background-size: 200%;
    }

    &:hover {
      input[type='radio']:checked + label:before,
      input[type='checkbox']:checked + label:before {
        border-color: var(--brdr_clr_11);
        background-color: var(--bg_clr_17);
      }
    }

    input[type='radio'] + label:before {
      border-radius: 50%;
    }

    input:disabled + label {
      color: var(--color_text_03);

      &:before {
        //background: #f3f3f4;
        border-color: #c2c3c7;
      }
    }

    &.alert {
      label {
        &:before {
          border-color: var(--brdr_clr_15);
          background-color: var(--bg_clr_28);
        }
      }
    }
  }
}

textarea.ui-form-control {
  resize: vertical;
  max-height: 300px;
}

.ui-box-select {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:after {
    width: 8px;
    height: 8px;
    border-top: 2px solid var(--brdr_clr_09);
    border-right: 2px solid var(--brdr_clr_09);
    @include web_transform(rotate(135deg));
    content: '';
    right: 0;
    top: 50%;
    position: absolute;
    margin-right: 10px;
    margin-top: -6px;
    pointer-events: none;
  }
}

@media (max-width: 767px) {
  .ui-form-control-rules {
    left: inherit;
    top: inherit;
    right: 0;
    @include web_transform(none);
    bottom: 100%;
    margin: 0 0 20px 0;

    &.left {
      right: 0;

      &:after {
        border-top: 8px solid #fff;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        bottom: inherit;
        top: 100%;
        left: 85%;
      }
    }
  }
}
