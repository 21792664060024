.trader {
  display: flex;
  flex-direction: column;

  &_header {
    background-color: var(--bg_clr_00); // #212226
    padding: 8px 2px;
    color: var(--color_text_01);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 16px;

    .icon-arrow-toggle {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  &_body {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-value-info {
      width: 100%;
      padding: 5px 16px 5px 12px;

      font-size: 11px;
      line-height: 11px;

      word-break: break-word;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 40px - 8px)) 80px;
      grid-column-gap: 8px;

      text-transform: capitalize;
      white-space: pre-line;
      background-color: var(--bg_clr_00); // #212226
      border-top: 1px solid var(--brdr_clr_06);
      color: var(--color_text_31);

      > div {
        display: flex;
        align-items: center;
        min-height: 18px;
      }

      &-pair {
      }

      &-price {
        justify-content: right;
      }

      &-change {
        justify-content: right;
      }
    }

    &-value {
      @include block_custom_scroll();
      overflow-y: auto;

      &-item {
        font-size: 12px;
        line-height: 14px;
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        @include flex_row;
        padding: 2px 12px;
        position: relative;
        color: var(--color_text_02);

        & > div {
          width: 33%;
          word-break: break-word;
        }

        .after {
          display: none;
        }

        &.down {
          .trader_body-value-item-pair {
            color: var(--color_text_12);
          }

          .after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: var(--bg_clr_27);
            z-index: 0;
            display: block;
          }
        }

        &.top {
          .trader_body-value-item-pair {
            color: var(--color_text_09);
          }

          .after {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--bg_clr_08);
            z-index: 0;
            display: block;
          }
        }

        &-pair {
          span {
            &.price-opacity {
              opacity: 0.5;
            }
          }
        }

        &-price {
          text-align: right;
        }

        &-change {
          text-align: right;

          span {
            opacity: 0.7;
          }
        }

        &-stroke {
          padding: 8px 10px;
        }

        &-title {
          font-size: 14px;
          line-height: 16px;
          font-weight: bold;
          width: 45% !important;
        }

        &-text {
          font-size: 14px;
          line-height: 16px;
          width: 45% !important;

          span {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
