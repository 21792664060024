.header {
  @include block_flex;
  @include flex_justify_between;
  @include flex_align_item_center;
  line-height: 38px;
  font-size: 12px;
  color: var(--color_text_03);
  position: absolute;
  top: 3px;
  left: 0;
  width: 100%;
  z-index: 30;
  //border-left: ($left_bar_width +6px) solid $bg_body;
  //background: $bg_clr_11;
  padding-left: $left_bar_width + 6px;
  @include block_flex;
  @include flex_justify_between;

  &.login {
    background: #dde2e5; // #212226
  }

  &.inner {
    padding-left: $left_bar_full_width;
    border-left: 0;
  }

  &.full {
    border-left: 0;
    padding-left: 0;
  }

  &_left {
    @include block_flex;
  }

  &-logo {
    padding: 0 15px;

    img {
      vertical-align: middle;
    }
  }

  &-balance {
    position: relative;
    @include block_flex;
    @include flex_align_item_center;
    color: var(--color_text_03);
    cursor: pointer;

    &_item {
      & + & {
        margin-left: 20px;
      }

      &:hover {
        .icon-dropdown {
          opacity: 1;
        }
      }

      .open & {
        &:hover {
          .icon-dropdown {
            opacity: 1;
          }
        }
      }
    }

    .value {
      color: var(--color_text_01);
    }

    .icon-dropdown {
      margin-left: 5px;
    }

    padding: 0 10px;

    &.open {
      .icon {
        margin-top: 2px;
        @include web_transition(all 0.15s ease);
      }
    }

    &_list {
      display: none;
      position: absolute;
      top: 100%;
      width: 124px;
      z-index: 10;
      right: 0;

      text-transform: uppercase;
      text-align: right;
      line-height: normal;

      .open & {
        display: block;
      }

      &-title {
        font-size: 10px;
        padding: 7px 15px;
        color: var(--color_text_04);
      }

      &-item {
        display: block;
        color: var(--color_text_02);
        padding: 10px 15px;
        cursor: pointer;
        @include web_transition(all 0.2s ease);

        &:hover {
          color: var(--color_text_01);
          background: var(--bg_clr_00);
        }
      }
    }
  }

  &-assets {
    @include block_flex;
    @include flex_align_item_start;
    width: 75%;

    &_item {
      padding: 0 15px;

      .icon-dropdown {
        opacity: 0.5;
        margin-right: 5px;
      }
    }
  }

  &-nav {
    @include block_flex;
    @include flex_align_item_center;

    &_item {
      padding: 0 7px;
      height: 38px;
      color: var(--color_text_02);
      @include block_flex;
      @include flex_justify_between;
      @include flex_align_item_center;
      @include web_transition(all 0.2s ease);
      line-height: normal;
      position: relative;

      &:after {
        height: 24px;
        width: 1px;
        background: var(--bg_clr_06);
        content: '';
        position: absolute;
        top: 50%;
        right: -0.5px;
        margin-top: -12px;
        z-index: 1;
      }

      .title {
        margin: 0 7px;

        .icon {
          margin-left: -7px;
          margin-right: 7px;
        }
      }

      &.connect {
        color: var(--color_text_31);

        .icon {
          margin-right: 0;
          opacity: 0.5;
        }
      }
    }

    &.nav-main {
      .header-nav_item {
        color: #979797;

        .icon {
          opacity: 0.5;
        }

        &:hover {
          color: var(--color_text_01);

          .icon {
            opacity: 1;
          }
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

    .btn-signup {
      padding: 15px 22px;
      margin-top: -3px;
      margin-bottom: -3px;
      border-radius: 0;
      font-size: 13px;
    }
  }

  .header_center {
    .value {
      color: var(--color_text_01);
    }
  }
}

.modal-signup-not-working {
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-signup-container {
  position: absolute;
  z-index: 99;
  height: 300px;
  width: 600px;
  background-color: var(--bg_clr_11);
  box-shadow: 0px 2px 8px rgba(123, 123, 123, 0.3);
  animation: blablabla 0.1s both;
  text-align: center;
  padding-top: 70px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 17px;
  font-weight: 600;
}

.button-close-modal-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.modal-overlay-signup {
  background-color: rgba(0, 0, 0, 0.85);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.market-volume-stats {
  *:focus {
    outline: 0;
  }
  position: relative;

  .period-selector {
    border-radius: 2px;
    border-color: var(--color_text_03);
    background-color: var(--bg_clr_09);
  }

  .loader {
    position: absolute;
    display: inline-block;
    max-height: 100%;
    height: 100%;
    width: auto;
    background-color: transparent;
    top: 50%;
    margin-top: -14px;
    left: auto;
    right: -25px;

    div,
    svg {
      height: 20px;
      width: auto;
    }
  }
}

.dark-theme {
  .period-selector {
    color: var(--color_text_03);
  }
}

.volume-stats-mode-selector-container {
  position: relative;
  display: inline-block;

  .selector {
    position: absolute;
    top: 100%;
    background: var(--bg_clr_11);
    border: 1px solid var(--brdr_clr_05);
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 var(--clr_shdw_00);
    left: 0;
    text-align: left;
    line-height: normal;
    z-index: 30;
    color: var(--color_text_01);
    display: none;
    white-space: nowrap;

    &:after {
      border-bottom: 6px solid var(--brdr_clr_06);
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      content: '';
      position: absolute;
      top: -6px;
      left: 6px;
    }

    ul,
    .range-picker {
      display: inline-block;
      vertical-align: top;
      &.period {
        border-left: 1px solid var(--brdr_clr_05);
      }
    }

    li {
      color: var(--color_text_02);
      border-bottom: 1px solid var(--brdr_clr_05);
      background: var(--bg_clr_11);
      display: block;
      outline: 0;
      line-height: 18px;
      padding: 10px 20px;
      text-align: left;
      cursor: pointer;
      transition: all 0.2s ease-out;

      &:hover {
        background: var(--bg_clr_07);
        color: var(--color_text_00);
      }

      &.active {
        font-weight: bold;
      }
    }

    .rdrDefinedRangesWrapper {
      width: 0;
    }
  }

  .current-mode {
    cursor: pointer;
    .icon-dropdown {
      margin-right: 4px;
    }
  }

  &.open {
    .selector {
      display: inline-block;
    }
  }
}

.lm_item.chart-panel {
  .lm_content {
    .market-volume-stats {
      font-size: 12px;
      position: absolute;
      left: 85px;
      top: -19px;
      z-index: 25;

      .value {
        color: var(--color_text_01);
      }
    }
  }
}
