.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);
  &.fade {
    opacity: 0;
    filter: alpha(opacity=0);
    @include web_transition(opacity 0.15s linear);
    .modal-dialog {
      @include web_transform(translate(0, -25%));
      @include web_transition(transform 0.3s ease-out);
    }
  }
  &.in {
    opacity: 1;
    filter: alpha(opacity=100);
    display: block;
    .modal-dialog {
      @include web_transform(translate(0, 0));
    }
  }
  &-open {
    overflow: hidden;
    .modal {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: var(--bg_clr_11);
    &.fade {
      filter: alpha(opacity=0);
      opacity: 0;
    }
    &.in {
      filter: alpha(opacity=50);
      opacity: 0.5;
    }
  }
  &-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  .close {
    position: absolute;
    // width: 30px;
    // height: 30px;
    line-height: 30px;
    right: 5px;
    top: 5px;
    border: 0;
    padding: 0;
    font-size: 30px;
    // background: url("../images/icon/close.svg") no-repeat center;
    background-size: 100% auto;
    -webnkit-background-size: 100% auto;
    color: var(--color_text_01);
    z-index: 1;
    @include web_transition(all 0.2s ease-in-out);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  &-dialog {
    max-width: 420px;
    margin: 5% auto;
    position: relative;
    width: auto;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 16px 16px rgba(0, 0, 0, 0.16);
    background: var(--bg_clr_11);
    color: var(--color_text_02);
    border-radius: 5px;
  }
  &-sm {
    max-width: 360px;
    background: var(--bg_clr_11);
    .close {
      //right: 10px;
      //top: 10px;
      //width: 18px;
      //height: 18px;
    }
  }
  &-md {
    max-width: 640px;
  }
  &-lg {
    max-width: 830px;
  }
  &-content {
    position: relative;
    outline: 0;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    &_header {
      padding: 12px 15px;
      @include block_flex;
      color: var(--color_text_00);
      font-weight: bold;
      @include flex_justify_between;
      position: relative;
      border-bottom: 1px solid var(--brdr_clr_08);
      font-size: 12px;
    }
    &_body {
      padding: 15px;
    }
    &_title {
      font-size: 11px;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: var(--color_text_02);
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
    &_form {
      padding: 30px;
      .btn-primary {
        min-width: 200px;
      }
    }
  }
  &-header {
    padding: 34px 30px 20px;
    color: var(--color_text_01);
    .text {
      color: var(--color_text_04);
      margin-top: 15px;
      line-height: 20px;
    }
    & + .modal-content_form {
      padding-top: 10px;
    }
    img {
      margin-bottom: 30px;
    }
    .stop {
      color: var(--color_text_02);
      font-size: 14px;
      line-height: 24px;
      margin-top: 40px;
      & + .stop {
        margin-top: 18px;
      }
      & + .text {
        margin-top: 40px;
      }
    }
  }
  &-body {
    padding: 45px 20px;
    position: relative;
    .title {
      color: var(--color_text_01);
      max-width: 480px;
      margin: 35px auto 25px;
    }
    img {
      margin: 15px 0;
    }
    .text {
      line-height: 20px;
      color: var(--color_text_02);
      & + .text {
        margin-top: 30px;
      }
    }
  }
  &-btn {
    margin-top: 35px;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  &-help {
    text-align: center;
    color: var(--color_text_31);
    font-size: 13px;
    padding: 35px;
    margin-top: 30px;
  }
  &-settings {
    padding: 15px 30px;
    .form-group-line {
      margin-bottom: 15px;
    }
    .form-checkbox {
      font-size: 14px;
    }
  }
  &-footer {
    padding: 30px;
    border-top: 1px solid var(--brdr_clr_08);
    @include block_flex;
    @include flex_justify_between;
    @include flex_align_item_center;
    .box-btn {
      width: 46%;
      text-align: center;
      &:first-child:last-child {
        width: 100%;
      }
    }
  }
  &-form {
    padding: 5px 30px 20px;
    border-bottom: 1px solid var(--brdr_clr_08);
    margin-bottom: 20px;
    & + & {
      padding-top: 0;
    }
    .form-group-line {
      margin-bottom: 20px;
      @include flex_justify_between;
      .box-control {
        width: 46%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .form-checkbox {
      font-size: 14px;
    }
    & + .modal-footer {
      margin-top: -20px;
      border-top: 0;
    }
  }
}

.close-order-modal {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  background: var(--bg_clr_11);
  width: 420px;
  height: 100px;
  top: 40%;
  transform: translate(0, -50%);
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 10px 12px 0 var(--bg_clr_04);
  .buttons-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    .size {
      width: 180px;
    }
  }
  .close-order-label {
    margin-left: 10px;
  }
}

.modal.tos {
  .content {
    width: 50%;
    height: 75%;
    position: absolute;
    left: 25%;
    top: 12.5%;
    background-color: #fff;
    padding: 1em;
    border-top: 2px solid var(--brdr_clr_10);
    overflow-y: auto;

    .form-checkbox {
      font-size: 14px;
      margin-bottom: 1em;
    }
    h1 {
      margin-bottom: 1em;
      text-align: center;
      font-weight: bold;
    }
    .box-btn {
      text-align: center;
      width: 98%;
      margin-top: auto;
      button {
        font-size: 16px;
        padding: 8px 14px;
      }
    }
  }
}
