.currency {
  &-dropdown {
    background: #18181c;
    width: 290px;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 12px;
    @include box_shadow(0 5px 15px var(--clr_shdw_01));
    z-index: 10;
    &.right {
      right: 0;
      left: inherit;
    }
    &_search {
      .form-control {
        background: var(--bg_clr_11);
        border-color: var(--brdr_clr_06);
        padding-left: 36px;
        &-icon {
          position: absolute;
          left: 8px;
          top: 8px;
          cursor: pointer;
          opacity: 1;
        }
      }
    }
    &_filter {
      @include block_flex;
      @include flex_justify_between;
      background: var(--bg_clr_11);
      &-item {
        border-bottom: 1px solid transparent;
        color: var(--color_text_02);
        line-height: 28px;
        padding-top: 1px;
        text-align: center;
        width: 20%;
        cursor: pointer;
        @include web_transition(all 0.2s ease-in-out);
        .icon {
          opacity: 0.5;
          @include web_transition(opacity 0.2s ease-in-out);
        }
        .icon-star {
          opacity: 1;
        }
        &:hover {
          color: var(--color_text_01);
          .icon {
            opacity: 1;
          }
        }
        &:first-child {
          width: 15%;
        }
        &.active {
          border-color: var(--brdr_clr_09);
          color: var(--color_text_01);
        }
      }
    }
    &_list {
      max-height: 276px;
      overflow-y: auto;
    }
    &_item {
      @include block_flex;
      @include flex_justify_between;
      @include flex_align_item_center;
      padding: 3px 12px;
      line-height: 18px;
      cursor: pointer;
      &:nth-child(even) {
        background: #1e1e22;
      }
      .name {
        color: var(--color_text_05);
        .icon {
          margin-left: -2px;
          margin-right: 10px;
        }
      }
      .full-name {
        color: var(--color_text_23);
        margin-left: 15px;
      }
      .action {
        opacity: 0;
        .link {
          line-height: 17px;
          border-radius: 3px;
          text-align: center;
          font-size: 10px;
          color: var(--color_text_01);
        }
      }
      &:hover {
        background: var(--bg_clr_06);
        .action {
          opacity: 1;
        }
      }
    }
  }
}
