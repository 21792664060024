.sanctions-form-block {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
  gap: 16px;
  width: 100%;
  height: 100%;
  background-color: var(--bg_clr_09);

  .form {
    position: relative;
    align-self: center;
    justify-self: center;

    padding: 30px;
    width: 890px;
    border-radius: 5px;
    background: var(--bg_clr_11);
    box-shadow: 0 4px 4px 0 var(--clr_shdw_00);

    .form-loader {
      border-radius: 5px;
      background-color: var(--loader-bg);
      z-index: 101;
    }

    &__section {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &-header {
        font-size: 24px;
        line-height: 28px;
        color: var(--color_text_01);
        margin-bottom: 8px;
      }

      &-content {
        $column-gap: 60px;
        $row-gap: 10px;

        display: grid;
        grid-column-gap: $column-gap;
        grid-row-gap: $row-gap;
        width: 100%;
        grid-template-columns: repeat(2, calc(50% - (#{$column-gap / 2})));

        .section-gender {
          $gap: 16px;
          display: grid;
          grid-template-columns: repeat(2, calc(50% - (#{$gap / 2})));
          gap: $gap;
          align-items: center;

          word-break: break-word;
        }
      }
    }

    &__select {
      &-header {
        font-size: 12px;
      }

      &-wrapper {
        &--error {
          .form__select-header {
            color: var(--color_text_12);
          }

          > div > div {
            border-color: var(--brdr_clr_15);
          }
        }
      }
    }

    &__link-container {
      a {
        color: var(--color_text_28);
        text-decoration: underline;

        &:visited {
          color: var(--color_text_28);
        }
      }
    }

    .request-error {
      padding: 16px 0;
      text-align: center;
      word-break: break-word;
      color: var(--color_text_12);
    }

    &__footer {
      display: flex;
      justify-content: flex-end;

      button {
        min-width: 200px;
      }
    }
  }
}

.sanctions-block-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding-right: 2px;
  padding-left: 64px;
  align-items: center;
  z-index: 102;
  background-color: var(--bg_clr_09);

  .header-logo-wrapper {
    display: flex;
    align-items: center;

    > span {
      margin-left: 10px;
      font-weight: 500;
    }
  }
}

.sanctions-frozen-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--bg_clr_09);

  &__content {
    font-weight: bold;
    padding: 30px;
    width: 430px;
    border-radius: 5px;
    background: var(--bg_clr_11);
    box-shadow: 0 4px 4px 0 var(--clr_shdw_00);
    word-break: break-word;

    a {
      color: var(--color_text_28);
      text-decoration: underline;

      &:visited {
        color: var(--color_text_28);
      }
    }
  }
}
