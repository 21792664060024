table {
  width: 100%;

  th {
    color: var(--color_text_06);
    text-align: left;
  }

  td {
    vertical-align: middle;
  }
}

.table {
  width: 100%;

  &-tab {
    display: flex;

    &-item {
      padding: 6px 12px;
      color: var(--color_text_32);
      font-size: 12px;
      font-weight: bold;
      line-height: 20px;
      background-color: var(--bg_clr_31);
      text-transform: uppercase;
      display: block;

      span {
        opacity: 0.35;
      }

      &:not(:last-child) {
        border-right: 1px solid var(--brdr_clr_00);
      }

      &.active {
        background-color: var(--bg_clr_00);
        color: var(--color_text_00);
        border-right: 0;
      }
    }
  }

  &-dropdown {
    @include block_flex;
    @include flex_justify_between;
    @include flex_align_item_center;
    padding: 4px;
    background-color: var(--bg_clr_11);

    &-nav {
      .btn {
        .icon {
          opacity: 0.5;
          @include web_transition(opacity 0.2s ease-in-out);
        }

        &:hover {
          .icon {
            opacity: 1;
          }
        }
      }
    }

    &_list-item {
      @include block_flex;
      border-radius: 8px;
    }

    &-item {
      position: relative;
      z-index: 5;
      color: var(--color_text_03);
      font-size: 12px;

      & + & {
        margin-left: 4px;
      }

      &:hover {
        .table-dropdown-item-header {
          @include web_transition(all 0.2s);
          color: var(--color_text_02);
          padding: -8px;

          .icon-dropdown {
            //margin-top: 2px;
            -webkit-transform: translateY(2px);
            transform: translateY(2px);
            @include web_transition(all 0.2s) //-webkit-transform: rotate(180deg);
              //transform: rotate(180deg);
;
          }
        }

        .table-dropdown-item-body {
          min-width: 225px;
          overflow-x: hidden;
          display: block;
          max-height: 250px;
          overflow-y: scroll;
        }
      }

      &-header {
        width: 100%;
        padding: 8px;
        height: 30px;
        line-height: 16px;
        cursor: pointer;

        .icon-dropdown {
          margin-left: 4px;
        }
      }

      &-body {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 100px;
        background-color: var(--bg_clr_11);
        display: none;

        border: 1px solid var(--brdr_clr_05);
        border-radius: 4px;
        box-shadow: $shdw_type_00 var(--clr_shdw_00);

        &-link {
          @include web_transition(all 0.2s);
          display: block;
          padding: 8px;
          height: 32px;
          color: var(--color_text_03);
          line-height: 15px;

          &:hover {
            @include web_transition(all 0.2s);
            //font-weight: 500;
            background-color: var(--bg_clr_07);
            color: var(--color_text_00);
          }
        }
      }
    }

    &-text {
      color: var(--color_text_02);
      font-size: 12px;
      line-height: 14px;
    }

    &-nav {
      .btn {
        padding: 0;
      }
    }
  }

  &-orders {
    padding-bottom: 20px;
    white-space: nowrap;

    .table-heading {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: var(--bg_clr_00);

      th {
        color: var(--color_text_02);
        font-size: 11px;
        text-transform: uppercase;
        line-height: 28px;
        text-align: center;
        padding: 0 8px;
        height: 28px;
        position: relative;

        & + th {
          &:after {
            content: '';
            position: absolute;
            top: 6px;
            left: -0.5px;
            width: 1px;
            height: 16px;
            display: block;
            background: var(--bg_clr_01);
          }
        }
      }

      .action {
        background: var(--bg_clr_27);
        text-transform: none;
        white-space: nowrap;
        width: 65px;

        .icon {
          margin-right: -5px;
        }

        &:after {
          display: none;
        }

        .dropdown {
          &.open {
            .dropdown_btn {
              background: none;
            }
          }

          &_menu {
            width: 130px;
            margin-right: -8px;
          }
        }
      }
    }

    .table-body {
      &.order-list {
        tr {
          td,
          th {
            width: auto;
          }
        }
      }

      tr {
        &:nth-child(even) {
          background-color: var(--bg_clr_25);
        }

        td {
          text-align: center;
          color: var(--color_text_02);
          font-size: 12px;
          line-height: 16px;
          padding: 4px;
        }

        .value {
          opacity: 0.5;
          white-space: nowrap;
          width: auto;
        }

        .count {
          color: var(--color_text_01);
        }

        .close {
          text-align: right;

          .icon {
            opacity: 0.5;
            @include web_transition(opacity 0.2s ease-in-out);

            &:hover {
              opacity: 1;
            }
          }
        }

        .toggle {
          text-align: left;
          color: var(--color_text_01);

          span {
            opacity: 0.5;
          }
        }

        .arrow {
          text-align: right;
        }
      }

      .open-next {
        .arrow {
          opacity: 0.5;
        }

        &:hover {
          .td-arrow {
            opacity: 1;
          }
        }

        &.open {
          .icon-arrow-toggle {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
        }

        .icon-arrow-toggle {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }

    .desc {
      text-align: left;
      margin: 0 15px;
    }

    .status {
      width: 120px;
      position: relative;

      &-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-bg {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background: var(--bg_clr_08);

          &.sell {
            background: var(--bg_clr_27);
          }
        }
      }

      @media (max-width: 1600px) {
        width: auto;
      }
    }

    .status-info.order-info {
      justify-content: center;
    }

    .cell {
      &-check {
        width: 42px;

        .form-checkbox {
          padding: 0;
          height: 14px;
          width: 14px;
          display: inline-block;
          vertical-align: middle;
          margin-top: -2px;

          .checkmark {
            top: 0;
            left: 0;
          }

          &:hover {
            background: none;
          }
        }
      }

      &-action {
        width: 85px;
        text-align: right !important;
        text-transform: none !important;
        padding-right: 10px !important;

        .icon {
          opacity: 0.5;
          @include web_transition(opacity 0.2s ease-in-out);

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.table-pagination {
  display: flex;
  background-color: var(--bg_clr_09);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 18px 16px;
  color: var(--color_text_02);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;

  &-btn {
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
      padding: 0 5px;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  & > div {
    padding-left: 34px;
  }

  &.no-bg {
    background-color: transparent;
  }
}
