.form {
  &-name {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid var(--brdr_clr_08);
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  &-label {
    color: var(--color_text_23);
    font-size: 13px;
    margin-bottom: 8px;
    padding-top: 5px;
  }

  &-group {
    margin-bottom: 20px;

    &-line {
      @include block_flex;
      @include flex_wrap;

      &_title {
        @include flex_col_full;
        color: var(--color_text_23);
        font-size: 13px;
        margin-bottom: 10px;
        padding-top: 5px;
      }

      .box-control {
        width: 50%;

        &:first-child:last-child {
          width: 100%;

          .form-checkbox {
            margin-right: -7px;
          }
        }

        &:nth-child(odd) {
          .form-checkbox {
            margin-left: -7px;
          }
        }
      }
    }
  }

  &-btn {
    margin-top: 35px;
  }

  &-text {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: var(--color_text_04);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-input {
    position: relative;
  }

  &-value {
    line-height: 20px;
    color: var(--color_text_23);
    font-size: 13px;
    margin-top: 12px;

    .value {
      font-size: 14px;
      color: red; //rgba(255,255,255,0.87)
      margin: 0 7px;
    }

    .item-value {
      margin-right: 30px;
    }
  }

  &-control {
    background-color: var(--bg_clr_11);
    margin: 0;
    font-size: 12px;
    color: var(--color_text_01);
    outline: none;
    width: 100%;
    border: 1px solid var(--brdr_clr_08);
    border-radius: 4px;
    vertical-align: top;
    line-height: normal;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-clip: padding-box;
    padding: 8px 7px;
    height: 32px;
    @include web_transition(all 0.2s ease);

    &:disabled {
      background: var(--bg_clr_09);
      color: var(--color_text_04);
    }

    &:hover {
      border-color: var(--brdr_clr_01);
    }

    &:focus {
      border-color: var(--brdr_clr_10);
      border-width: 1px;
    }

    &::-webkit-input-placeholder {
      color: var(--color_text_04);
    }

    &:-moz-placeholder {
      color: var(--color_text_04);
    }

    &:-ms-input-placeholder {
      color: var(--color_text_04);
    }

    &.error {
      border-color: var(--brdr_clr_15);
    }

    &.valid {
      border-color: var(--brdr_clr_10);
    }

    &.warning {
      border-color: var(--brdr_clr_16);
    }
  }

  &-checkbox {
    @include web_transition(all 0.2s ease);
    display: block;
    position: relative;
    padding: 7px 7px 7px 33px;
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    color: var(--color_text_02);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      border-radius: 4px;
      background: var(--bg_clr_00);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 7px;
      left: 7px;
      height: 14px;
      width: 14px;
      background-color: var(--bg_clr_11);
      border: 2px solid var(--brdr_clr_27);
      border-radius: 2px;
    }

    input[type='radio'] ~ .checkmark {
      border-radius: 50%;

      &:after {
        width: 6px;
        height: 6px;
        background: var(--bg_clr_11);
        top: 2px;
        left: 2px;
        border-radius: 50%;
        position: absolute;
        content: '';
        display: none;
      }
    }

    &:hover input ~ .checkmark {
      background-color: var(--bg_clr_11);
      border-color: var(--brdr_clr_10);
    }

    &:hover input ~ span,
    input:checked ~ span {
      color: var(--color_text_01);
    }

    input:checked ~ .checkmark {
      background: var(--bg_clr_16);
      border-color: var(--brdr_clr_10);

      &:after {
        display: block;
      }
    }

    input[type='checkbox']:checked ~ .checkmark {
      background: url('../images/checkbox-mark.svg') no-repeat center var(--bg_clr_16);
    }

    &:hover input:checked ~ .checkmark {
      background-color: var(--bg_clr_17);
      border-color: var(--brdr_clr_11);
    }
  }

  &-control-toggle {
    line-height: 30px;
    border-radius: 5px;
    opacity: 0.5;
    color: var(--color_text_00);
    padding: 0 15px;
    font-size: 12px;

    .icon {
      margin-left: -5px;
      margin-right: 5px;
    }

    &.active {
      opacity: 1;
      background-color: var(--bg_clr_00);
    }
  }
}

.no-border {
  .form-control {
    border: 0 !important;
  }
}

textarea.form-control {
  resize: vertical;
  max-height: 300px;
}

.box-control {
  position: relative;
}

.box-select {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:after {
    width: 4px;
    height: 4px;
    border-top: 2px solid var(--brdr_clr_01);
    border-right: 2px solid var(--brdr_clr_01);
    @include web_transform(rotate(135deg));
    content: '';
    right: 0;
    top: 50%;
    position: absolute;
    margin-right: 10px;
    margin-top: -4px;
    pointer-events: none;
  }
}

.dropdown-control {
  width: 100%;
  font-size: 12px;
  cursor: pointer;

  .dropdown {
    width: 100%;
    font-size: 12px;
  }

  .dropdown_btn {
    padding: 9px 8px;
    background-color: var(--bg_clr_11);
    border: 1px solid var(--brdr_clr_08);
    border-radius: 4px;
    font-size: 12px;
    color: var(--color_text_03);
    position: relative;
    width: 100%;
    @include web_transition(all 0.2s ease);

    &:after {
      width: 4px;
      height: 4px;
      border-top: 2px solid var(--brdr_clr_02);
      border-right: 2px solid var(--brdr_clr_02);
      @include web_transform(rotate(135deg));
      content: '';
      right: 0;
      top: 50%;
      position: absolute;
      margin-right: 10px;
      margin-top: -4px;
      pointer-events: none;
      border-color: var(--brdr_clr_01);
    }

    .error & {
      border-color: var(--brdr_clr_15);
    }
    .success & {
      background-image: url('../images/ui/ok.svg');
    }

    &:focus,
    &:hover {
      border-color: var(--brdr_clr_01);
    }
  }

  .dropdown_menu-item {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.open .dropdown_btn:after {
    @include web_transform(rotate(-45deg));
    margin-top: -2px;
  }

  &.disabled {
    cursor: not-allowed;
    background: var(--bg_clr_00);
    color: var(--color_text_04);
    .dropdown_btn {
      background: var(--bg_clr_00);
      color: var(--color_text_04);
      border-color: var(--brdr_clr_08);
    }
  }
}
