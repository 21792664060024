.account {
  &__info-block {
    $column-gap: 80px;
    $row-gap: 10px;

    display: grid;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;
    width: 100%;
    grid-template-columns: repeat(2, calc(50% - (#{$column-gap / 2})));
    padding: 24px 32px;

    &-header {
      color: var(--color_text_25);
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      padding-bottom: 6px;
    }

    &-value {
      word-break: break-word;
      min-height: 41px;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 8px 8px 0;
      color: var(--color_text_02);
      border-bottom: 1px solid var(--brdr_clr_08);
      width: 100%;
    }
  }
}
