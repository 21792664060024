.table-api {
  &-header {
    .tr,
    tr {
      .td,
      th {
        color: var(--color_text_26);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 48px;
        padding: 0 6px 0 32px;
        border-bottom: 1px solid var(--brdr_clr_08);
        position: relative;
        vertical-align: middle;
      }
    }
  }

  &-body {
    .tr,
    tr {
      background-color: var(--bg_clr_25);

      .td,
      td {
        color: var(--color_text_02);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 48px;
        padding: 0 6px 0 32px;
        border-bottom: 1px solid var(--brdr_clr_08);
        position: relative;
        vertical-align: middle;
      }

      &.disabled {
        .td {
          color: var(--color_text_26);
        }

        .box-status {
          span {
            color: var(--color_text_26);
          }

          .text {
            color: #fca467;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .box-reveal {
    position: relative;
    display: inline-block;

    &.no-blur {
      .box-reveal-key {
        -webkit-filter: blur(0);
        -ms-filter: blur(0);
        filter: blur(0);

        div {
          padding: 10px;
        }
      }

      .btn-reveal-key {
        display: none;
      }
    }

    &.no-blur-closed {
      .box-reveal-key {
        -webkit-filter: blur(0);
        -ms-filter: blur(0);
        filter: blur(0);
        text-decoration: line-through;
      }

      .btn-reveal-key {
        display: none;
      }
    }

    &-key {
      -webkit-filter: blur(3px);
      -ms-filter: blur(3px);
      filter: blur(3px);
      line-height: 18px;
      div {
        padding: 10px;
      }
    }

    .btn-reveal-key {
      left: 50%;
      margin-left: -55px;
      height: 30px;
      top: 35%;
      color: var(--color_text_01);
      padding: 5px 15px;
      position: absolute;
      border-radius: 4px;
      background-color: #414246;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;

      &:hover {
        color: var(--color_text_10);
      }
    }
  }

  .add-function {
    display: flex;

    a {
      opacity: 0.5;
      padding: 7px;
      position: relative;

      &:hover {
        opacity: 1;
      }
    }

    &-info {
      a {
        &:hover {
          .add-function-info-box {
            display: block;
          }
        }
      }

      .add-function-info-box {
        width: 220px;
        padding: 14px 20px;
        font-size: 12px;
        line-height: 20px;
        color: var(--color_text_01);
        border-radius: 4px;
        background-color: var(--bg_clr_11);
        margin-right: 10px;
        position: absolute;
        bottom: -6px;
        @include box_shadow(0 4px 20px 0 rgba(34, 36, 37, 0.15));
        z-index: 10;
        display: none;
        right: 100%;

        span {
          color: var(--color_text_10);
          padding-left: 5px;
        }

        &:after {
          color: inherit;
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          content: '';
          border-left: 8px solid var(--brdr_clr_06);
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          left: 100%;
          bottom: 12px;
        }

        li {
          display: flex;
          align-items: center;

          .icon {
            display: none;
          }

          &.close {
            text-decoration: line-through;
            opacity: 0.5;

            span {
              display: none;
            }

            .icon {
              display: inline-block;
            }
          }
        }
      }
    }

    &-act {
      position: relative;

      .add-function-act-box {
        width: 140px;
        background-color: var(--bg_clr_11);
        position: absolute;
        border: 1px solid var(--brdr_clr_05);
        border-radius: 4px;
        @include box_shadow(0 4px 20px 0 rgba(34, 36, 37, 0.15));
        z-index: 10;
        display: none;
        left: 0;
        top: 0;

        div {
          padding: 0 20px;
          font-size: 12px;
          line-height: 36px;
          color: var(--color_text_32);
          width: 100%;
          display: block;

          &:hover {
            background-color: var(--bg_clr_07);
            color: var(--color_text_12);
          }
        }
      }
    }
  }

  .box-status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .btn-toggle {
      margin-right: 10px;
    }

    span {
      color: var(--color_text_10);
    }

    .text {
      color: #fca467;
    }
  }
}
