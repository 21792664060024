.block-account {
  background-color: var(--bg_clr_11);
  border-radius: 5px;
  margin-bottom: 34px;
  margin-top: 20px;
  box-shadow: $shdw_type_00 var(--clr_shdw_00);

  &.trading-fees {
    .open-next {
      cursor: pointer;
    }
  }

  &_title {
    color: var(--color_text_02);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 26px;
    padding: 24px 32px;
  }

  &-name {
    color: var(--color_text_02);
    font-size: 24px;
    line-height: 28px;
  }

  &-status {
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin-top: 15px;
  }

  &-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 32px;

    &:not(:first-child) {
      border-top: 1px solid var(--brdr_clr_08);
    }

    .title {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--color_text_25);
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      padding-bottom: 6px;
    }

    .value {
      display: flex;
      justify-content: flex-start;
      color: var(--color_text_02);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 26px;
      padding-bottom: 10px;

      span {
        opacity: 0.6;
      }
    }

    .link {
      a {
        color: var(--color_text_17);
      }
    }

    &_control {
      max-width: 460px;
      width: 100%;

      .box-control {
        position: relative;
        margin-bottom: 20px;

        .label {
          color: var(--color_text_26);
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 20px;
          padding-bottom: 8px;
          display: block;
        }

        .form-control {
          color: var(--color_text_02);
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 28px;
          border: 0;
          border-bottom: 1px solid var(--brdr_clr_08);
          background: transparent !important;
          padding-left: 0;
        }

        a {
          position: absolute;
          right: 0;
          bottom: 8px;
          color: var(--color_text_07);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.25px;
          line-height: 16px;
        }
      }
    }

    &_group {
      margin-top: 30px;
    }

    &_status {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &-icon {
        position: relative;

        span {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          display: block;
          margin-top: -20px;
          color: var(--color_text_07);
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.25px;
          line-height: 26px;
          text-align: center;
        }
      }

      &-info {
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        padding-left: 34px;
        width: 310px;

        .text {
          color: var(--color_text_26);
          padding-bottom: 10px;
        }

        .name {
          color: var(--color_text_02);
          font-size: 20px;
          font-weight: 500;
          padding-bottom: 10px;
        }

        .active {
          color: var(--color_text_17);
        }

        .verification {
          color: var(--color_text_25);
          padding-top: 30px;
          line-height: 23px;

          .unverified {
            background-color: var(--bg_clr_27);
            padding: 2px 4px;
            border-radius: 2px;
          }
        }
      }
    }

    &_soon {
      width: 100%;
      text-align: center;
    }

    &_affiliate {
      width: 17%;

      &:last-child {
        width: 43%;
      }

      .box-control {
        position: relative;

        .label {
          color: var(--color_text_26);
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 20px;
          padding-bottom: 8px;
          display: block;
        }

        .form-control {
          color: var(--color_text_02);
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 28px;
          border-bottom: 1px solid var(--brdr_clr_08);
          background: transparent !important;
          padding-left: 0;
        }

        a {
          position: absolute;
          right: 0;
          bottom: 8px;
          color: var(--color_text_07);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.25px;
          line-height: 16px;
        }
      }
    }

    &_form {
      width: 45%;

      .name {
        color: var(--color_text_02);
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.25px;
        line-height: 26px;
        padding-top: 20px;
        padding-bottom: 10px;
      }

      .step {
        border-bottom: 1px solid var(--brdr_clr_08);
        color: var(--color_text_26);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 24px;
        padding: 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
      }
    }

    &-load {
      width: 100%;
      height: 4px;
      position: relative;
      margin-bottom: 6px;

      &-value {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
      }

      &.green {
        background-color: var(--bg_clr_34);

        .block-account-item-load-value {
          background-color: var(--bg_clr_19);
        }
      }

      &.red {
        background-color: var(--bg_clr_27);

        .block-account-item-load-value {
          background-color: var(--bg_clr_21);
        }
      }
    }

    &_volume {
      width: 100%;

      .day {
        color: var(--color_text_26);
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        text-align: center;
        width: 100%;
      }

      .value {
        color: var(--color_text_02);
        font-size: 48px;
        line-height: 57px;
        text-align: center;
        width: 100%;
      }
    }

    &_trade {
      width: 100%;
      padding-top: 15px;

      &-link {
        a {
          color: var(--color_text_07);
        }
      }

      .level {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: var(--color_text_02);

          &-value {
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 20px;
          }

          &-info {
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 20px;
            color: var(--color_text_26);

            .icon-star {
              width: 26px;
              height: 26px;
            }

            &.first-level {
              color: var(--color_text_26);
            }

            &.silver-level {
              .icon-star {
                background-image: url('../images/icon/favorite-n-w.svg');
              }
            }

            &.gold-level {
              .icon-star {
                background-image: url('../images/icon/favorite-f.svg');
              }
            }
          }

          &-text {
            color: var(--color_text_26);
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 16px;
            padding: 0 10px;
          }
        }
      }

      .toggle {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-top: 22px;

        &-text {
          padding-left: 20px;

          span {
            opacity: 0.6;
          }
        }
      }
    }
  }

  &-btn {
    padding: 20px 32px 24px;

    .btn {
      max-width: 240px;
      width: 100%;
      padding: 11px 10px;
      text-align: center;

      .icon {
        margin-right: 10px;
      }
    }

    &_right {
      @include block_flex;
      @include flex_align_item_center;
      @include flex_justify_end;
      padding: 32px;
      border-top: 1px solid var(--brdr_clr_08);

      .btn + .btn {
        margin-left: 60px;
      }

      &.rel {
        position: relative;
      }
    }
  }

  &-delete {
    padding: 16px 32px;
    background: #131416;
    border-radius: inherit;

    a {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &-control {
    padding: 16px 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid var(--brdr_clr_08);
    }

    &.upload {
      border-bottom: 0;
      border-top: 1px solid var(--brdr_clr_08);
      min-height: 80px;

      &:hover {
        background: var(--bg_clr_11);
      }
    }

    &_image {
      position: absolute;
      left: 0;
      top: 0;
      padding: inherit;

      & + .block-account-control_value {
        padding-left: 64px;
      }
    }

    .title {
      color: var(--color_text_25);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      margin-bottom: 8px;
    }

    .label-value {
      color: var(--color_text_00);
      font-size: 16px;
      letter-spacing: 0.29px;
      line-height: 20px;
    }

    .status {
      margin-top: 8px;
      font-size: 16px;
    }

    .size {
      color: var(--color_text_04);
    }

    .text {
      color: var(--color_text_25);
      font-size: 12px;
      margin-top: 8px;
    }

    .error {
      font-size: 14px;
      margin-top: 7px;
      color: var(--color_text_12);
    }
  }

  .link-control {
    font-weight: 500;
    color: var(--color_text_07);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.25px;

    &:hover {
      color: var(--color_text_08);
      cursor: pointer;
    }

    &.with_btn {
      background: none;
      &:focus {
        outline: none;
      }
    }
  }

  .ui-form-control {
    color: var(--color_text_13);
  }
}
