.reset-password {
  @include block_flex;
  @include flex_wrap;
  min-height: 100%;
  padding: 0 20px;

  > div {
    @include flex_col_full;
  }

  &_logo {
    @include flex_align_self_start;
    text-align: center;
    padding: 35px 0;

    > svg {
      max-width: 180px;
      width: 180px;
      margin-bottom: 30px;
    }
  }

  &_form {
    .ui-form {
      h2 {
        color: var(--color_text_01);
        font-size: 28px;
        font-weight: 400;
        text-align: center;
      }

      .ui-form-text {
        color: var(--color_text_31);
        padding-top: 15px;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      max-width: 360px;
      margin: 0 auto;
    }
  }

  &_footer {
    @include flex_align_self_end;
    text-align: center;
    font-size: 9px;
    color: var(--color_text_32);
    padding: 20px 0;
  }
}
