.markets {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &_stats {
    padding: 6px 8px;
    color: var(--color_text_02);
    width: 100%;

    &_header {
      @include block_flex;
      @include flex_align_item_center;
      padding-bottom: 6px;

      &-icon {
        padding-right: 8px;

        .icon {
          background-size: 100% auto;
          -webnkit-background-size: 100% auto;
          width: 32px;
          height: 32px;
        }
      }

      &-info {
        width: 100%;

        .title {
          font-size: 12px;
          line-height: 18px;
        }

        .value {
          color: var(--color_text_01);
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;
        }

        & > div {
          @include block_flex;
          @include flex_justify_between;
          @include flex_row;
          @include flex_align_item_center;
        }
      }
    }

    &_body {
      .text {
        @include block_flex;
        @include flex_justify_between;
        @include flex_row;
        @include flex_align_item_center;
        font-size: 12px;
        line-height: 18px;
        padding: 1px 0;
      }
    }
  }

  &_header {
    background-color: var(--bg_clr_00); // #212226
    padding: 8px 2px;
    color: var(--color_text_01);
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;

    .icon-arrow-toggle {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  &_body {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &-current {
      padding-top: 6px;
      background: var(--bg_clr_06);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon-star,
      .icon-search {
        path {
        }
      }

      .search-wrapper {
        position: relative;
        margin: 0 8px;
        min-width: 162px;

        .icon {
          position: absolute;
          left: 6px;
          z-index: 2;
          top: 50%;
          margin-top: -9px;
        }

        .search-input {
          display: flex;

          input {
            font-size: 12px;
            line-height: 12px;
            padding: 6px 6px 6px 26px;
            color: var(--color_text_01);
            width: 100%;
            border: 1px solid var(--brdr_clr_01);
            border-radius: 4px;
            outline: none;
            background-color: transparent;

            &:focus {
              border-color: var(--brdr_clr_10);
            }
          }
        }
      }

      &-item {
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        height: 100%;
        border-bottom: 1px solid transparent;
        color: var(--color_text_02);
        padding: 1px 8px 0;
        @include web_transition(all 0.2s ease-in-out);

        .icon {
          opacity: 0.5;
          @include web_transition(opacity 0.2s ease-in-out);
        }

        .icon-star {
          opacity: 1;
        }

        &:hover {
          color: var(--color_text_01);
          cursor: pointer;

          .icon {
            opacity: 1;
          }
        }

        &.active {
          border-bottom: 1px solid var(--brdr_clr_09);
          color: var(--color_text_01);
          opacity: 1;
        }
      }
    }

    &-value {
      overflow-y: auto;

      &-item {
        font-size: 12px;
        line-height: 14px;
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;

        padding: 4px 0;

        &.active {
          .markets_body-value-item-pair {
            color: var(--color_text_17);
          }
        }

        &:nth-child(even) {
          background: var(--bg_clr_09);
        }

        &:hover {
          background: var(--bg_clr_01);
        }

        &-icon {
          width: 25px;
          text-align: center;
        }

        &-pair {
          width: 32%;

          .icon {
            margin-left: 4px;
            margin-right: 6px;
          }
        }

        &-price {
          text-align: center;
          width: 30%;
          padding-left: 4px;
          padding-right: 4px;
        }

        &-cur {
          color: var(--color_text_04);
          text-align: center;
          padding-right: 4px;
          width: 27%;
        }

        &-change {
          text-align: center;
          width: 40%;
          // padding-right: 12px;
        }
      }
    }
  }

  .markets-grid-header {
    display: grid;
    grid-template-columns: 46px 1fr 80px 65px;
    padding: 6px 8px;
    font-size: 11px;
    column-gap: 8px;
    text-transform: capitalize;
    background-color: var(--bg_clr_00);
    color: var(--color_text_31);
    word-break: break-word;

    .row {
      &-name {
        padding-right: 2px;
        display: flex;
        align-items: center;

        grid-column-start: 1;
        grid-column-end: 3;
      }

      &-price {
        padding-right: 2px;
        justify-content: flex-start;
        display: flex;
        align-items: center;
      }

      &-percentage {
        padding-right: 2px;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }

      &-volume {
        padding-right: 2px;
        justify-content: flex-end;
        display: flex;
        align-items: center;

        .volume-label {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    .market-ordering {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 16px;

      > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        opacity: 0.3;
        width: 100%;

        &.active,
        &:hover {
          opacity: 1;
        }

        svg {
          width: 8px;
          height: 14px;

          path {
            fill: rgb(132, 133, 136);
          }
        }

        &:last-of-type {
          justify-content: flex-start;
          transform: rotateZ(180deg);
        }
      }
    }
  }

  .grid-markets-block {
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;

    @include block_custom_scroll();
  }

  .grid-market-item {
    cursor: pointer;

    padding: 2px 8px;
    display: grid;
    grid-template-columns: 46px 1fr 80px 65px;
    font-size: 12px;
    column-gap: 8px;

    &:hover {
      background: var(--bg_clr_01);
    }

    &.active {
      background-color: var(--bg_clr_01);

      .row-name {
        color: var(--color_text_17);
      }
    }

    .market-icon,
    .row-name,
    .row-price,
    .row-percentage {
      display: flex;
      align-items: center;
      word-break: break-word;
    }

    .market-icon {
      justify-content: space-between;

      .svg-icon-balances {
        margin: 0;
      }
    }

    .row-percentage {
      justify-content: flex-end;
    }
  }
}
