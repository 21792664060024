.block-markets {
  width: 100%;
  .tab-pane {
    padding-top: 0;
  }
  &-title {
    line-height: 40px;
    padding: 15px 20px 30px;
    @include block_flex;
    .title {
      color: #ffffff;
      font-size: 34px;
      letter-spacing: 0.25px;
      line-height: 40px;
    }
    .filter-market {
      @include block_flex;
      margin-left: 35px;
      a {
        color: var(--color_text_02);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        width: 180px;
        text-align: center;
        border: 1px solid var(--brdr_clr_24);
        position: relative;
        & + & {
          margin-left: -1px;
        }
        &:hover {
          color: var(--color_text_01);
        }
        &.active {
          border-color: var(--brdr_clr_13);
          color: var(--color_text_10);
          z-index: 1;
        }
      }
    }
  }
  &-slider {
    padding-bottom: 40px;
  }
  &-global {
    padding: 30px 20px;
    @include block_flex;
    &_cap {
      font-size: 14px;
      margin-right: 40px;
      .value {
        font-size: 20px;
        font-weight: 500;
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        margin-top: 10px;
        color: rgba(255, 255, 255, 0.87);
        .status {
          font-size: 14px;
          font-weight: bold;
        }
      }
      .chart {
        margin-top: 35px;
      }
    }
    &_cur {
      padding: 15px 15px 0;
      .title {
        color: var(--color_text_05);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      .cur-item {
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        font-size: 14px;
        color: var(--color_text_05);
        padding: 15px;
        line-height: 18px;
        cursor: pointer;
        margin: 0 -15px;
        width: 280px;
        .name {
          .cur {
            color: rgba(255, 255, 255, 0.6);
          }
        }
        &:hover {
          background: var(--bg_clr_11);
        }
      }
      .all {
        margin-top: 5px;
        .link {
          color: rgba(255, 255, 255, 0.6);
          line-height: 24px;
          border-color: rgba(#979797, 0.5);
          &:hover {
            border-color: var(--brdr_clr_09);
            color: var(--color_text_01);
          }
        }
      }
      .cryptocurrency-icons {
        margin-right: 10px;
      }
      @media (max-width: 1500px) {
        &:last-child {
          display: none;
        }
      }
    }
  }
  &-value {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &-item {
      background-color: var(--bg_clr_30);
      width: 25%;
      border: 1px solid #38393d;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      height: 215px;
      &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &-text {
          .icon {
            margin-right: 8px;
          }
          color: rgba(255, 255, 255, 0.87);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.25px;
          line-height: 20px;
          span {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      &-bottom {
        width: 100%;
        padding-top: 10px;
        &-text {
          color: rgba(255, 255, 255, 0.87);
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0.21px;
          line-height: 24px;
          span {
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
          }
        }
        .value-box {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          &-big {
            color: rgba(255, 255, 255, 0.87);
            font-size: 20px;
            letter-spacing: 0.25px;
            line-height: 26px;
            padding-bottom: 4px;
            padding-right: 4px;
          }
          &-small {
            color: rgba(255, 255, 255, 0.87);
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.21px;
            line-height: 24px;
          }
        }
      }
    }
  }

  &-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &-text {
      color: rgba(255, 255, 255, 0.87);
      font-size: 16px;
      line-height: 28px;
    }
    &-search {
      background-color: var(--bg_clr_33);
      border-radius: 4px;
      padding: 0 6px;
      .box-control {
        position: relative;
        width: 170px;
        .form-control {
          color: rgba(255, 255, 255, 0.87);
          font-size: 12px;
          letter-spacing: 0.4px;
          line-height: 16px;
          border: 0;
          background: 0 0 !important;
          padding-left: 0;
        }
        .icon {
          position: absolute;
          right: 0;
          bottom: 6px;
          color: #4a90e2;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.25px;
          line-height: 16px;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  &-table {
    background: #18181c;
    &_filter {
      padding: 0 20px;
      line-height: 56px;
      @include block_flex;
      @include flex_justify_between;
      .link {
        color: var(--color_text_01);
        opacity: 0.6;
        border-bottom: 1px solid transparent;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        &:hover {
          opacity: 0.8;
        }
        &.active {
          opacity: 1;
          border-color: var(--brdr_clr_09);
        }
      }
      .cur {
        .link {
          padding: 0 17px;
        }
      }
      .time {
        .link {
          margin: 0 12px;
        }
      }
    }
    .table {
      border-radius: 4px;
      margin-bottom: 0;
      .table-search {
        display: inline-block;
        vertical-align: top;
        line-height: normal;
        margin-left: 10px;
        .form-control {
          width: 177px;
          border-radius: 4px;
          background-color: var(--bg_clr_11);
          font-size: 14px;
          border: 0;
          padding-left: 30px;
        }
        .icon {
          position: absolute;
          left: 8px;
          top: 8px;
        }
      }
      .cryptocurrency-icons {
        margin-right: 10px;
      }
      .marked {
        height: 14px;
        width: 9px;
        background: #737475;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        @include web_transition(all 0.2s ease-in-out);
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 100%;
          border-right: 4px solid transparent !important;
          border-top: 7px solid #737475;
          border-left: 4px solid #737475;
          border-bottom: 7px solid #737475;
          @include web_transition(all 0.2s ease-in-out);
        }
        .title-hover {
          color: #b2b7bd;
          font-size: 12px;
          background: #050505;
          line-height: 28px;
          position: absolute;
          left: 130%;
          top: 70%;
          border-radius: 4px;
          padding: 0 10px;
          white-space: nowrap;
          display: none;
          z-index: 1;
        }
        &:hover {
          background: var(--bg_clr_03);
          width: 12px;
          &:after {
            border-color: var(--brdr_clr_01);
          }
          .title-hover {
            display: block;
          }
        }
        &.active {
          background: #00b8f6;
          &:after {
            border-color: #00b8f6;
          }
        }
      }
      &-header {
        background-color: var(--bg_clr_30);
        text-align: right;
        .tr,
        tr {
          .td,
          th {
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.1px;
            line-height: 32px;
            padding: 8px;
            border-bottom: 1px solid #38393d;
            position: relative;
            vertical-align: middle;
            background-color: var(--bg_clr_32);
            white-space: nowrap;
            text-align: right;
            &:first-child {
              padding-left: 30px;
            }
            &:last-child {
              padding-right: 30px;
            }
          }
        }
      }
      &-body {
        max-height: 980px;
        overflow-y: auto;
        text-align: right;
        .tr,
        tr {
          .td,
          td {
            color: rgba(255, 255, 255, 0.87);
            font-size: 14px;
            padding: 8px;
            border-bottom: 1px solid #38393d;
            position: relative;
            vertical-align: middle;
            &:first-child {
              padding-left: 30px;
            }
            &:last-child {
              padding-right: 30px;
            }
          }
          &:hover {
            .td,
            td {
              background: var(--bg_clr_11);
            }
          }
        }
      }
      .value {
        color: rgba(255, 255, 255, 0.87);
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 20px;
        padding-bottom: 2px;
      }
      .label {
        color: #626467;
        font-size: 12px;
        letter-spacing: 0.21px;
        line-height: 18px;
      }
      .box-pairs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        &-icon {
          padding-right: 14px;
        }
      }
      .cell {
        &-number {
          text-align: left;
          width: 100px;
          position: relative;
          .status {
            margin-left: 10px;
          }
        }
        &-name {
          text-align: left;
        }
        &-price {
          padding-right: 38px;
          position: relative;
          .link-set {
            opacity: 0.5;
            right: 0;
            top: 50%;
            position: absolute;
            margin-top: -10px;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .chart-table-market {
        width: 120px;
        height: 40px;
        margin: -4px 0;
        overflow: hidden;
        display: inline-block;
        vertical-align: top;
      }
      .progress-bar {
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}

.block-markets-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    li {
      opacity: 0.5;
      width: 24px;
      height: 24px;
      margin-left: 10px;

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }
}

.block-markets-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg_clr_30);
  border-bottom: 1px solid #38393d;
  padding-right: 7px;
  .nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .nav-item {
    line-height: 48px;
    border-bottom: 2px solid var(--brdr_clr_22);
    width: 160px;
    text-align: center;
    &.active {
      border-bottom: 2px solid #41bd54;
      a {
        color: #41bd54;
      }
    }
    a {
      width: 100%;
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
      line-height: 46px;
      text-align: center;
      display: block;
      text-transform: uppercase;
    }
  }
}
//unused
//.red1{color: #F54865}
//.red2{color: #F05670}
//.red3{color: #E9657C}
//.red4{color: #E27689}
//.red5{color: #DB8696}
//.red6{color: #D596A3}
//.red7{color: #CDA7B0}
//.red8{color: #C7B6BC}
//.null{color: $color_text_32}
//.green1{color: #2FCE67}
//.green2{color: #3ECD71}
//.green3{color: #51CB7D}
//.green4{color: #65CA8A}
//.green5{color: #78C896}
//.green6{color: #8CC7A3}
//.green7{color: #9FC6B0}
//.green8{color: #B2C4BC}
