.txt-input {
  position: relative;
  width: 100%;
  display: block;

  &--with-title {
    margin-top: 16px;
  }

  &--with-error {
    .txt-input__header {
      color: var(--color_text_12);
    }

    .txt-input__field {
      border-color: var(--brdr_clr_15);

      &:not(:focus) {
        &:hover {
          border-color: var(--brdr_clr_15);
        }
      }
    }
  }

  &__header {
    position: absolute;
    z-index: 1;
    font-size: 16px;
    bottom: 10px;
    transition: transform 0.3s;

    &--animated {
      transform: translate(-13%, -28px) scale(0.75);
    }
  }

  &__field {
    position: relative;
    z-index: 2;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 8px 8px 0;
    outline: none;
    color: var(--color_text_01);
    border: 0;
    border-bottom: 1px solid var(--brdr_clr_08);
    width: 100%;
    background-color: transparent;

    &--hoverable {
      &:hover {
        border-color: var(--brdr_clr_01);
      }

      &:focus {
        border-color: var(--brdr_clr_10);
      }
    }
  }
}
