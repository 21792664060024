.btn {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  padding: 8px 10px;
  line-height: normal;
  position: relative;
  border-radius: 3px;

  &-sm {
    font-size: 12px;
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-md {
    font-size: 16px;
    border-width: 2px;
    padding: 0.907em 1.25em;
  }

  &-primary {
    border-color: var(--brdr_clr_10);
    background: var(--bg_clr_16);
    color: var(--color_text_05);

    &:hover {
      @include web_transition(all 0.2s ease-in-out);
      border-color: var(--brdr_clr_11);
      background: var(--bg_clr_17);
      color: var(--color_text_05);
    }

    .upper {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
    }
  }

  &-lg {
    min-width: 270px;
    //text-transform: uppercase;
    font-size: 16px;
    padding-top: 18px;
    padding-bottom: 18px;

    &.btn-full {
      min-width: 100%;
    }
  }

  &-red {
    background-color: var(--bg_clr_21);
    color: var(--color_text_05);
    padding: 12px;

    &:hover {
      background-color: var(--bg_clr_22);
    }
  }

  &-green {
    background-color: var(--bg_clr_19);
    color: var(--color_text_05);
    padding: 12px;

    &:hover {
      background-color: var(--bg_clr_20);
    }
  }

  &-purple {
    background-color: var(--violet0);
    color: var(--color_text_05);
    padding: 12px;

    &:hover {
      background-color: var(--violet1);
    }

    @media (max-height: 768px) {
      padding: 6px;
      font-size: 12px;
    }
  }

  &-red,
  &-green,
  &-purple {
    &.disabled,
    &:disabled {
      background: var(--bg_clr_31);
      opacity: 1;
      color: var(--color_text_27);
    }
  }

  &-blue {
    background-color: #4a90e2;
    color: var(--color_text_05);
  }

  &-menu {
    border: 0;
    padding: 10px 15px;
    line-height: 2px;
    text-align: right;
    width: 52px;

    .icon-bar {
      display: inline-block;
      vertical-align: top;
      background: var(--bg_clr_11);
      width: 22px;
      height: 2px;
      @include web_transition(all 0.2s ease-in-out);

      & + .icon-bar {
        margin-top: 5px;
      }

      &:nth-child(2) {
        width: 18px;
      }

      &:nth-child(3) {
        width: 20px;
      }
    }

    &.open {
      .icon-bar {
        &:first-child {
          @include web_transform(translateY(7px) rotate(45deg));
          width: 100%;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          @include web_transform(translateY(-7px) rotate(-45deg));
          width: 100%;
        }
      }
    }
  }

  &-toggle {
    background-color: rgb(213, 213, 213);
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 26px;
    text-decoration: none;
    transition: all 0.2s ease;
    height: 16px;
    width: 35px;
    position: relative;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
    @include web_transition(all 0.2s ease-in-out);

    .toggle {
      width: 20px;
      height: 20px;
      background-color: rgb(96, 97, 102);

      border-radius: 50%;
      display: block;
      position: absolute;
      left: 0;
      top: -2px;
      @include web_transition(all 0.2s ease-in-out);
    }

    &.on {
      @include web_transition(all 0.2s ease-in-out);
      background-color: var(--bg_clr_19);

      .toggle {
        @include web_transition(all 0.2s ease-in-out);
        right: 0;
        left: inherit;
        background: var(--bg_clr_20);
      }
    }
  }

  &-link {
    color: var(--color_text_02);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding: 5px;
    margin: 0 25px;

    &:hover {
      @include web_transition(all 0.2s ease-in-out);
      color: var(--color_text_08);
    }
  }

  &-full {
    width: 100%;
  }
}

.link {
  display: inline-block;
  vertical-align: top;

  &-underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &-control {
    font-weight: 500;
    color: var(--color_text_07);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.25px;

    &:hover {
      color: var(--color_text_08);
    }
  }
}

.box-control-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  line-height: 32px;
  padding: 10px;
  width: 60px;

  a {
    opacity: 0.3;
    padding: 2px;
    color: var(--color_text_05);

    &:hover {
      opacity: 1;
    }
  }
}

.custom-radio {
  $main-color: var(--brdr_clr_10);

  position: relative;
  cursor: pointer;
  word-break: break-word;
  display: flex;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark-container {
      .checkmark {
        display: block;
      }
    }
  }

  //&:hover input ~ .checkmark-container {
  //  background-color: $main-color;
  //}

  .checkmark-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 50%;
    border-color: $main-color;
    border-style: solid;

    .checkmark {
      position: absolute;
      display: none;
      min-width: 66%;
      max-width: 66%;
      min-height: 66%;
      max-height: 66%;
      border-radius: 50%;
      background: $main-color;
    }
  }

  &.sm {
    padding-left: 17px;
    line-height: 10px;
    font-size: 11px;
    text-transform: lowercase;

    .checkmark-container {
      min-height: 10px;
      max-height: 10px;
      min-width: 10px;
      max-width: 10px;
      border-width: 1px;
    }
  }

  &:not(.sm) {
    padding-left: 22px;
    line-height: 16px;

    .checkmark-container {
      height: 17px;
      width: 17px;
      border-width: 2px;
    }
  }
}
