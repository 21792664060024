.result_container {
  @include block_flex;
  @include flex_align_item_center;
  @include flex_justify_center;
  height: 100%;
  .result {
    background-color: var(--bg_clr_11);
    max-width: 452px;
    width: 100%;
    @include block_flex;
    @include flex_align_item_center;
    @include flex_justify_center;
    @include flex_column;
    padding: 38px;
  }
  .title {
    font-size: 20px;
    text-align: center;
  }
  .mt-2 {
    margin-top: 16px;
  }
  .description {
    font-size: 12px;
  }
}
