.leftbar + .page-inner {
  padding-left: $left_bar_full_width;
}

.page-inner {
  @include block_flex;
  @include flex_wrap;
  min-height: 100%;

  &-down {
    width: 100%;
    @include flex_align_self_end;
  }

  &-up {
    width: 100%;
    @include flex_align_self_start;
  }

  &_navigate {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 16px;
    text-align: center;

    ul {
      border-bottom: 2px solid var(--brdr_clr_08);
      @include block_flex;
      @include flex_justify_between;

      li {
        width: 100%;

        a {
          color: var(--color_text_02);
          opacity: 0.75;
          border-bottom: 2px solid transparent;
          height: 50px;
          @include block_flex;
          @include flex_align_item_center;
          @include flex_justify_center;
          margin-bottom: -2px;
          letter-spacing: 2px;

          &:hover {
            opacity: 1;
          }
        }

        &.active {
          a {
            color: var(--color_text_07);
            border-bottom-color: var(--brdr_clr_10);
            opacity: 1;
          }
        }
      }
    }
  }

  table {
    color: var(--color_text_01);
    font-size: 14px;
    text-align: left;
    //background: $bg_clr_11;
    font-weight: 500;

    th {
      color: var(--color_text_26);
    }

    th,
    td {
      padding: 16px 12px;
      border-bottom: 1px solid var(--brdr_clr_08);
    }

    td {
      color: var(--color_text_02);
    }
  }
}

table.fees-table {
  color: var(--color_text_01);
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  min-width: 40%;

  th {
    color: var(--color_text_26);
    font-weight: bold;
  }

  th,
  td {
    padding: 12px 10px;
    border-bottom: 1px solid var(--brdr_clr_08);
  }

  td {
    color: var(--color_text_02);
  }

  tbody {
    tr td {
      transition: color 0.2s ease-out;
    }

    tr:hover td {
      color: var(--color_text_00);
    }
  }

  &.narrow {
    td,
    th {
      &:first-of-type {
        text-align: left;
      }
    }

    min-width: 25%;

    td,
    th {
      text-align: center;
    }
  }
}
