.login_page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &__content {
    width: 561px;
    padding: 30px 0;
    border-radius: 5px;
    background: var(--bg_clr_11);
    box-shadow: 0 4px 4px 0 var(--clr_shdw_00);
  }

  &__header {
    display: flex;
    justify-content: center;

    .logo {
      max-width: 180px;
      width: 180px;
      margin-bottom: 30px;
    }
  }
}
