.documents-history {
  padding-top: 130px;
  display: flex;
  justify-content: center;

  &_wrapper {
    width: 1180px;
  }

  &_title {
    font-size: 24px;
  }

  &_content {
    margin-top: 38px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.08);
    max-height: 60vh;
    overflow-y: auto;

    &_search {
      position: relative;
      height: 44px;
      border-bottom: 1px solid #e9ecea;

      &_icon {
        position: absolute;
        top: 50%;
        left: 23px;
        transform: translateY(-50%);
      }

      &_input {
        height: 100%;
        width: 100%;
        border: none;
        font-size: 16px;
        padding-left: 50px;
        color: #657087;
        letter-spacing: 1px;

        &:focus {
          outline: none;
        }
      }
    }

    &_table {
      &_loader {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &_row {
        height: 52px;
        border-bottom: 1px solid #e9ecea;

        &__active {
          background-color: rgba($color: #7f63ff, $alpha: 0.05);
        }
      }

      &_th {
        color: #8c94a5;
        vertical-align: middle;

        &_comment {
          width: 25%;
        }

        &_attention {
          width: 60px;
        }
      }

      &_td {
        color: #657087;

        &_wrapper {
          display: flex;
          align-items: center;

          &__attention {
            justify-content: center;
          }
        }

        &_icon {
          margin-right: 20px;
        }

        &_comment {
          display: flex;
        }

        &_status {
          margin-left: 20px;
          text-transform: capitalize;
        }

        &_type {
        }
      }

      &_button {
        cursor: pointer;
        color: #7f63ff;
        padding: 8px;
        border: 1px solid #7f63ff;
        border-radius: 5px;
        background: none;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &_icon {
          margin-bottom: 3px;
          margin-right: 15px;
        }
      }

      &__empty {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        line-height: 52px;
        font-size: 22px;
      }
    }
  }

  &_modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #2c2c2c, $alpha: 0.3);

    &_container {
      max-height: 92%;
      overflow-y: auto;
      padding: 30px;
      position: absolute;
      top: 50%;
      left: calc(50% + 58px);
      transform: translate(-50%, -50%);
      width: 720px;
      background-color: #ffffff;
      border-radius: 3px;
      opacity: 0;
      animation: appearance 0.4s linear;
      -webkit-animation: appearance 0.4s linear;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;

      &_cancel {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 50%;
        background: none;

        &_mark {
          position: relative;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 1px;
          height: 20px;
          background-color: #041517;

          &:first-child {
            position: absolute;
            transform: rotate(45deg);
          }
          &:last-child {
            position: absolute;
            transform: rotate(-45deg);
          }
        }
      }

      &_title {
        display: flex;
        justify-content: center;
        font-size: 20px;
      }

      &_description {
        display: inline-block;
        margin-top: 30px;
        font-size: 14px;
      }

      &_dropzone {
        margin-top: 30px;
        width: 100%;
        height: 140px;
        border: 1px dashed #7f63ff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        &_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &_button {
          margin-left: 15px;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: none;
          color: #7f63ff;
          border: 1px solid #7f63ff;
          border-radius: 5px;
          cursor: pointer;

          &_icon {
            margin-right: 15px;
          }
        }
      }

      &_limit {
        margin-top: 10px;
        font-size: 12px;
        color: #657087;
      }

      &_grid {
        /*margin-top: 40px;
        max-height: calc(100vh - 10vh - 475px);
        overflow-y: auto;*/

        &_icon {
          &__error {
            transform: rotate(180deg);

            & path {
              fill: #f66132;
            }
          }
        }

        &_row {
          display: grid;
          grid-template-columns: 60px 45px 1fr 60px;
          border-bottom: 1px solid #e9ecea;
          opacity: 0;
          animation: appearance 0.2s linear;
          -webkit-animation: appearance 0.2s linear;
          animation-fill-mode: forwards;
          -webkit-animation-fill-mode: forwards;

          &:last-child {
            border: none;
          }
        }

        &_cell {
          padding: 10px 0;
          display: flex;
          justify-content: center;
          flex-direction: column;

          &:last-child {
            align-items: flex-end;
            padding-right: 10px;
          }

          &__error {
            margin-top: 10px;
            color: #f66132;
          }

          &_button {
            background: none;
            cursor: pointer;
          }
        }
      }

      &_button {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &_submit {
          margin-top: 40px;
          padding: 10px 30px;
          background-color: #7f63ff;
          border-radius: 5px;
          color: #ffffff;
          cursor: pointer;
          transition: background-color 0.3s;
          -webkit-transition: background-color 0.3s;
        }

        &__disabled {
          background-color: #c8cedb;
          cursor: initial;
        }
      }
    }
  }
}

.document {
  &-pending {
    color: #7f63ff;

    & path {
      fill: #7f63ff;
    }
  }
}

.status {
  &-pending {
    color: #f3a561;

    & path {
      fill: #f3a561;
    }
  }
  &-completed {
    color: #5fce87;

    & path {
      fill: #5fce87;
    }
  }
  &-submitted {
    color: #2f80ed;

    & path {
      fill: #2f80ed;
    }
  }
}

@keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.documents-history__dark {
  background-color: #17171a;
  border-color: #5d5d5f;
}
