.buysell {
  max-height: 100%;
  overflow-y: auto;

  &-panel {
    .lm_controls {
      display: none;
    }

    .lm_header {
      background: var(--bg_clr_09);
    }
  }

  &_body {
    padding: 12px;
    min-width: 270px;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      @media (max-height: 768px) {
        margin-bottom: 4px;
      }

      &-text {
        width: 25%;
        color: var(--color_text_31);
        font-size: 12px;
        line-height: 18px;
      }

      &-value {
        margin-left: 15px;
        width: 70%;

        &.market-view {
          width: calc(35% - 8px);
        }

        &.left-auto {
          margin-left: auto;
        }

        &-tab {
          display: flex;
          flex-direction: row;

          &-tabs {
            width: 50%;
            color: var(--color_text_32);
            font-size: 12px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background-color: var(--bg_clr_32);
            border-bottom: 1px solid var(--brdr_clr_25);

            &.active-tabs {
              background-color: var(--bg_clr_31);
              color: var(--color_text_01);
              border-bottom: 1px solid var(--brdr_clr_09);
            }
          }
        }

        &-form {
          .box-control {
            position: relative;

            input.form-control:read-only {
              cursor: not-allowed;
              border: 1px solid var(--brdr_clr_08);
              background-color: var(--bg_clr_00);
            }

            .value {
              height: 32px;
              line-height: 32px;
              color: var(--color_text_02);
              font-size: 12px;
              width: 30px;
              text-align: center;
              position: absolute;
              top: 0;
              right: 5px;
            }

            .box-control-button {
              position: absolute;
              top: 0;
              right: 30px;
            }
          }
        }

        &-text {
          width: 100%;
          text-align: right;
          color: var(--color_text_02);
          font-size: 10px;
          margin-bottom: 4px;

          &.price-range {
            span.setPrice {
              cursor: pointer;

              &:hover {
                font-weight: bold;
              }
            }
          }
        }

        &-factor {
          width: 100%;
          font-weight: 500;
          color: var(--color_text_04);
          padding: 0;
          display: flex;
          justify-content: flex-end;

          &-item {
            @include web_transition(all 0.2s ease-in-out);
            color: var(--color_text_02);
            font-size: 10px;
            display: inline-block;
            vertical-align: bottom;
            padding: 0 7px;
            text-transform: uppercase;
            position: relative;

            & + & {
              &:after {
                position: absolute;
                background: var(--bg_clr_01);
                width: 1px;
                height: 10px;
                top: 3px;
                left: -0.5px;
                content: '';
              }
            }

            &:hover {
              color: var(--color_text_07);
              cursor: pointer;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .box-btn {
      padding-top: 10px;

      &-double {
        @include block_flex;
        @include flex_justify_between;
        display: flex;
        justify-content: flex-end;

        .btn {
          width: 40%;
        }
      }
    }

    .error {
      margin-bottom: 4px;
    }
  }

  &_tabs {
    .nav-tabs {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      li {
        width: 50%;

        a {
          display: block;
          width: 100%;
          background-color: #27282d;
          padding: 10px 12px;
          position: relative;
          border-bottom: 1px solid transparent;
          text-align: center;
          font-size: 12px;
          color: var(--color_text_31);
          line-height: 16px;
        }

        &.active {
          color: var(--color_text_00);

          &.block-sell {
            a {
              background-color: rgba(245, 42, 101, 0.15);
              border-bottom-color: var(--brdr_clr_15);
            }

            .name {
              color: var(--color_text_12);
            }

            .value {
              color: rgba(255, 255, 255, 1);
            }

            .add {
              display: block;
              color: var(--color_text_12);
            }
          }

          &.block-buy {
            a {
              background-color: rgba(0, 200, 127, 0.15);
              border-bottom-color: var(--brdr_clr_12);
            }

            .name {
              color: var(--color_text_09);
            }

            .value {
              color: rgba(255, 255, 255, 1);
            }

            .add {
              display: block;
              color: var(--color_text_09);
            }
          }
        }

        &.block-sell {
          .block-sell-add {
            display: none;
            font-size: 9px;
            line-height: 16px;
            position: absolute;
            text-transform: uppercase;
            width: 60px;
            right: -30px;
            z-index: 4;
            top: 20px;
            text-align: center;

            span {
              display: block;
              font-size: 12px;
            }
          }
        }

        &.block-buy {
          .block-buy-add {
            display: none;
            font-size: 9px;
            line-height: 16px;
            position: absolute;
            text-transform: uppercase;
            width: 60px;
            left: -30px;
            z-index: 4;
            top: 20px;
            text-align: center;

            span {
              display: block;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .tab-pane {
    display: none;

    &.active {
      display: block;
    }
  }
}

.buysell {
  height: 100%;
  width: 50%;
}

.lm_stack.buysell-panel {
  .lm_content {
    overflow: visible;
  }
}
