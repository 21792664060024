.history {
  padding-bottom: 5px;
  width: 100%;
  height: calc(100% - 31px);
  display: flex;
  flex-direction: column;

  &_body {
    max-width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    @include block_custom_scroll();
  }
}
