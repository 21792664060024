.chat-widget {
  font-size: 13px;
  height: 100%;
  @include web_transition(width 0.2s ease-in-out);
  &_header {
    @include block_flex;
    @include flex_justify_between;
    @include flex_align_item_center;
    height: 40px;
    padding: 5px;
    .status {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: #e2e6ec;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 7px;
      &.online {
        background-color: #2fce67;
      }
    }
    &-title {
      font-size: 12px;
      .icon {
        margin-left: 4px;
      }
    }
    .icon-nav-dropdown {
      width: 24px;
      height: 24px;
    }
    &-nav {
      .link {
        opacity: 0.5;
        @include web_transition(opacity 0.2s ease-in-out);
        &:hover {
          opacity: 1;
        }
      }
    }
    .chat_user-list_nav {
      display: inline-block;
      vertical-align: top;
      .box-drop {
        .icon {
          opacity: 0.5;
        }
        &:hover {
          .icon {
            opacity: 1;
          }
        }
      }
    }
  }
  &_body {
    height: calc(100% - 40px);
    color: #797a7d;
  }
  &_dialog {
    position: absolute;
    width: 100%;
    height: 100%;
    right: -100%;
    top: 0;
    background: var(--bg_clr_14);
    z-index: 2;
    opacity: 0;
    @include web_transition(all 0.2s ease-in-out);
    &.open {
      right: 0;
      opacity: 1;
    }
    &-close {
      position: absolute;
      top: 12px;
      right: 10px;
      z-index: 10;
    }
    .chat_dialog {
      width: 100%;
      &-body {
        height: calc(100% - 40px);
      }
      &-header {
        line-height: 15px;
        padding: 5px 12px;
        padding-right: 25px;
        &_person {
          &-info {
            margin-left: 0;
            .name {
              font-size: 13px;
            }
            .date {
              font-size: 11px;
            }
          }
        }
      }
      &-body {
        padding: 0 8px 5px;
        &_date {
          font-size: 10px;
        }
      }
      &-item {
        padding: 0 0 8px;
        color: #797a7d;
        .person {
          &_image {
            @include web_transform(scale(0.45));
            margin: -15px -23px -15px 0;
            transform-origin: 0 50%;
            -webkit-transform-origin: 0 50%;
          }
        }
        .text {
          margin-left: 0;
          padding-top: 6px;
          font-size: 10px;
          line-height: 14px;
          .name {
            font-size: 12px;
            margin-bottom: 2px;
          }
        }
      }
      &-send {
        padding: 0;
        margin-top: 5px;
        &_form {
          padding-top: 5px;
          &-nav {
            .link {
              opacity: 0.5;
              &:hover {
                opacity: 1;
              }
            }
          }
          textarea.form-control {
            font-size: 10px;
            padding-top: 8px;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  &_list-user {
    border-top: 1px solid var(--brdr_clr_24);
    height: 100%;
    &-search {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      .box-control {
        position: absolute;
        right: 0;
        top: 4px;
        width: 100%;
        height: 100%;
        @include web_transition(width 0.2s ease-in-out);
      }
      .form-control {
        padding-left: 40px;
        border: 0;
        &-icon {
          position: absolute;
          left: 9px;
          top: 7px;
          cursor: pointer;
          opacity: 1;
        }
      }
      &:not(.open) {
        position: relative;
        .box-control {
          width: 0;
          @include web_transition(none);
        }
        .form-control {
          padding: 0;
          &-icon {
            top: -2px;
            left: -24px;
            opacity: 0.5;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    &-title {
      color: var(--color_text_02);
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 10px 12px 5px;
    }
    &-results {
      overflow-y: auto;
      max-height: 100%;
    }
    .chat_user-info_member {
      padding: 0 10px;
      font-size: 12px;
      .person {
        &_image {
          @include web_transform(scale(0.45));
          margin: -15px -23px -15px 0;
        }
        &_name {
          font-size: 12px;
        }
      }
    }
    .chat_user-list_channel {
      padding: 8px 10px;
      line-height: 14px;
      font-size: 12px;
      &:after {
        display: none;
      }
      .name {
        font-size: 13px;
        padding-left: 0;
      }
    }
  }
}
