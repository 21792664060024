.chart {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-panel {
    position: relative;

    .lm_controls {
      padding-right: 150px;

      .lm_maximise {
        display: none;
      }
    }

    .lm_content {
      overflow: visible;
    }

    &.has-child {
      .lm_header {
        .hide-child {
          display: none;
        }

        .lm_tabdropdown_list {
          right: 153px;
          top: 28px;
        }
      }

      .chart_action-new {
        .title {
          display: none;
        }

        .icon {
          margin-left: -10px;
        }
      }
    }
  }

  &_action {
    position: absolute;
    top: -28px;
    right: 0;
    @include block_flex;
    white-space: nowrap;
    z-index: 1;

    &-new,
    &-display {
      font-size: 12px;
      color: var(--color_text_04);
      line-height: 18px;
      padding: 5px 5px 5px 15px;
      background: var(--bg_clr_06);
      cursor: pointer;
      @include web_transition(all 0.2s ease-in-out);

      &:hover {
        background: var(--bg_clr_11);
      }

      &.open {
        background: var(--bg_clr_11);
      }
    }

    &-display {
      border-left: 1px solid var(--brdr_clr_06);
      position: relative;

      &_menu {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        background: var(--bg_clr_11);
        z-index: 2;
        box-shadow: $shdw_type_00 var(--clr_shdw_00);
        padding: 4px 0;
        display: none;

        &.open {
          display: block;
        }

        .link {
          color: var(--color_text_02);
          display: block;
          padding: 4px 12px;
          line-height: 18px;

          &:hover {
            background: var(--bg_clr_06);
          }
        }

        .icon {
          margin-right: 5px;
        }
      }
    }
  }

  img {
    width: 100%;
  }

  &_header {
    @include block_flex;
    @include flex_justify_between;
    @include flex_align_item_center;
    line-height: 38px;
    font-size: 12px;

    &-info {
      @include block_flex;

      &-item {
        margin: 0 12px;
      }
    }

    &-nav {
      text-align: right;

      .title {
        margin-right: 10px;
      }

      .link {
        padding: 0 4px;
        width: 35px;
        text-align: center;
      }
    }

    & + .chart-candle {
      flex-grow: 1;
    }
  }

  &-market,
  &-candle {
    flex-grow: 1;

    > div {
      height: 100%;
    }

    img {
      height: 100%;
    }
  }

  &-market {
    padding: 12px;
  }

  &-help-hover {
    position: absolute;
    bottom: 30px;
    right: 60px;
    width: 180px;
    z-index: 11;
    height: auto !important;
    background: var(--bg_clr_11);
    line-height: 36px;
    font-size: 12px;
    color: var(--color_text_02);

    .link {
      color: inherit;
      display: block;
      padding: 0 12px;

      .box-img {
        width: 28px;
        display: inline-block;
        vertical-align: top;
      }

      .icon {
        opacity: 0.5;
        @include web_transition(opacity 0.2s ease-in-out);
      }

      &:hover {
        background: var(--bg_clr_11);

        .icon {
          opacity: 1;
        }
      }
    }
  }
}
