.price-alert {
  height: 100%;
  &_nav {
    padding: 4px;
    .btn-add {
      font-size: 12px;
      color: var(--color_text_02);
      padding: 0 10px;
      border-radius: 4px;
      line-height: 30px;
      .icon {
        opacity: 0.5;
        @include web_transition(opacity 0.2s ease-in-out);
        margin-left: -3px;
        margin-right: 3px;
      }
      &:hover {
        background: var(--bg_clr_09);
        color: var(--color_text_01);
        .icon {
          opacity: 1;
        }
      }
    }
  }
  &_body {
    max-width: 100%;
    overflow: auto;
    max-height: calc(100% - 38px);
  }
}
