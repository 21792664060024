.table-balance {
  &-header {
    background-color: var(--bg_clr_11);

    .tr,
    tr {
      .td,
      th {
        color: var(--color_text_26);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 48px;
        padding: 0 12px;
        border-bottom: 1px solid var(--brdr_clr_08);
        position: relative;
        vertical-align: middle;
      }
    }
  }

  &-body {
    .tr,
    tr {
      background-color: var(--bg_clr_11);

      .td,
      td {
        color: var(--color_text_02);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 48px;
        padding: 0 12px;
        border-bottom: 1px solid var(--brdr_clr_08);
        vertical-align: middle;

        &.td-actions {
          width: 260px;
        }
      }

      .td-actions-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }

      .btn {
        &.open {
          background: var(--bg_clr_11);

          .icon-donwload-top {
            background-image: url('../images/icon/download-arrow-top-red.svg');
          }

          .icon-donwload {
            background-image: url('../images/icon/download-arrow-green.svg');
          }

          &:hover {
            background: var(--bg_clr_11);
          }
        }

        &:hover {
          background: var(--color_text_28);
        }
      }

      &.open-next-tr {
        background-color: var(--bg_clr_00);
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.6);
        @include web_transform(scale(1.015));

        td {
          border-color: #38393d;
          border: 0;
        }
      }

      &.open-tr {
        background-color: var(--bg_clr_11);
        @include web_transform(scale(1.015));

        > td {
          padding: 0;
          border: 0;
        }
      }

      &.status-maintenance {
        .td {
          opacity: 0.2;
          font-weight: normal;
        }

        .td-actions {
          opacity: 1;
          color: #ffd692;

          .icon {
            margin-right: 7px;
          }
        }
      }
    }
  }
}

.add-info {
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.6);
  line-height: normal;

  &.exchange__dialog__header .dialog_modal {
    width: 60%;
    left: calc(41% + 58px);
  }

  .box-control {
    .label {
      color: var(--color_text_02);
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 16px;
      position: relative;
      bottom: -8px;
      margin-left: 15px;
      background-color: var(--bg_clr_11);
    }

    .form-control {
      color: var(--color_text_01);
      font-size: 16px;
      letter-spacing: 0.15px;
      line-height: 24px;
      padding: 16px;
      border-radius: 4px;
      height: 56px;
      background: transparent;
      border: 1px solid var(--brdr_clr_04);
      overflow: hidden;
    }
  }

  .gti-section {
    display: flex;
    align-items: flex-end;

    .gti-input-section {
      margin-right: 25px;
    }

    .btn-gti-deposit {
      height: 56px;
    }

    .input-fiat {
      width: 200px;
    }
  }

  .gti-current-status-section {
    display: flex;

    .gti-current-status {
      margin-left: 10px;
      margin-top: 3px;

      &-warning {
        color: #f66132;
      }

      &-success {
        color: #52be7d;
      }
    }
  }

  .or-text {
    text-decoration: underline;
    margin: 0 30px;
  }

  .global-unit-pay-link {
    font-weight: bold;
    color: #897df7;
    margin-left: 10px;
  }

  .gti-message {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .arrow-icon {
    margin-left: 10px;
    transform: scale(0.8);
  }

  .confirmation-letter-container {
    margin: 30px auto 0 auto;
  }

  .confirmation-letter-text {
    margin-bottom: 45px;
  }

  .confirmation-letter-loader {
    text-align: center;
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  padding: 0 12px;

  &.add-info-deposit {
    .add-info-header {
      justify-content: start;
      align-items: flex-start;

      &-item {
        &:first-child {
          width: 60%;
        }

        a {
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 1.25px;
          margin-top: 17px;
          margin-left: 25px;
          display: block;
          text-transform: uppercase;
          font-weight: 500;

          .icon {
            margin-right: 6px;
          }
        }
      }
    }

    .chain-selector {
      flex-direction: column;
      align-items: center;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;

    &_text {
      color: rgba(255, 255, 255, 0.87);
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 36px;
    }

    a {
      color: var(--color_text_07);

      &:hover {
        color: var(--color_text_08);
      }
    }

    .gti-section a {
      color: var(--white0);
    }

    &-item {
      a.action-ic-block {
        height: 56px;
        display: flex !important;
        align-items: center;
      }
    }
  }

  //.copied-wrapper {
  //  color: red;
  //  transition: all .500s;
  //}

  &-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0 24px;

    &.balances-info-body {
      align-items: flex-start;
    }

    &-item {
      &.btn-wrapper {
        padding-top: 18px;
      }

      .btn {
        padding: 16px;
        border-radius: 4px;
        height: 56px;
        line-height: 28px;
        width: 100%;
        letter-spacing: 1.25px;
        font-weight: bold;
      }

      width: 19%;

      &:first-child {
        width: 37%;
      }
    }
  }

  &-withdraw {
    justify-content: space-between;
    width: 400px;

    .box-control {
      width: 200px;
    }

    .btn-wrapper {
      width: 150px;
    }
  }

  &-twoFa {
    width: 650px;
  }
}

.copied-wrapper {
  position: absolute;
  height: 20px;
  min-width: 70px;
  margin-top: -45px;
  text-align: center;
}

.copied-wrapper span {
  font-size: 14px;
  font-weight: 300;
}

.custom-radio-button:checked,
.custom-radio-button:not(:checked) {
  display: none;
}

.custom-radio-button:checked + label,
.custom-radio-button:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.custom-radio-button:checked + label:before,
.custom-radio-button:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid var(--violet1);
  border-radius: 100%;
  background: #fff;
}

.custom-radio-button:not(:checked) + label:before {
  border: 2px solid #848588;
}

.custom-radio-button:checked + label:after,
.custom-radio-button:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--violet1);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}

.custom-radio-button:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
}

.custom-radio-button:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
}

.deposit-method-select-container {
  display: flex;
  padding: 14px 0;

  .deposit-method-select {
    font-size: 16px;
    padding-right: 30px;
  }

  .deposit-method-select-label {
    margin-left: 14px;
  }

  .quantum_label {
    display: flex !important;
    font-weight: bold;
    align-items: center;
  }

  .gupay_label {
    line-height: normal;
    font-size: 12px;
    padding-left: 8px;
  }

  .deposit-method-radio_group {
    margin-right: 110px;

    &-quantum {
      margin-bottom: 28px;
    }
  }
}

.adv_form_container {
  padding-top: 28px;
}

.withdrawal_gti_fiat {
  width: 200px;
}

.voucher-tab {
  margin-top: -2em;

  .tab-pane-info-title {
    padding: 0;
    text-align: right;
    font-weight: 550;
  }

  .add-info {
    box-shadow: none;
    padding: 0;
  }

  label {
    background-color: var(--bg_clr_09) !important;
  }

  button {
    position: absolute;
    right: 0.5em;
    top: 2em;
  }

  .red {
    text-align: right;
  }
}

.sepa_form_container {
  min-width: 498px;
  max-width: 498px;

  .sepa_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }

    .sepa_value {
      display: flex;
      align-items: center;

      > svg {
        margin-left: 8px;
        cursor: pointer;

        path {
          fill: var(--color_text_07);
        }
      }
    }
  }

  .sepa_attention {
    margin-top: 28px;
    word-break: break-word;
    padding: 7px;
    border-radius: 0.3em;
    background-color: var(--red0);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    > svg {
      margin-right: 8px;
      max-width: 24px;
      max-height: 24px;

      path {
        stroke: white;
      }
    }
  }

  .sepa_deposit_fees {
    word-break: break-word;
    padding: 16px 0;
    color: #f66132;
    display: flex;
    align-items: center;

    > svg {
      margin-right: 16px;
    }
  }
}

.withdraw_sepa_form {
  display: flex;
  align-items: center;
  padding: 16px 0;

  &.warning {
    color: #f66132;
  }

  > svg {
    margin-right: 16px;
  }

  &.with-fields {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 0;
  }

  .input-form-wrapper {
    width: 250px;
  }
}

.withdrawal-info-details {
  > div {
    margin: 5px 0;
  }
}

.sepa-withdraw-fee {
  display: flex;
  align-items: center;
  color: #f66132;
  padding-bottom: 16px;

  > svg {
    margin-right: 16px;
  }
}
