.dialog {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #2c2c2c, $alpha: 0.3);
  display: none;
  opacity: 0;
  animation: appearance 0.2s linear;
  -webkit-animation: appearance 0.2s linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

  &_modal {
    max-height: 92%;
    overflow-y: auto;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: calc(50% + 58px);
    transform: translate(-50%, -50%);
    width: 720px;
    background-color: #ffffff;
    border-radius: 3px;
    opacity: 0;
    animation: appearance 0.2s linear;
    -webkit-animation: appearance 0.2s linear;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    &_btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      border-radius: 50%;
      background: none;

      &_mark {
        position: relative;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 1px;
        height: 20px;
        background-color: #041517;

        &:first-child {
          position: absolute;
          transform: rotate(45deg);
        }
        &:last-child {
          position: absolute;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.dialog__visible {
  display: block;
}

@keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dialog__dark {
  background-color: #17171a;
  border-color: #5d5d5f;
}

.dialog_button__dark {
  background-color: #c6ccd9;
}
