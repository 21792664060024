.rdrDefinedRangesWrapper {
  border-right: solid 1px var(--brdr_clr_05);
  background: var(--bg_clr_11);
  border-top-left-radius: 4px;

  .rdrStaticRangeSelected {
    color: var(--color_text_01);
  }
}

.rdrStaticRanges button:first-child {
  border-top-left-radius: 4px;
}

.rdrDateDisplayWrapper {
  background: var(--bg_clr_06);
  border-top-right-radius: 4px;
}

.rdrCalendarWrapper {
  color: var(--color_text_01);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayNumber:after {
  background: var(--bg_clr_11);
}

.rdrMonths {
  background: var(--bg_clr_11);
  border-bottom-right-radius: 4px;
}

.rdrStaticRange {
  color: var(--color_text_02);
  border-bottom: 1px solid var(--brdr_clr_05);
  background: var(--bg_clr_11);

  &:hover,
  &:focus {
    .rdrStaticRangeLabel {
      background: var(--bg_clr_07);
      color: var(--color_text_00);
    }
  }
}

.rdrDefinedRangesWrapper {
  border-color: var(--brdr_clr_05);
  border-bottom-left-radius: 4px;
}

.rdrMonthAndYearWrapper {
  background: var(--bg_clr_11);
}

.rdrDateDisplay {
  background: var(--bg_clr_06);
}

.rdrMonthAndYearPickers {
  background: var(--bg_clr_11);
}

.rdrMonthAndYearPickers select {
  color: var(--color_text_02);

  &:hover,
  &:focus {
    background: var(--bg_clr_07);
    color: var(--color_text_00);
  }
}

.rdrInputRangeInput {
  background: var(--bg_clr_11);
}

//.rdrDateDisplayItemActive {
//  border-color: var(--brdr_clr_10);
//}

.rdrDateDisplayItemActive input,
.rdrDateDisplayItem {
  background: var(--bg_clr_11);
}

.rdrDateDisplay input {
  border-radius: 4px;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  background: var(--bg_clr_11);
}

.rdrInputRanges {
  color: var(--color_text_01);
}

.rdrDay {
  color: var(--color_text_01);
}

.rdrDayNumber span {
  color: var(--color_text_01);
}
