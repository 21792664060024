.page-help {
  min-height: 100%;
  background: #f6f7fc;
  .header {
    padding: 0;
    background: #1f1f1f;
    top: 0;
    &-logo {
      img {
        height: 20px;
      }
    }
    &-links {
      a {
        color: var(--color_text_01);
        margin-left: 35px;
        display: inline-block;
        vertical-align: top;
        font-size: 13px;
        &:hover {
          color: var(--color_text_04);
        }
      }
    }
  }
  .footer {
    background: var(--bg_clr_14);
    padding: 22px 0 18px;
    line-height: 20px;
    border: 0;
    &-logo {
      img {
        height: 20px;
      }
    }
  }
  .container {
    max-width: 1210px;
    margin: 0 auto;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
  .flexible {
    width: 100%;
  }
  .box-control {
    position: relative;
  }
  .btn-border {
    border: 1px solid var(--brdr_clr_13);
    border-radius: 4px;
    font-weight: 400;
    color: var(--color_text_10);
    font-size: 12px;
    letter-spacing: 2px;
    padding: 8px 24px;
    text-transform: uppercase;
    &:hover {
      box-shadow: 0 2px 5px 0 var(clr_shdw_02);
    }
  }
  &_search {
    padding: 106px 0;
    background: var(--bg_clr_11);
    color: var(--color_text_01);
    .form-control {
      border-radius: 4px;
      background-color: #242424;
      color: var(--color_text_01);
      font-size: 16px;
      padding: 18px 16px 18px 52px;
      height: 56px;
      border-color: rgba(255, 255, 255, 0.2);
      &-icon {
        position: absolute;
        left: 20px;
        top: 18px;
      }
      &::-webkit-input-placeholder {
        color: var(--color_text_24);
      }
      &:-moz-placeholder {
        color: var(--color_text_24);
      }
      &:-ms-input-placeholder {
        color: var(--color_text_24);
      }
    }
  }
  &_nav {
    padding: 40px 0 60px;
    @include block_flex;
    @include flex_justify_center;
    &-item {
      width: 170px;
      margin: 30px 60px;
      text-align: center;
      .image {
        img {
          height: 120px;
        }
      }
      .name {
        font-weight: bold;
        letter-spacing: 1.25px;
        font-size: 14px;
        margin-top: 20px;
        color: var(--color_text_30);
        text-transform: uppercase;
      }
    }
  }
  &_center {
    .title {
      margin-left: 20px;
      color: var(--color_text_34);
    }
    &-cat {
      @include block_flex;
      @include flex_justify_between;
      @include flex_wrap;
      padding-bottom: 30px;
      &__item {
        background: var(--bg_clr_14);
        display: block;
        border-radius: 4px;
        width: 32%;
        padding: 24px 20px;
        margin: 12px 0;
        min-height: 120px;
        &:hover {
          background: var(--bg_clr_19);
          color: var(--color_text_01);
          .name,
          .text {
            color: var(--color_text_01);
          }
        }
        .name,
        .text {
          @include web_transition(all 0.2s ease-in-out);
        }
        .name {
          font-size: 18px;
          color: var(--color_text_10);
          margin-bottom: 7px;
        }
        .text {
          font-size: 13px;
          color: #626467;
          line-height: 22px;
        }
      }
    }
    &-nav {
      @include block_flex;
      @include flex_justify_between;
      @include flex_wrap;
      margin: 40px 0 70px;
      &__item {
        padding-left: 20px;
        max-width: 318px;
        .name {
          color: var(--color_text_30);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.25px;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
        ul {
          li {
            line-height: 20px;
            font-size: 13px;
            color: var(--color_text_33);
            margin: 12px 0;
            position: relative;
            &:after {
              width: 3px;
              height: 3px;
              border-radius: 50%;
              content: '';
              position: absolute;
              left: -20px;
              top: 8px;
              background: var(--bg_clr_19);
            }
            a {
              color: var(--color_text_33);
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
  &_breadcrumb {
    font-size: 12px;
    color: #b2b7bd;
    line-height: 22px;
    padding: 20px 0;
    .breadcrumb {
      a {
        color: #1f1f1f;
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-right: 15px;
        &:hover {
          text-decoration: underline;
        }
        &:after {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          content: '';
          position: absolute;
          right: 4px;
          top: 10px;
          background: var(--bg_clr_19);
        }
      }
      &-search {
        width: 340px;
        .form-control {
          border: 1px solid rgba(221, 221, 221, 0.5);
          padding: 18px 18px 18px 52px;
          border-radius: 4px;
          height: 56px;
          font-size: 16px;
          background: var(--bg_clr_14);
          color: var(--color_text_05);
          &-icon {
            position: absolute;
            left: 20px;
            top: 18px;
            .icon-search-green {
              width: 15px;
              height: 15px;
              background-image: url('../images/help/search-help.svg');
            }
          }
        }
      }
    }
  }
  &_content {
    padding-bottom: 80px;
    &-group {
      @include flex_justify_end;
      @include block_flex;
    }
    &-nav {
      width: 280px;
      &__body {
        background: var(--bg_clr_14);
        padding: 28px 20px;
      }
      .title {
        color: var(--color_text_30);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.25px;
        line-height: 16px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .nav {
        ul {
          margin: 0 -20px;
          li {
            font-size: 13px;
            letter-spacing: 0.12px;
            line-height: 20px;
            a {
              display: block;
              color: #626467;
              padding: 9px 20px;
              &:hover {
                color: var(--color_text_10);
              }
            }
            &.active {
              a {
                background: var(--bg_clr_19);
                color: var(--color_text_01);
              }
            }
          }
        }
      }
      .more {
        margin-top: 10px;
      }
    }
    &-block {
      max-width: 880px;
      width: 100%;
      margin-left: 20px;
      padding: 35px 40px;
      background: var(--bg_clr_14);
      font-size: 13px;
      color: #626467;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: var(--color_text_34);
        padding-bottom: 0.35em;
      }
      .title {
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        margin-bottom: 10px;
        h2 {
          padding-bottom: 0;
        }
      }
      .user {
        margin: 15px 0 35px;
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        &-info {
          font-size: 15px;
          color: var(--color_text_34);
          line-height: 22px;
          @include block_flex;
        }
        .image {
          height: 48px;
          width: 48px;
          background-color: #96bdff;
          border-radius: 50%;
          margin-right: 18px;
          overflow: hidden;
        }
        .date {
          color: var(--color_text_31);
          font-size: 12px;
        }
      }
      .info {
        font-size: 15px;
        color: #626467;
      }
      .text {
        font-size: 15px;
        color: var(--color_text_33);
        line-height: 24px;
        p {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .nav {
        margin-top: 30px;
        ul {
          padding-left: 20px;
          li {
            line-height: 20px;
            font-size: 15px;
            color: var(--color_text_33);
            margin: 12px 0;
            position: relative;
            &:after {
              width: 3px;
              height: 3px;
              border-radius: 50%;
              content: '';
              position: absolute;
              left: -20px;
              top: 8px;
              background: var(--bg_clr_19);
            }
            a {
              color: var(--color_text_33);
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }
      .follow {
        margin: 40px 0;
        .link {
          width: 24px;
          height: 24px;
          margin-right: 3px;
          border-radius: 50%;
          &-fb {
            background: url('../images/help/social/fb.svg') no-repeat;
          }
          &-gp {
            background: url('../images/help/social/gp.svg') no-repeat;
          }
          &-in {
            background: url('../images/help/social/in.svg') no-repeat;
          }
          &-tw {
            background: url('../images/help/social/tw.svg') no-repeat;
          }
          &:hover {
            background-color: rgba(150, 189, 255, 0.3);
          }
        }
      }
      &__item {
        border-top: 1px solid #e8eaf6;
        padding: 40px 0;
        &:last-child {
          padding-bottom: 5px;
        }
        .box-btn {
          margin: 20px 0 30px;
          line-height: 33px;
          color: var(--color_text_31);
          font-size: 12px;
          .btn {
            margin-right: 15px;
            min-width: 105px;
          }
          .check,
          .cross {
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
            margin-right: 7px;
          }
          .check {
            background: url('../images/help/check-help.svg');
            width: 10px;
            height: 7px;
          }
          .cross {
            background: url('../images/help/cross.svg');
            width: 9px;
            height: 9px;
          }
        }
        ul {
          padding-left: 20px;
          margin-top: 15px;
          li {
            line-height: 20px;
            font-size: 13px;
            color: var(--color_text_33);
            margin: 12px 0;
            position: relative;
            &:last-child {
              margin-bottom: 0;
            }
            &:after {
              width: 3px;
              height: 3px;
              border-radius: 50%;
              content: '';
              position: absolute;
              left: -20px;
              top: 8px;
              background: var(--bg_clr_19);
            }
            a {
              color: var(--color_text_33);
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}
