.exchange__page {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .or__span {
    text-transform: lowercase;
    color: var(--color_text_01);
  }
  .link__span {
    color: var(--color_text_05);
  }
  .inner__page {
    margin-left: auto;
    margin-right: auto;
    margin-top: 126px;
  }
  .checkbox__wrapper {
    margin-top: 40px;
    span {
      font-size: 14px;
    }
    .btn-purple {
      margin-top: 40px;
      padding-left: 30px;
      padding-right: 30px;
      margin-left: 5px;
    }
    span {
      opacity: 0.9;
    }
  }
  .form__body {
    background: var(--bg_clr_11);
    padding: 50px 30px 50px 30px;
    width: 100%;
    max-width: 58vw;
  }
  svg.switch__ic {
    margin-top: 15px;
    fill: transparent;
    stroke: var(--violet0);

    transition: all 0.7s;
    &:hover {
      transition: all 0.7s;

      path {
        fill: var(--violet0);
        stroke: var(--bg_clr_01);
        transition: all 0.7s;
      }
    }
  }
  .form__selection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .error_msg {
      bottom: -20px;
      position: absolute;
    }
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
    .svg-icon {
      margin-top: 0 !important;
    }
    .label__wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
      color: var(--color_text_02);
    }
  }
  @media (max-width: 767px) {
    .form__body {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    .tab-pane-name {
      max-width: 90%;
      margin-left: auto;
    }
  }
}
