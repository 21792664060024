.activity {
  & + .table-pagination {
    background: none;
    padding-right: 0;
  }
  .table-pagination {
    background: none;
    padding: 0;
    margin-bottom: 10px;
  }
  &-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    position: relative;
    .date {
      width: 100px;
      padding-right: 15px;
      opacity: 0.5;
      font-size: 12px;
      line-height: 20px;
    }
    .item-content {
      width: calc(100% - 100px);
      background-color: var(--bg_clr_11);
      border-radius: 4px;
      box-shadow: $shdw_type_00 var(--clr_shdw_00);
      padding: 15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      &-icon {
        width: 24px;
        text-align: center;
      }
      &-body {
        width: calc(100% - 24px);
        padding-left: 16px;
        .head {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 6px;
          &-name {
            color: var(--color_text_02);
            font-size: 16px;
            letter-spacing: 0.29px;
            line-height: 20px;
          }
          &-ip {
            font-size: 12px;
            color: var(--color_text_02);
            opacity: 0.5;
          }
        }
        .text {
          font-size: 14px;
          color: var(--color_text_02);
          opacity: 0.6;
        }
      }
    }
  }
}
.download-wrapper {
  display: flex;
  flex-direction: column;
}
.download-button {
  border: none;
  color: var(--color_text_00);
  background: none;
  cursor: pointer;
}
.error-download {
  color: var(--color_text_12);
}
