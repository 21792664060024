.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
}

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}
.scroll-element,
.scroll-element div {
  box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default;
}

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999;
}
.scroll-textarea > .scroll-content {
  overflow: hidden !important;
}
.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important;
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}

/*************** SIMPLE INNER SCROLLBAR ***************/

.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scrollbar-inner > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 4px;
  left: 0;
  width: 100%;
}

.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 4px;
}

.scrollbar-inner > .scroll-element .scroll-element_outer {
  overflow: hidden;
}

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  filter: alpha(opacity=10);
  opacity: 0.1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: #cccccc;
}
.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #999999;
}
.scrollbar-inner > .scroll-element:hover .scroll-bar,
.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
  filter: alpha(opacity=40);
  opacity: 0.4;
}

/* update scrollbar offset if both scrolls are visible */

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px;
}
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px;
}

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px;
}
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px;
}
