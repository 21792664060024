.adaptive-version-popup {
  position: fixed;
  min-height: 100%;
  min-width: 100%;
  background-color: var(--bg_clr_11);
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  &__logo {
    height: 45px;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    > svg {
      width: 200px;
      height: 200px;
      fill: var(--violet1);
    }
  }

  &__description {
    display: flex;
    flex-grow: 1;
    text-align: center;
    padding: 16px 0;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: auto;
    width: 100%;

    button {
      min-width: 100%;
      min-height: 46px;
      font-size: 14px;

      &:first-of-type {
        margin-bottom: 16px;
      }

      &:last-of-type {
        background-color: transparent;
        border: 2px solid var(--violet0);
        color: var(--violet0);
        padding: 12px;
      }
    }
  }
}
