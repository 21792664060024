.orderbook {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &_header {
    background-color: var(--bg_clr_00); // #212226
    padding: 8px 2px;
    color: var(--color_text_01);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 16px;

    .icon-arrow-toggle {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-aggregation {
      color: var(--color_text_02);
      font-size: 12px;
      @include block_flex;
      @include flex_justify_between;
      @include flex_align_item_center;
      white-space: nowrap;

      &-info {
        @include block_flex;
        @include flex_align_item_center;

        &-title {
          span {
            color: var(--color_text_03);
          }
        }

        &-cur {
          color: var(--color_text_02);
          font-size: 14px;
          font-weight: bold;
          padding: 0 12px;
        }
      }

      &-nav {
        .btn {
          padding: 6px;
          opacity: 0.75;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &-value {
      flex-grow: 1;
      overflow: hidden;
    }

    &-value-info {
      line-height: 18px;
      @include block_flex;
      white-space: nowrap;
      //@include flex_justify_between;
      @include flex_align_item_center;
      font-size: 11px;
      padding: 5px 0;
      text-transform: capitalize;
      background-color: var(--bg_clr_00); // #212226
      & > div {
        flex: 1;
        color: var(--color_text_02);
        padding: 0 2px;
        padding-right: 5px;
        text-align: center;

        &:first-child {
          padding-left: 0;
        }
      }

      &-pair {
      }

      &-price {
        text-align: right;
      }

      &-change {
        text-align: right;
      }
    }

    &-value {
      &.row {
        display: flex;
        flex-direction: row;

        .orderbook_body-value_down,
        .orderbook_body-value_top {
          height: 100%;
          width: 50%;
        }

        .orderbook_body-value_down {
          justify-content: flex-start;
        }
      }

      &_top,
      &_down {
        @include block_custom_scroll();
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(50% - 17px);
      }

      &-item {
        font-size: 12px;
        line-height: 14px;
        @include block_flex;
        @include flex_justify_start;
        @include flex_align_item_center;
        @include flex_row;
        padding: 2px;
        position: relative;
        color: var(--color_text_02);

        &:hover {
          @include web_transition(all 0.2s);
          cursor: pointer;
          font-weight: 500;
          background-color: var(--bg_clr_26);
          color: var(--color_text_01);
          //.after{
          //	background: none!important;
          //}
        }

        & > div {
          flex: 1;
          padding-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
        }

        & > div:first-child {
          text-align: left;
          padding-left: 10px;
          padding-right: 0;
        }

        & > div:last-child {
          padding: 0;
        }

        & > div.d-none {
          padding: 0;
          flex: 0;
        }

        &.down {
          .orderbook_body-value-item-pair {
            color: var(--color_text_12);
          }

          .after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            background: var(--bg_clr_27);
            z-index: 0;
          }
        }

        &.top {
          .orderbook_body-value-item-pair {
            color: var(--color_text_09);
          }

          .after {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background: var(--bg_clr_08);
            z-index: 0;
            display: block;
          }
        }

        &-pair {
          span {
            &.price-opacity {
              opacity: 0.5;
            }
          }
        }

        &-price {
          text-align: right;

          span {
            padding-right: 22px;
          }
        }

        &-change {
          text-align: right;
        }

        &-stroke {
          padding: 9px 12px;
          border-top: 1px solid var(--brdr_clr_05);
          border-bottom: 1px solid var(--brdr_clr_05);
        }

        &-title {
          font-size: 14px;
          line-height: 14px;
          font-weight: bold;
          padding-right: 16px;
        }

        &-text {
          font-size: 12px;
          line-height: 12px;
          text-align: right;

          span {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
