.maintenance {
  text-align: center;
  padding: 20px 0 70px;
  position: relative;
  min-height: 100%;
  @include block_flex;
  @include flex_justify_center;
  @include flex_column;
  &.login {
    background: var(--bg_clr_30);
  }
  .image {
    margin-bottom: 30px;
  }
  .title {
    font-weight: normal;
    letter-spacing: 0.25px;
    padding-bottom: 0;
    line-height: 1.5;
    max-width: 620px;
    margin: 0 auto;
    color: var(--color_text_01);
  }
  .text {
    max-width: 480px;
    margin: 15px auto 0;
    color: var(--color_text_31);
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .stop {
    color: var(--color_text_31);
    font-size: 14px;
    line-height: 24px;
    margin-top: 40px;
    & + .stop {
      margin-top: 18px;
    }
  }
  .box-btn {
    margin: 30px auto 0;
    max-width: 360px;
    width: 100%;
    &-full {
      .text {
        margin: -20px 0 55px;
      }
      margin: 55px auto 0;
      .btn {
        max-width: 360px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
  &-message {
    font-size: 14px;
    color: var(--color_text_01);
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 12px;
    background-color: rgba(23, 23, 26, 0.8);
    @include block_flex;
    @include flex_justify_center;
    @include flex_column;
    .text {
      max-width: 240px;
      margin: 0 auto;
      font-size: 13px;
      color: var(--color_text_04);
    }
    .title {
      font-size: 15px;
      color: var(--color_text_01);
      line-height: 18px;
      margin-bottom: 10px;
    }
  }
  &-blur {
    -webkit-filter: blur(2px);
    filter: blur(2px);
  }
  &_footer {
    text-align: center;
    color: var(--color_text_02);
    font-size: 9px;
    width: 100%;
    position: absolute;
    bottom: 25px;
    left: 0;
  }
}
