.tab-pane {
  display: none;
  padding-top: 40px;
  padding-bottom: 20px;

  &.active {
    display: block;
  }

  &-name {
    color: var(--color_text_02);
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 20px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-filter {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    &-item {
      padding-left: 10px;
      line-height: 30px;

      .icon-arrow-toggle {
        margin-right: 2px;
      }

      span {
        color: var(--color_text_02);
      }

      &.active {
        .icon-arrow-toggle {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }

    .item-filter {
      padding: 10px 20px;
      text-transform: uppercase;
      border: 1px solid var(--brdr_clr_28);
      color: var(--color_text_26);

      &.active {
        border-color: var(--brdr_clr_10);
        color: var(--color_text_07);
      }
    }
  }

  &-info {
    &-title {
      color: var(--color_text_02);
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 28px;
      padding-bottom: 22px;
    }

    &-load {
      max-width: 480px;
      height: 4px;
      position: relative;
      margin-bottom: 14px;

      &-value {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        max-width: 100%;
      }

      &.type_1 {
        background-color: var(--bg_clr_36);

        .tab-pane-info-load-value {
          background-color: var(--bg_clr_17);
        }
      }

      //&.red {
      //  background-color: rgba(245, 72, 101, 0.2);
      //  .tab-pane-info-load-value {
      //    background-color: #F54865;
      //  }
      //}
    }

    &-text {
      color: var(--color_text_25);
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 20px;
      padding-bottom: 22px;
    }
  }
}

.MuiStepLabel-label {
  color: var(--color_text_02) !important;
}
.MuiInputBase-input {
  background-color: var(--bg_clr_09) !important;
  color: var(--color_text_02) !important;
}
.MuiFormLabel-root {
  color: var(--color_text_02) !important;
}
.curoWc,
.dxmbSc,
.gRFynB {
  background-color: var(--bg_clr_09) !important;
}
.ggOPVF,
.eBYBxh,
.euqswk {
  background-color: var(--bg_clr_07) !important;
  color: var(--color_text_02) !important;
}
.MuiSvgIcon-root {
  fill: var(--bg_clr_07) !important;
}
.asLg .somethingWrongHeader,
.asLg .reuploadButton {
  color: var(--color_text_02) !important;
}
.idaAAi .doneButton {
  display: none !important;
}
.eDPzFk .labelBorder {
  border: 1px solid var(--bg_clr_30) !important;
}
