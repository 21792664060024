.chat {
  height: 100%;
  position: relative;
  &_user {
    &-list {
      width: 333px;
      border-radius: 4px;
      background: var(--bg_clr_14);
      .tab-pane {
        display: none;
        &.active {
          display: block;
        }
      }
      &_header {
        border-bottom: 2px solid #ececf3;
        font-size: 13px;
        color: #263858;
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        margin: 0 20px;
        .nav-tabs {
          @include block_flex;
          a {
            color: inherit;
            padding: 25px 12px;
            line-height: 15px;
            display: block;
          }
          li {
            margin-right: 10px;
            &.active {
              a {
                position: relative;
                &:after {
                  height: 2px;
                  width: 50px;
                  border-radius: 2px;
                  background-color: #447bff;
                  content: '';
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: -25px;
                }
              }
            }
          }
        }
      }
      &_search {
        padding: 20px 20px 10px;
        .box-control {
          position: relative;
        }
        .form-control {
          padding-left: 50px;
          &-icon {
            position: absolute;
            left: 20px;
            top: 50%;
            @include web_transform(translateY(-50%));
          }
        }
      }
      &_result {
        height: calc(100% - 137px);
        overflow-y: auto;
      }
      &_item {
        border-left: 2px solid transparent;
        background: var(--bg_clr_14);
        padding: 12px 20px 13px;
        @include block_flex;
        @include flex_align_item_center;
        position: relative;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          width: 86%;
          height: 1px;
          left: 7%;
          top: 100%;
          background-color: var(--bg_clr_06);
          z-index: 1;
        }
        &:hover {
          background-color: var(--bg_clr_25);
        }
        &.active {
          border-color: #447bff;
          background-color: var(--bg_clr_06);
        }
        &.online {
          .person_status {
            background-color: #2fce67;
          }
          .text {
            color: #263858;
          }
          .name {
            font-weight: 500;
          }
        }
        .person {
          margin-right: 5px;
          position: relative;
          &_status {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            position: absolute;
            right: 12px;
            bottom: 10px;
            z-index: 1;
          }
        }
        .text {
          color: var(--color_text_06);
          font-size: 13px;
          max-width: 78%;
          &_header {
            @include block_flex;
            @include flex_justify_between;
            @include flex_align_item_center;
            margin-bottom: 7px;
            .name {
              font-size: 16px;
              color: #263858;
            }
            .date {
              color: var(--color_text_06);
            }
          }
          &_content {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      &_channel {
        border-left: 2px solid transparent;
        padding: 16px 20px;
        @include block_flex;
        @include flex_align_item_center;
        @include flex_justify_between;
        position: relative;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          width: 86%;
          height: 1px;
          left: 7%;
          top: 100%;
          background-color: var(--bg_clr_06);
          z-index: 1;
        }
        &:hover {
          background-color: var(--bg_clr_11);
        }
        &.active {
          border-color: var(--brdr_clr_07);
          .name {
            font-weight: 500;
          }
        }
        .name {
          font-size: 16px;
          padding-left: 7px;
        }
      }
      &_blocked {
        @include block_flex;
        @include flex_align_item_center;
        @include flex_justify_between;
        .person {
          @include block_flex;
          @include flex_align_item_center;
          &_image {
            @include web_transform(scale(0.5));
            margin: -15px -20px -15px 0;
            transform-origin: 0 50%;
            -webkit-transform-origin: 0 50%;
          }
        }
        .link {
          opacity: 0.7;
        }
      }
      &_nav {
        &-action {
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--bg_clr_09);
          z-index: 10;
          top: 0;
          left: 0;
          display: none;
          &_title {
            padding: 9px 0;
            margin: 0 20px;
            border-bottom: 1px solid #2b2b2c;
            @include block_flex;
            @include flex_justify_between;
            @include flex_align_item_center;
            .link {
              margin-right: -8px;
            }
          }
          &_form {
            padding: 10px 20px;
            .box-control {
              margin-bottom: 10px;
            }
            .box-btn {
              text-align: right;
              .btn {
                width: 98px;
                padding: 6px;
              }
            }
            .form-control {
              border: 1px solid #2b2b2c;
              border-radius: 2px;
            }
            textarea.form-control {
              height: 78px;
              resize: none;
            }
          }
        }
      }
    }
    &-info {
      width: 310px;
      border-radius: 4px;
      background: var(--bg_clr_14);
      &_header {
        border-bottom: 2px solid #ececf3;
        font-size: 13px;
        color: #263858;
        padding: 25px 0;
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        margin: 0 20px;
        &.open {
          .icon {
            @include web_transition(all 0.2s ease-in-out);
            @include web_transform(rotate(180deg));
          }
          .title {
            display: none;
          }
        }
        &:not(.open) {
          .user-opened {
            display: none;
          }
        }
      }
      &_content {
        padding: 10px 0;
        height: calc(100% - 67px);
        position: relative;
        overflow: hidden;
        .section-user-profile {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      &_list {
        max-height: 100%;
        overflow-y: auto;
      }
      &_personal {
        right: -100%;
        opacity: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: var(--bg_clr_14);
        @include web_transition(all 0.4s ease-in-out);
        z-index: 2;
        .open & {
          display: block;
          right: 0;
          opacity: 1;
        }
      }
      &_member {
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_center;
        padding: 0 20px;
        &:hover {
          background-color: var(--bg_clr_11);
        }
        &.online {
          .person_status {
            background-color: #2fce67;
          }
        }
        &:not(.online) {
          .person_image {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: 0.5;
          }
        }
        .person {
          @include block_flex;
          @include flex_align_item_center;
          &_name {
            font-size: 16px;
          }
          &_image {
            @include web_transform(scale(0.68));
            transform-origin: 0 50%;
            -webkit-transform-origin: 0 50%;
            margin: -8px -8px -8px 0;
          }
          &_status {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            z-index: 1;
            background-color: #e2e6ec;
          }
          &_time {
          }
        }
      }
    }
  }
  &_dialog {
    background: var(--bg_clr_11);
    width: calc(100% - 647px);
    @include block_flex;
    @include flex_wrap;
    height: 100%;
    font-size: 13px;
    color: var(--color_text_02);
    line-height: 22px;
    > div {
      @include flex_col_full;
    }
    &-header {
      padding: 0 20px;
      border-bottom: 1px solid var(--brdr_clr_24);
      @include flex_align_self_start;
      @include block_flex;
      @include flex_justify_between;
      &_person {
        @include block_flex;
        @include flex_align_item_center;
        &.online {
          .status {
            background-color: #2fce67;
          }
        }
        &-image {
          margin: -1px 0;
          position: relative;
        }
        .status {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          position: absolute;
          right: 12px;
          bottom: 10px;
          z-index: 1;
        }
        &-info {
          margin-left: 10px;
          .name {
            font-size: 20px;
          }
          .status {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
            right: inherit;
            bottom: inherit;
          }
        }
      }
      &_nav {
        @include block_flex;
        @include flex_align_item_center;
        .link {
          margin: 0 10px;
          & + .btn {
            margin-left: 20px;
          }
        }
        .btn {
          margin-left: 10px;
        }
      }
    }
    &-body {
      padding: 0 20px 15px;
      @include flex_align_self_end;
      @include block_flex;
      @include flex_column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;

      height: calc(100% - 67px);
      &-scroll {
        flex: 2 0;
        -webkit-flex: 2 0;
        overflow: hidden;
        &-list {
          height: 100%;
          max-height: 100%;
          position: relative;
          @include block_flex;
          &_content {
            @include flex_align_self_end;
            max-height: 100%;
            width: 100%;
            position: relative;
            overflow-y: scroll;
          }
        }
      }
      &_date {
        text-align: center;
        line-height: 20px;
        position: relative;
        color: var(--color_text_02);
        width: 100%;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 50%;
          background-color: var(--bg_clr_31);
          z-index: 1;
        }
        span {
          padding: 0 8px;
          background: var(--bg_clr_11);
          z-index: 2;
          position: relative;
        }
      }
    }
    &-send {
      @include flex_align_self_end;
      @include block_flex;
      @include flex_justify_between;
      position: relative;
      width: 100%;
      margin-top: 15px;
      textarea {
        max-height: 300px;
      }
      &_form {
        padding: 15px 0 0;
        width: 100%;
        border-top: 1px solid var(--brdr_clr_24);
        @include block_flex;
        @include flex_justify_between;
        @include flex_align_item_end;
        &-nav {
          @include block_flex;
          @include flex_align_item_center;
          @include flex_justify_end;
          width: 30%;
          .btn {
            width: 98px;
            padding: 6px;
            margin-left: 10px;
          }
          .link {
            margin-left: 5px;
          }
        }
        &-place {
          width: 70%;
          textarea.form-control {
            height: 24px;
            padding: 5px 0;
            font-size: 11px;
            background: none;
            color: #797a7d;
            border: 0;
          }
        }
      }
    }
    &-item {
      padding: 12px 0;
      @include block_flex;
      position: relative;
      cursor: pointer;
      &.online {
        .person_status {
          background-color: #2fce67;
        }
      }
      .person {
        position: relative;
        &_status {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          position: absolute;
          right: 12px;
          bottom: 10px;
          z-index: 1;
        }
        &_image {
          position: relative;
        }
      }
      .text {
        margin-left: 10px;
        padding-top: 15px;
        width: 100%;
        .name {
          font-weight: bold;
        }
        &_line {
          @include block_flex;
          @include flex_justify_between;
          & + .text_line {
            margin-top: 10px;
          }
          &-date {
            @include flex_col_width(55px);
            text-align: right;
          }
        }
        .image {
          max-width: 354px;
          margin: 5px 0 20px;
        }
      }
      &_nav {
        left: 0;
        .box-drop-open {
          padding: 10px 0;
          top: 50px;
        }
      }
    }
  }
}
