.flag {
  width: 21px;
  height: 15px;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center;

  &-cs {
    background-image: url('../images/flags/icon-CZ.svg');
  }

  &-de {
    background-image: url('../images/flags/icon-DE.svg');
  }

  &-en {
    background-image: url('../images/flags/icon-US.svg');
  }

  &-es {
    background-image: url('../images/flags/icon-ES.svg');
  }

  &-et {
    background-image: url('../images/flags/icon-EE.svg');
  }

  &-hi_IN {
    background-image: url('../images/flags/icon-IN.svg');
  }

  &-it {
    background-image: url('../images/flags/icon-IT.svg');
  }

  &-kk {
    background-image: url('../images/flags/icon-KZ.svg');
  }

  &-pt {
    background-image: url('../images/flags/icon-PT.svg');
  }

  &-ro {
    background-image: url('../images/flags/icon-RO.svg');
  }

  &-ru {
    background-image: url('../images/flags/icon-RU.svg');
  }

  &-fr {
    background-image: url('../images/flags/icon-FR.svg');
  }

  &-zh_CN {
    background-image: url('../images/flags/icon-CN.svg');
  }

  &-ru {
    background-image: url('../images/flag/flag-RU.svg');
  }

  &-tr {
    background-image: url('../images/flags/icon-TR.svg');
  }

  &-de {
    background-image: url('../images/flag/flag-DE.svg');
  }
}

.cryptocurrency-icons {
  height: 18px;
}

.switcher {
  font-size: 13px;
  color: var(--color_text_02);
  line-height: 20px;

  &-place {
    background: var(--bg_clr_04);
    cursor: pointer;
    padding: 0 2px;
    border-radius: 10px;
    text-decoration: none;
    @include web_transition(all 0.2s ease);
    height: 20px;
    width: 35px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 2;

    .on & {
      background: #447bff;
    }

    &_point {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      @include web_transition(all 0.2s ease);

      .on & {
        left: 100%;
        margin-left: -18px;
      }
    }
  }

  &-text {
    &-on,
    &-off {
      margin-right: 15px;
    }
  }

  &.on {
    .switcher-text-off {
      display: none;
    }
  }

  &:not(.on) {
    .switcher-text-on {
      display: none;
    }
  }
}

.border-user {
  width: 103px;
  height: 120px;
  padding-top: 13px;
  text-align: center;
  margin: 0 auto;
  background-image: url('../images/mask/avatar-borders.svg');

  &-img {
    -webkit-mask-image: url(../images/mask/avatar-mask.svg);
    -moz-mask-image: url(../images/mask/avatar-mask.svg);
    background-size: cover;
    width: 81px;
    height: 94px;
    margin: 0 auto;
    display: block;
  }

  &.mini {
    width: 58px;
    height: 68px;
    padding-top: 8px;

    .border-user-img {
      width: 46px;
      height: 52px;
    }
  }

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
    border: 2px solid #6d7077;
    border-radius: 50%;
    overflow: hidden;

    .border-user-img {
      width: 100%;
      height: auto;
      background: none;
      -webkit-mask-image: none;
      -moz-mask-image: none;
    }
  }
}

.box-drop {
  position: relative;

  &:hover {
    cursor: pointer;

    .box-drop-open {
      display: block;
    }
  }

  &-open {
    display: none;
    position: absolute;
    top: 24px;
    right: -5px;
    z-index: 10;
    width: 220px;
    background-color: red;
    box-shadow: $shdw_type_00 var(--clr_shdw_00);
    padding: 0 12px;
    color: var(--color_text_02);
    font-size: 12px;

    &:after {
      content: '';
      position: absolute;
      right: 6px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid var(--brdr_clr_06);
    }

    &.list {
      box-shadow: $shdw_type_00 var(--clr_shdw_00);
    }

    &.left {
      left: 6px;

      &:after {
        left: 6px;
      }
    }

    &-item {
      border-bottom: 1px solid var(--brdr_clr_06);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 18px 0;

      &-info {
        font-size: 13px;
        line-height: 15px;
        padding-left: 12px;

        &-title {
          color: var(--color_text_19);
          padding-bottom: 2px;
          line-height: 22px;
        }

        &-text {
          color: var(--color_text_01);
        }

        &_action {
          color: var(--color_text_01);
        }
      }

      .list & {
        @include flex_align_item_center;
        border-bottom: 0;
        color: var(--color_text_02);
        padding: 4px 0;
        min-height: 32px;

        & + .box-drop-open-item {
          border-top: 1px solid var(--brdr_clr_06);
        }
      }

      &_nav {
        display: block;
        padding: 10px 18px;
        color: #797a7d;

        .icon {
          margin-right: 10px;
        }
      }

      &_action {
        color: #797a7d;
        line-height: 24px;
      }
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: top;

  &_btn {
    display: inline-block;
    vertical-align: top;

    &:hover {
      .icon-dropdown {
        margin-top: 2px;
        @include web_transition(all 0.15s);
      }
    }

    .open & {
      background: none;

      .icon-dropdown {
        margin-top: 2px;
        @include web_transition(all 0.15s);
      }
    }
  }

  &_menu {
    position: absolute;
    top: 100%;
    background: var(--bg_clr_11);
    border: 1px solid var(--brdr_clr_05);
    border-radius: 4px;
    box-shadow: $shdw_type_00 var(--clr_shdw_00);
    right: 0;
    text-align: left;
    line-height: normal;
    z-index: 30;
    display: none;
    min-width: 100%;
    color: var(--color_text_01);

    .error & {
      border-color: var(--brdr_clr_15);
    }

    &.widget {
      min-width: 200px;
    }

    .open & {
      display: block;
    }

    &:after {
      border-bottom: 6px solid var(--brdr_clr_06);
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      content: '';
      position: absolute;
      top: -5px;
      right: 6px;
    }

    &.center {
      &:after {
        right: 50%;
        margin-right: -5px;
      }
    }

    &.top {
      top: inherit;
      bottom: 100%;

      &:after {
        border-top: 6px solid var(--brdr_clr_06);
        border-bottom: 0;
        top: 100%;
        margin-top: 5px;
      }
    }

    &-title {
      font-size: 11px;
      padding: 9px 12px;
      text-transform: uppercase;
      margin-bottom: 3px;
      color: var(--color_text_04);
    }

    &-text {
      font-size: 10px;
      color: var(--color_text_02);
      padding: 20px 12px;
      line-height: 16px;
    }

    &-link {
      text-align: center;
      padding: 9px;
      display: block;
      line-height: 18px;
      color: var(--color_text_04);
      position: relative;
      z-index: 2;
      white-space: nowrap;

      &:hover {
        background-color: var(--bg_clr_07);
      }

      &.active {
        opacity: 0.5;
      }

      .name {
        font-size: 13px;
        color: var(--color_text_01);
        margin-right: 9px;
        margin-left: 3px;
      }
    }

    &-item {
      padding: 4px 12px;
      font-size: 12px;
      line-height: 24px;
      cursor: pointer;
      color: var(--color_text_02);
      position: relative;
      z-index: 2;
      @include block_flex;
      @include flex_justify_between;
      @include flex_align_item_center;

      &:hover {
        background-color: var(--bg_clr_07);
        color: var(--color_text_00);
      }

      &.active {
        color: var(--color_text_07);
        color: var(--color_text_07);
        font-weight: 500;
      }

      .icon:last-child {
        margin-right: -3px;
      }

      .title {
        .icon {
          margin-left: -6px;
          margin-right: 3px;
        }
      }

      &.widget {
        color: var(--color_text_01);
        font-weight: bold;
        text-transform: uppercase;

        &:after {
          background: linear-gradient(var(--bg_clr_05) 50%, transparent 50%);
          background: -webkit-linear-gradient(var(--bg_clr_05) 50%, transparent 50%);
          background-size: 2px 2px;
          -webkit-background-size: 2px 2px;
          background-color: transparent;
          content: '';
          position: absolute;
          top: 20%;
          left: 4px;
          height: 60%;
          width: 3px;
        }

        .action {
          text-transform: uppercase;
          font-size: 10px;
          display: none;
          font-weight: normal;
        }

        &:hover {
          .action {
            display: block;
            background: var(--bg_clr_00);
          }
        }
      }

      &.enabled {
        background: var(--bg_clr_01);

        &:after {
          display: none;
        }
      }
    }
  }
}

.progress-bar {
  position: relative;
  width: 190px;
  margin-top: 15px;

  &-line {
    position: relative;
    height: 2px;
    border-radius: 1.5px;
    background: #3e4049;

    .line {
      position: absolute;
      height: 2px;
      border-radius: 1.5px;
      background: var(--bg_clr_02);
      top: 0;
      left: 0;

      &:after {
        width: 2px;
        height: 6px;
        background: var(--bg_clr_02);
        left: 100%;
        top: -2px;
        content: '';
        position: absolute;
      }
    }
  }

  &-info {
    @include block_flex;
    @include flex_justify_between;
    font-size: 9px;
    color: var(--color_text_24);
    margin-top: 5px;
  }
}
