.login-disable {
  @include block_flex;
  @include flex_wrap;
  min-height: 100%;
  padding: 0 20px;
  > div {
    @include flex_col_full;
  }
  &_logo {
    @include flex_align_self_start;
    text-align: center;
    padding: 65px 0;
    img {
      height: 38px;
    }
  }

  &_form {
    .ui-form {
      h2 {
        color: var(--color_text_01);
        font-size: 28px;
        font-weight: 400;
        text-align: center;
      }
      .ui-form-text {
        color: var(--color_text_31);
        padding-top: 15px;
        &-full {
          margin-right: -100px;
          margin-left: -100px;
          margin-bottom: 55px;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      max-width: 360px;
      margin: 0 auto;
    }
  }
  &_footer {
    @include flex_align_self_end;
    text-align: center;
    font-size: 9px;
    color: var(--color_text_32);
    padding: 20px 0;
  }
}
