.assets-info-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;

  .assets-block {
    display: flex;
    width: 1210px;
    height: 670px;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
    border-radius: 3px;

    .block-left {
      min-width: 418px;
      max-width: 418px;
      border-radius: 3px;

      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: var(--bg_clr_06); //01
      overflow: hidden;

      .asset-input-wrapper {
        position: relative;
        margin: 32px;

        .search-icon {
          position: absolute;
          height: 20px;
          right: 0;
          bottom: 10px;
          fill: var(--brdr_clr_08);
        }

        > label > input {
          padding-right: 25px;
        }
      }

      .assets-list {
        flex-grow: 1;
        padding: 0 24px;
        overflow-y: auto;

        @include block_custom_scroll(true);

        li {
          a {
            display: block;
            padding: 16px 8px;
            font-size: 16px;
            font-weight: 400;
            word-break: break-word;
            cursor: pointer;
            color: var(--color_text_02);

            &:hover {
              background-color: var(--bg_clr_01);
            }

            &.active {
              background-color: var(--bg_clr_01);
              color: var(--color_text_17);
            }
          }
        }
      }
    }

    .block-right {
      flex-grow: 1;
      border-radius: 3px;
      padding: 64px 40px;
      background-color: var(--bg_clr_11);
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        margin-bottom: 40px;
      }

      .asset-details {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-gap: 12px;
        padding-bottom: 37px;
        margin-bottom: 24px;
        border-bottom: 1px solid var(--brdr_clr_08);

        .asset-info-item {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;

          word-break: break-word;

          &.label {
            color: var(--color_text_31);
          }
        }
      }

      .asset-description {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        overflow-y: auto;
        word-break: break-word;

        color: var(--color_text_31);

        @include block_custom_scroll(true);
      }

      .asset-info-loader-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .loader-bg {
          background-color: transparent;
        }
      }
    }
  }
}
