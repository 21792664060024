.modal-signup {
  min-height: 100%;
  width: 50%;
  left: 50%;
  position: relative;
  background-color: var(--bg_clr_30);
  @include web_transform(translate(100%, 0));
  @include web_transition(transform 0.4s ease-out 0.2s);

  .in & {
    @include web_transform(translate(0, 0));
  }

  .logo {
    max-width: 130px;
    max-height: 130px;
    margin-left: auto;
    margin-right: auto;
  }

  .close {
    position: absolute;
    width: 32px;
    height: 32px;
    line-height: 32px;
    top: 20px;
    border: 0;
    padding: 0;
    font-size: 30px;
    color: #40b6f0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    right: 20px;
    background: url(../images/icon/modal-close.svg) no-repeat center;
    opacity: 1;
    &:hover {
      opacity: 0.75;
    }
  }
  h2 {
    color: var(--color_text_05);
    font-size: 28px;
    font-weight: 400;
    padding-bottom: 15px;
  }
  .back {
    position: absolute;
    width: 32px;
    height: 32px;
    line-height: 32px;
    top: 20px;
    border: 0;
    padding: 0;
    font-size: 30px;
    color: var(--color_text_09);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    left: 20px;
    background: url(../images/icon/back.svg) no-repeat center;
    &:hover {
      left: 16px;
      opacity: 0.75;
    }
  }
  &_footer {
    font-size: 9px;
    color: var(--color_text_32);
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    left: 0;
  }
  &_logo {
    margin-bottom: 40px;
    margin-top: 15px;
    text-align: center;
  }
  &-content {
    height: 100%;
    position: relative;
    padding: 70px 50px;
  }
  &_form {
    text-align: center;
    padding-top: 20px;

    .ui-form {
      text-align: left;
      max-width: 360px;
      margin: 0 auto;
      &-text {
        margin-bottom: 40px;
        color: var(--color_text_31);
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .popup-error-message {
        margin-top: 10px;
      }
      .sign-up-login-popup-error-message {
        color: var(--brdr_clr_15);
        text-align: center;
      }
    }
  }
  &_maintenance {
    img {
      max-width: 450px;
      margin: 0 auto;
    }
  }
  &_confirm {
    text-align: center;
    .image {
      margin-bottom: 40px;
      //transform: translate3d(300px,-100px,0);
      //transition-timing-function: cubic-bezier(.175,.885,.32,1.1275);
      //transition-duration:2s;
      //opacity: 0;
      //transition-property: opacity,transform;
    }
    .text {
      color: #626467;
      line-height: 1.35;
      max-width: 460px;
      margin: 20px auto 0;
    }
    .box-btn {
      margin: 50px auto 0;
      max-width: 360px;
    }
  }
  &_forgot {
    text-align: center;
    padding-top: 20px;
    .text {
      padding-top: 6px;
      color: var(--color_text_31);
      max-width: 470px;
      margin: 0 auto;
      line-height: 1.35;
    }
  }
  .modal-text-bottom {
    margin-top: 15px;
    color: var(--color_text_31);
    text-align: center;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.modal {
  &.in {
    .modal-signup_confirm {
      //.image {
      //	margin-bottom: 40px;
      //	opacity: 1;
      //	transform: translateZ(0);
      //}
    }
  }
}
#open {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
#fa2 {
  width: 130px;
  height: 130px;
  margin: 0 auto;
}
@media (max-width: 920px) {
  .modal-signup {
    &-content {
      padding: 50px 20px;
    }
  }
}

@media (max-width: 767px) {
  .modal-signup {
    width: 100%;
    left: 0;
  }
}
