.sign_up_page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &__content {
    width: 840px;
    padding: 30px 0;
    border-radius: 5px;
    background: var(--bg_clr_11);
    box-shadow: 0 4px 4px 0 var(--clr_shdw_00);

    .form-container {
      $column-gap: 60px;
      $row-gap: 10px;

      padding: 0 30px;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - (#{$column-gap / 2})));
      grid-row-gap: $row-gap;
      grid-column-gap: $column-gap;

      &__agreement {
        display: flex;
        align-items: center;
      }
    }
  }

  &__main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      text-align: center;
      padding: 0 30px 15px 30px;
    }
  }

  &__header {
    display: flex;
    justify-content: center;

    .logo {
      max-width: 180px;
      width: 180px;
      margin-bottom: 30px;
    }
  }
}
