.table-orders {
  border-radius: 4px;
  &-header,
  &-footer {
    background-color: var(--bg_clr_11);
    .tr,
    tr {
      .td,
      th {
        color: var(--color_text_02);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 48px;
        padding: 0 6px 0 12px;
        border-bottom: 1px solid var(--brdr_clr_26);
        position: relative;
        vertical-align: middle;
      }
    }
  }
  &-footer {
    .tr,
    tr {
      .td,
      th {
        border-top: 2px solid var(--brdr_clr_26);
      }
    }
  }
  &-body {
    .tr,
    tr {
      background-color: var(--bg_clr_11);
      .td,
      td {
        color: var(--color_text_02);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 48px;
        padding: 0 6px 0 12px;
        border-bottom: 1px solid #ffffff;
        position: relative;
        vertical-align: middle;
        .td-flex {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
        &.td-amount {
          width: 230px;
        }
        .block-amount {
          height: 4px;
          width: 128px;
          position: relative;
          margin-left: 16px;
          margin-right: 16px;
          .block-amount-value {
            position: absolute;
            height: 4px;
          }
          &.green {
            background-color: rgba(0, 210, 128, 0.2);
            .block-amount-value {
              background-color: #00d280;
            }
          }
          &.red {
            background-color: rgba(245, 72, 101, 0.2);
            .block-amount-value {
              background-color: #f54865;
            }
          }
        }
      }

      .close-tr {
        width: 18px;
        height: 18px;
        background-color: #a6a6a8;
        border-radius: 50%;
        display: block;
        position: relative;
        line-height: 18px;
        text-align: center;
        &:hover {
          cursor: pointer;
          background-color: #7c7c7e;
        }
      }
    }
  }
  .ui-form-checkbox {
    position: relative;
    width: 18px;
    height: 18px;
  }
}
